import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

export const EyfsApiSlice = createApi({
  reducerPath: "EYFSApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    createEYFS: builder.mutation({
      query: (body) => ({
        url: "/efys",
        method: "POST",
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateEYFS: builder.mutation({
      query: ({ id, body }) => ({
        url: `/efys/${id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getEYFS: builder.query({
      query: (id) => {
        return {
          url: `/efys/${id}`,
          method: "GET",
        };
      },
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getAllEYFS: builder.query({
      query: (params) => {
        return {
          url: "/efys",
          method: "GET",
          params,
        };
      },
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
