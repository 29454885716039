import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { LI, UL } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import ClosureDayApi from "../../../../services/ClosureDayApi";
import { useForm } from "react-hook-form";
interface ClosureDay {
  id: string;
  startDate: string;
  endDate: string;
  reason: string;
  typeOfClosureDay: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ClosureDay: React.FC = () => {
  const navigate = useNavigate();
  const [closureDays, setClosureDays] = useState<ClosureDay[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const handleEditClosureDay = (closureDay: ClosureDay) => {
    setSelectedClosureDay(closureDay);
    toggleModal();
  };
  

  const [selectedClosureDay, setSelectedClosureDay] = useState<
    Partial<ClosureDay>
  >({}); // For editing closure day

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ClosureDay>();

  // const toggleModal = () => {
  //   setIsModalOpen((prevState) => !prevState);
  //   if (!isModalOpen) {
  //     reset(selectedClosureDay); // Reset form with current closure day data when opening modal
  //   }
  // };
  
  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedClosureDay({}); // Clear selected room data
    } else {
      // If opening modal, reset with selected room data for edit, otherwise with empty data for add
      reset(selectedClosureDay); 
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async (data: ClosureDay) => {
    try {
      if (selectedClosureDay.id) {
        // Update closure day
        await ClosureDayApi.updateClosureDay(selectedClosureDay.id, data);
        Swal.fire({
          title: "Success!",
          text: "Closure Day updated successfully",
          icon: "success",
        });
      } else {
        // Create new closure day
        await ClosureDayApi.createClosureDay(data);
        Swal.fire({
          title: "Success!",
          text: "Closure Day created successfully",
          icon: "success",
        });
      }
      // Reload the data after successful operation
      fetchClosureDays();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  // Fetch closure days from the API
  const fetchClosureDays = async () => {
    try {
      const response = await ClosureDayApi.getClosureDay();
      if (response.success && Array.isArray(response.data)) {
        setClosureDays(response.data);
      } else {
        throw new Error("Failed to fetch closure days");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch closure days",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClosureDays();
  }, []);

  const filteredItems = closureDays.filter((closureDay) =>
    closureDay.reason.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns: TableColumn<ClosureDay>[] = [
    {
      name: <CustomHeader title="Start Date" />,
      selector: (row) => row.startDate,
      sortable: true,
    },
    {
      name: <CustomHeader title="End Date" />,
      selector: (row) => row.endDate,
      sortable: true,
    },
    {
      name: <CustomHeader title="Reason" />,
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: <CustomHeader title="Type" />,
      selector: (row) => row.typeOfClosureDay,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap" }}>{row.typeOfClosureDay}</div>
      ),
    },
    {
      name: <CustomHeader title="Action" />,
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="#"
              onClick={() => handleEditClosureDay(row)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
    
  ];

  return (
    <div className="page-body mt-0">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title="Closure Days"
                  subTitle={[]}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={toggleModal}
                >
                  <i className="fa fa-plus me-2" /> Add Closure Day
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal for Adding Closure Day */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedClosureDay.id ? "Edit Closure Day" : "Add Closure Day"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="startDate">
                  Start Date <span className="text-danger">*</span>
                </Label>
                <input
                  type="date"
                  className={`form-control ${
                    errors.startDate ? "is-invalid" : ""
                  }`}
                  id="startDate"
                  {...register("startDate", {
                    required: "Start date is required",
                  })}
                  defaultValue={selectedClosureDay.startDate}
                />
                {errors.startDate && (
                  <FormFeedback>{errors.startDate.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  End Date <span className="text-danger">*</span>
                </Label>
                <input
                  type="date"
                  className={`form-control ${
                    errors.endDate ? "is-invalid" : ""
                  }`}
                  id="endDate"
                  {...register("endDate", {
                    required: "End date is required",
                  })}
                  defaultValue={selectedClosureDay.endDate}
                />
                {errors.endDate && (
                  <FormFeedback>{errors.endDate.message}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="reason">
                  Reason <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.reason ? "is-invalid" : ""
                  }`}
                  id="reason"
                  {...register("reason", { required: "Reason is required" })}
                  defaultValue={selectedClosureDay.reason}
                  placeholder="Enter closure day reason"
                />
                {errors.reason && (
                  <FormFeedback>{errors.reason.message}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="typeOfClosureDay">
                  Type of Closure Day <span className="text-danger">*</span>
                </Label>
                <select
                  className={`form-control ${
                    errors.typeOfClosureDay ? "is-invalid" : ""
                  }`}
                  id="typeOfClosureDay"
                  {...register("typeOfClosureDay", {
                    required: "Please select a closure day type",
                  })}
                  defaultValue={selectedClosureDay.typeOfClosureDay || ""}
                >
                  <option value="" disabled>
                    Select type
                  </option>
                  <option value="Bank Holiday">Bank Holiday</option>
                  <option value="Training Day/Inset Day">
                    Training Day/Inset Day
                  </option>
                  <option value="Closure Day">Closure Day</option>
                </select>
                {errors.typeOfClosureDay && (
                  <FormFeedback>{errors.typeOfClosureDay.message}</FormFeedback>
                )}
              </FormGroup>

              <ModalFooter>
                <Button color="primary" type="submit">
                  {selectedClosureDay.id
                    ? "Update Closure Day"
                    : "Add Closure Day"}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default ClosureDay;
