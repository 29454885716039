export const languagesData = [
  {
    data: "us",
    language: "English",
  },
  {
    data: "fr",
    language: "Français",
  },
  {
    data: "es",
    language: "Español",
  },
];

export const cartHeaderData = [
  {
    id: 1,
    src: "1.png",
    name: "Watch multicolor",
    price: "500",
  },
  {
    id: 2,
    src: "2.png",
    name: "Airpods",
    price: "500.00",
  },
];

export const notificationData = [
  {
    id: 1,
    color: "primary",
    date: "30-04-2024",
    time: "Today",
    name: "Alice Goodwin",
    text: "Fashion should be fun. It shouldn't be labelled intellectual.",
  },
  {
    id: 2,
    color: "secondary",
    date: "28-06-2024",
    time: "1 hour ago",
    name: "Herry Venter",
    text: "I am convinced that there can be luxury in simplicity.",
  },
  {
    id: 3,
    color: "primary",
    date: "04-08-2024",
    time: "Today",
    name: "Loain Deo",
    text: "I feel that things happen for open new opportunities.",
  },
  {
    id: 4,
    color: "secondary",
    date: "12-11-2024",
    time: "Yesterday",
    name: "Fenter Jessy",
    text: "Sometimes the simplest things are the most profound.",
  },
];

//setting-icon
export const settingIconData = [
  {
    id: 1,
    icon: "Profile",
    link: "dashboard/profile",
    text: "Profile",
  },
];
