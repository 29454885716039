import React from "react";
import { Table } from "reactstrap";
import { jobCardTableData } from "../../../../Data/Dashboard/Default";
import { H6, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Utils";

export default function JobCardTable() {
  return (
    <div className="table-responsive theme-scrollbar mt-2">
      <Table className="display">
        <thead>
          <tr>
            <th>{"Date "}</th>
            <th>{"Room Name "}</th>
            <th>{"Children"}</th>
            <th>{"Expected"}</th>
            <th>{"Staff"} </th>
            <th>{"Action"} </th>
          </tr>
        </thead>
        <tbody>
          {jobCardTableData.map((item) => (
            <tr key={item.id}>
              <td>{item.time}</td>
              <td>{item.job}</td>
              <td style={{ paddingLeft: "30px" }}>{item.company}</td>
              <td style={{ paddingLeft: "30px" }}>{item.employee}</td>
              <td style={{ paddingLeft: "25px" }}>{item.company}</td>
              <td>
                <a
                  href={``}
                  className="btn btn-light d-flex justify-content-center align-items-center"
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                >
                  <i className="icon-pencil-alt text-dark" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
