import { Card, CardBody, Col, FormFeedback, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const SocialMedia = (props: any) => {
  const navigate = useNavigate();

  return (
    <Row className="pt-0">
      <Col sm={12}>
        <Card>
          <CardBody>
            <Row className="g-3">
              <Col md={12}>
                <Label for="facebook">Facebook Link</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="https://facebook.com/yournurserypage"
                  value={props.form.facebookLink}
                  onChange={(e) =>
                    props.saveDataForm("facebookLink", e.target.value)
                  }
                />
                {props.error.facebookLink && (
                  <span style={{ color: "red" }}>
                    {"Facebook Link is required"}{" "}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="instagram">Instagram Link</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="https://instagram.com/yournurseryprofile"
                  value={props.form.instagramLink}
                  onChange={(e) =>
                    props.saveDataForm("instagramLink", e.target.value)
                  }
                />
                {props.error.instagramLink && (
                  <span style={{ color: "red" }}>
                    {"Instagram Link is required"}{" "}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="twitter">Twitter Link</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="https://twitter.com/yournurseryprofile"
                  value={props.form.twitterLink}
                  onChange={(e) =>
                    props.saveDataForm("twitterLink", e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <Label for="linkedin">LinkedIn Link</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="https://linkedin.com/in/yournurseryprofile"
                  value={props.form.linkedinLink}
                  onChange={(e) =>
                    props.saveDataForm("linkedinLink", e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <Label for="youtube">YouTube Link</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="https://youtube.com/yournurserychannel"
                  value={props.form.youtubeLink}
                  onChange={(e) =>
                    props.saveDataForm("youtubeLink", e.target.value)
                  }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SocialMedia;
