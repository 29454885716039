import { createListenerMiddleware } from "@reduxjs/toolkit";
import { FETCH_PLANS, SET_PLANS } from "./PackageReducer";
import { fetchPlans } from "./meditator";

export const PackagePlansListener = createListenerMiddleware();

PackagePlansListener.startListening({
  actionCreator: FETCH_PLANS,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    const { dispatch } = listenerAPI;
    const resp = await fetchPlans(action.payload, dispatch);
    console.log("resp: ", resp);
    if (resp.isSuccess) {
      const plansData = resp.data.data;
      dispatch(
        SET_PLANS({
          plans: plansData.list,
          count: plansData.list.length,
          pageNo: plansData?.pageNo,
        })
      );
    }
  },
});
