import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import CommonModal from "../../../Components/UiKits/Modal/Common/CommonModal";
import { Btn, H4, Image, LI, P, UL } from "../../../AbstractElements";
import { dynamicImage, getLoggedInUserId } from "../../../Utils";
import DemoRequestAPi from "../../../services/DemoRequest";
import moment from "moment";
import Swal from "sweetalert2";
import EventAPi from "../../../services/EventApi";
import { baseUrl } from "../../../Utils/Constants/config";

export interface SelectedEvent {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  designation: string;
  demoDate: string;
  demoTime: string;
  message: string;
  id: string;
}

const MyCalendar = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date>(new Date());
  const [demoRequest, setDemoRequest] = useState([]);
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [modal, setModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const toggle = () => setModal(!modal);

  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date: any) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const fetchDemoRequestData = async () => {
    try {
      const response = await DemoRequestAPi.getDemoRequest({
        page: 1,
        addToCalendar: true,
        pageSize: 1000,
      });
      if (response.success && Array.isArray(response.data.demos)) {
        setDemoRequest(response.data.demos);
      } else {
        throw new Error("Expected an array of DemoRequest");
      }
    } catch (error: any) {
      setError(error.message || "Error fetching demoRequest");
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await EventAPi.fetchEvent({
        searchText: "",
        nurseryId: "",
      });

      if (response.success && Array.isArray(response.events)) {
        setEvent(response.events);
      } else {
        throw new Error("Expected an array of Event");
      }
    } catch (error: any) {
      setError(error.message || "Error fetching event");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDemoRequest = async () => {
      try {
        const response = await DemoRequestAPi.getDemoRequest({
          page: 1,
          addToCalendar: true,
          pageSize: 1000,
        });
        if (response.success && Array.isArray(response.data.demos)) {
          setDemoRequest(response.data.demos);
        } else {
          throw new Error("Expected an array of DemoRequest");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching demoRequest");
      } finally {
        setLoading(false);
      }
    };

    fetchDemoRequest();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await EventAPi.fetchEvent({
          searchText: "",
          nurseryId: "",
        });

        if (response.success && Array.isArray(response.events)) {
          setEvent(response.events);
        } else {
          throw new Error("Expected an array of Event");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching event");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const tileContent = ({ date, view }: any) => {
    if (view !== "month") return null;

    // Filter events for the specific date
    const filteredRequest = demoRequest.filter((row: any) => {
      const demoDate = new Date(row.demoDate);
      return formatDate(demoDate) === formatDate(date);
    });

    // Filter events for the specific date
    const filteredEvents = event.filter((row: any) => {
      const eventDate = new Date(row.eventDate);
      return formatDate(eventDate) === formatDate(date);
    });

    if (filteredRequest.length > 0 && filteredEvents.length > 0) {
      return (
        <>
          <div
            className="event-marker"
            style={{ backgroundColor: "#34c3eb", cursor: "pointer" }}
            onClick={() => handleEventClick(filteredRequest[0])} // Set the first event on click
          >
            <span className="event-icon">📅</span>
          </div>
          <div
            className="event-marker"
            style={{ backgroundColor: "#FF69B4", cursor: "pointer" }}
            onClick={() => handleEventClick(filteredEvents[0])} // Set the first event on click
          >
            <span className="event-icon">🎉</span>
          </div>
        </>
      );
    }

    if (filteredRequest.length > 0) {
      return (
        <div
          className="event-marker"
          style={{ backgroundColor: "#34c3eb", cursor: "pointer" }}
          onClick={() => handleEventClick(filteredRequest[0])} // Set the first event on click
        >
          <span className="event-icon">📅</span>
        </div>
      );
    }

    console.log("filteredEvents: ", filteredEvents, formatDate(date));
    if (filteredEvents.length > 0) {
      return (
        <div
          className="event-marker"
          style={{ backgroundColor: "#FF69B4", cursor: "pointer" }}
          onClick={() => handleEventClick(filteredEvents[0])} // Set the first event on click
        >
          <span className="event-icon">🎉</span>
        </div>
      );
    }

    return null;
  };

  const handleEventClick = (event: any) => {
    console.log("event: ", event);
    setSelectedEvent(event); // Set the selected event data
    toggle(); // Open the modal
  };

  const data = {
    isOpen: modal,
    center: true,
    toggler: toggle,
  };

  const handleDeclined = (deleteId: string) => {
    Swal.fire({
      title: "Deleting Demo Booking",
      text: "Are you sure you want to delete this Booking?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await DemoRequestAPi.deleteDemoRequest(deleteId, getLoggedInUserId());
          await fetchDemoRequestData();
          toggle(); // Open the modal
          Swal.fire("Deleted!", "The booking has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting user:", error);
          Swal.fire("Error", "There was an issue deleting the user.", "error");
        }
      }
    });
  };

  const handleRemove = (deleteId: string) => {
    Swal.fire({
      title: "Deleting Event",
      text: "Are you sure you want to delete this Event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await EventAPi.deleteEvent(deleteId);
          await fetchEvents();
          toggle(); // Open the modal
          Swal.fire("Deleted!", "The event has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting event:", error);
          Swal.fire("Error", "There was an issue deleting the event.", "error");
        }
      }
    });
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`My Calendar`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/calendar/add`
                    );
                  }}
                >
                  Add New Event
                </Button>
              </div>
              <CardBody>
                <Calendar
                  onChange={(value) => setDate(value as Date)}
                  value={date}
                  tileContent={tileContent}
                  className="custom-calendar w-100"
                  nextLabel="Next Month"
                  prevLabel="Previous Month"
                />

                {selectedEvent && (
                  <CommonModal modalData={data}>
                    {selectedEvent.addToCalendar ? (
                      <div className="modal-toggle-wrapper booking-modal">
                        <h2>Booking Details</h2>
                        <p>Please review your booking details </p>
                        <div className="booking-details">
                          <p>
                            <span>Customer Name:</span>{" "}
                            {`${selectedEvent.firstName} ${selectedEvent.lastName}`}
                          </p>
                          <p>
                            <span>Email:</span> {selectedEvent.email}
                          </p>
                          <p>
                            <span>Phone:</span> {selectedEvent.phoneNo}
                          </p>
                          <p>
                            <span>Designation:</span>{" "}
                            {selectedEvent.designation}
                          </p>
                          <p>
                            <span>Booking Date:</span>{" "}
                            {moment(selectedEvent.demoDate).format(
                              "MMMM D, YYYY"
                            )}
                          </p>
                          <p>
                            <span>Booking Time:</span>{" "}
                            {moment(selectedEvent.demoTime).format("hh:mm A")}
                          </p>
                        </div>
                        <div className="message">
                          <p>
                            <strong>Message:</strong>{" "}
                            {selectedEvent.message || "No message available."}
                          </p>
                        </div>
                        <div className="d-flex mt-4">
                          <Btn
                            color="secondary"
                            className="d-flex"
                            onClick={toggle}
                          >
                            Close
                          </Btn>
                          <Btn
                            color="dark"
                            type="button"
                            className="d-flex"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() => handleDeclined(selectedEvent.id)}
                          >
                            Decline
                          </Btn>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {selectedEvent.creationType ? (
                      <div className="modal-toggle-wrapper booking-modal">
                        <h2>Event Details</h2>
                        <p>Please review the event details</p>
                        <div className="booking-details">
                          <p>
                            <span>Event Title:</span>{" "}
                            {selectedEvent.title || "No title available"}
                          </p>
                          <p>
                            <span>Description:</span>{" "}
                            {selectedEvent.description ||
                              "No description available"}
                          </p>
                          <p>
                            <span>Visibility:</span> {selectedEvent.visibility}
                          </p>
                          <p>
                            <span>Media Type:</span> {selectedEvent.mediaType}
                          </p>
                          <p>
                            <span>Media URL:</span>{" "}
                            {selectedEvent.mediaUrl ? (
                              <a
                                href={baseUrl + selectedEvent.mediaUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Media
                              </a>
                            ) : (
                              "No media available"
                            )}
                          </p>
                          <p>
                            <span>Event Date:</span>{" "}
                            {moment(selectedEvent.eventDate).format(
                              "MMMM D, YYYY"
                            )}
                          </p>
                          <p>
                            <span>Start Time:</span>{" "}
                            {moment(selectedEvent.startAt, "x").format(
                              "hh:mm A"
                            )}
                          </p>
                          <p>
                            <span>End Time:</span>{" "}
                            {moment(selectedEvent.endAt, "x").format("hh:mm A")}
                          </p>
                        </div>
                        <div className="d-flex mt-4">
                          <Btn
                            color="secondary"
                            className="d-flex"
                            onClick={toggle}
                          >
                            Close
                          </Btn>
                          <Btn
                            color="dark"
                            type="button"
                            className="d-flex"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() => handleRemove(selectedEvent.id)}
                          >
                            Remove
                          </Btn>
                          <Btn
                            color="primary"
                            type="button"
                            className="d-flex"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/dashboard/calendar/edit/${selectedEvent.id}`
                              )
                            }
                          >
                            Edit
                          </Btn>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CommonModal>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyCalendar;
