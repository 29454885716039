import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import NotificationApi from "../../../services/NotificationApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";

interface ISettingsNotification {
  id: string;
  title: string;
  description: string;
  active: boolean;
}

const Notification: React.FC = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<ISettingsNotification[]>([]);

  // Fetch notifications on component mount
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const data = await NotificationApi.getAllNotifications();
        setNotifications(data);
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: error.message || "Failed to fetch notifications.",
          icon: "error",
        });
      }
    };
    fetchNotifications();
  }, []);

  // Toggle active state of a notification
  const handleToggle = (id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) =>
        notif.id === id ? { ...notif, active: !notif.active } : notif
      )
    );
  };

  // Save changes to notification settings
  const handleSubmit = async () => {
    try {
      await Promise.all(
        notifications.map((notif) =>
          NotificationApi.updateNotification(notif.id, { active: notif.active })
        )
      );
      Swal.fire({
        title: "Success!",
        text: "Notification settings updated successfully",
        icon: "success",
      }).then(() => navigate(`${process.env.PUBLIC_URL}/dashboard`));
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong.",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Notification Settings"
                titleClass="mb-3"
              />
              <CardBody>
                <Form onSubmit={(e) => e.preventDefault()}>
                  {notifications.map((notification) => (
                    <div className="form-group mb-4 row align-items-center" key={notification.id}>
                      <div className="col-md-10">
                        <Label className="form-label font-weight-bold" style={{ fontSize: "1.2rem" }}>
                          {notification.title}
                        </Label>
                        <p className="text-muted">{notification.description}</p>
                      </div>
                      <div className="col-md-2 text-center">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notification.active}
                            onChange={() => handleToggle(notification.id)}
                            id={`switch-${notification.id}`}
                            style={{ width: '3rem', height: '1.5rem', borderRadius: '1rem' }}
                          />
                          <label className="form-check-label" htmlFor={`switch-${notification.id}`}></label>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="text-end">
                    <Button color="primary" onClick={handleSubmit} className="btn-lg px-5">
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
