import { Card, CardBody, Col } from "reactstrap";
import { dynamicImage } from "../../../../Utils";
import { H1, Image, P } from "../../../../AbstractElements";
import Select from "react-select";
import { useState } from "react";
import DropItem from "../../../../Components/Forms/Common/DropItem";

export default function WelcomeBanner() {
  const [selectedOption, setSelectedOption] = useState<null | string>(
    "diamond-plan"
  );
  const options = [
    {
      value: "diamond-plan",
      label: "Nursery 1",
    },
    {
      value: "demo-plan",
      label: "Nursery 2",
    },
  ];

  const handleChange: any = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as any;
      setSelectedOption(newSelectedOption?.value || null);
    }
  };

  return (
    <Col xl={4} sm={6} className="proorder-xxl-1 box-col-6">
      <Card className="welcome-banner">
        <CardBody>
          <div className="d-flex align-center">
            <H1>
              {"Hi, Shweta Admin"}
              <Image src={dynamicImage(`dashboard-1/hand.png`)} alt="" />
            </H1>
          </div>
          <P> {"Welcome back! Let’s start from where you left."}</P>
          <div className="d-flex align-center justify-content-between position-relative">
            <Select
              id={"nationality"}
              options={options}
              value={options.find(
                (option: any) => option.value === selectedOption
              )}
              onChange={handleChange}
              className={`nationality-form w-100`}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
