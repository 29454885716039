import React from "react";
import { Card, CardBody } from "reactstrap";

const NewPlan: React.FC = () => {
  return (
    <Card>
      <CardBody>
        <h5>NewPlan</h5>
        <p>Hi, new plan.</p>
      </CardBody>
    </Card>
  );
};

export default NewPlan;
