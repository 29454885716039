import { ReportsApiSlice } from "./ReportApiSlice";

export const fetchVisitors = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getAllVisitors.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const fetchVisitorsGraph = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getVisitorsGraph.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const fetchRevenueGraph = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getRevenueGraph.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const fetchUserGraph = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getUsersGraph.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const fetchSubSummary = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getSubsSummary.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const fetchDashboardCard = async (params: any, dispatch: any) => {
  return dispatch(
    ReportsApiSlice.endpoints.getDashboardCards.initiate(params, {
      forceRefetch: true,
    })
  );
};
