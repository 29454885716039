// src/services/api.ts
import axios from "axios";
import { baseUrl } from "../Utils/Constants/config";
import store from "../ReduxToolkit/Store";

// Create an Axios instance with default config
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to add token to headers automatically
api.interceptors.request.use(
  (config) => {
    const state = store.getState(); // Get the current state
    const token = state.auth.token; // Access the token from the auth slice
    console.log(token);
    console.log("Token before request:", token); // Log the token


    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
