import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import {
  CompanyName,
  FirstName,
  LastName,
  LooksGood,
} from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connectComponent } from "./connector";
import { IForm, ValidKeys } from "./Helper/types";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getLoggedInUserId } from "../../../Utils";

const AddUsers = (props: any) => {
  const navigate = useNavigate();

  const initialState = {
    startedAt: new Date().getTime(),
    expiredAt: new Date().setFullYear(new Date().getFullYear() + 1),
    joinDate: new Date(),
    paymentStatus: "PAID",
    nurseryObjArr: [
      {
        name: "",
      },
    ],
    packageId: "",
    nurseryType: "singleNursery",
    companyName: "",
    noOfNurseries: "1",
    noOfChild: "",
    noOfAdmins: "1",
    createdBy: getLoggedInUserId(),
    adminsObjArr: [
      {
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNo: "",
        altPhoneNo: "",
      },
    ],
  };

  const [form, setForm] = useState<IForm>({ ...initialState });

  const [plansData, setPlansData] = useState([]);

  const [showNursery, setShowNursery] = useState(
    form.nurseryType !== "singleNursery"
  );

  const [validate, setValidate] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<IForm>();

  const onSubmit = () => {
    setValidate(true);
    if (form.packageId === "") {
      toast.error("Please provide package");
    } else {
      let formData = JSON.parse(JSON.stringify(form));
      formData.joinDate = new Date(formData.joinDate).getTime();
      formData.noOfAdmins = Number(formData.noOfAdmins);
      formData.noOfChild = Number(formData.noOfChild);
      formData.noOfNurseries = Number(formData.noOfNurseries);
      if (formData.nurseryType == "singleNursery") {
        formData.nurseryObjArr = formData.nurseryObjArr.slice(0, 1);
      }
      console.log("formData: ", formData);
      props.addForm(formData);
    }
  };

  const saveDataForm = (name: ValidKeys, value: any) => {
    if (name === "nurseryType" && value === "singleNursery") {
      setForm({ ...form, [name]: value, ["noOfNurseries"]: 1 });
    } else if (name === "nurseryType" && value === "groupNursery") {
      setForm({
        ...form,
        [name]: value,
        ["noOfNurseries"]: form.nurseryObjArr.length,
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  useEffect(() => {
    props.fetchPlans({});
    setValue("nurseryType", "singleNursery");
  }, []);

  useEffect(() => {
    const data = props.plans.map((plan: any) => {
      return {
        label: `${plan.title} - £${plan.fee.toFixed(
          0
        )}/${plan.billing.toLowerCase()} (${
          plan.isActive ? "Active" : "Inactive"
        })`,
        value: plan.id,
      };
    });
    setPlansData(data);
  }, [props.plans]);

  const handleAddField = () => {
    setForm({
      ...form,
      nurseryObjArr: [...form.nurseryObjArr, { name: "" }],
    });
  };

  const handleRemoveField = (indexToRemove: number) => {
    if (form.nurseryObjArr.length === 1) {
      return;
    }

    const updatedNurseryNames = form.nurseryObjArr.filter(
      (_, index: number) => index !== indexToRemove
    );
    setForm({
      ...form,
      nurseryObjArr: updatedNurseryNames,
    });
  };

  const handleNurseryNameChange = (val: string, index: number) => {
    const updatedNurseryNames = [...form.nurseryObjArr];
    updatedNurseryNames[index] = { name: val };
    setForm({
      ...form,
      nurseryObjArr: updatedNurseryNames,
    });
  };

  const handleAddMoreAdmins = () => {
    let addFields = {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      email: "",
      phoneNo: "",
      altPhoneNo: "",
    };

    setForm({
      ...form,
      adminsObjArr: [...form.adminsObjArr, addFields],
    });
  };

  const handleAdminsChange = (val: string, index: number, key: string) => {
    let updatedAdminObj: any = [...form.adminsObjArr];
    updatedAdminObj[index][key] = val;
    setForm({
      ...form,
      adminsObjArr: updatedAdminObj,
    });
  };

  const handleRemove = (indexToRemove: number) => {
    if (form.adminsObjArr.length === 1) {
      return;
    }

    const updatedAdminObj = form.adminsObjArr.filter(
      (_, index: number) => index !== indexToRemove
    );
    setForm({
      ...form,
      adminsObjArr: updatedAdminObj,
    });
  };

  useEffect(() => {
    if (props.success && props.success?.message) {
      Swal.fire({
        title: "Successfully !!!",
        text: props.success.message,
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`${process.env.PUBLIC_URL}/dashboard/users/`, {
            replace: true,
          });
          // Clear the success message after navigating
          props.setMessage({ success: {}, error: {} });
        }
      });
    }

    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  const filterSubscription = (noOfChild: number | undefined | string) => {
    let autoSelectValue: any = "";
    if (noOfChild) {
      // Find the appropriate plan by checking `minNumOfChild` and `maxNumOfChild` for each plan
      const selectedPlan = props.plans.find(
        (plan: any) =>
          noOfChild >= plan.minNumOfChild && noOfChild <= plan.maxNumOfChild
      );

      if (selectedPlan) {
        autoSelectValue = selectedPlan.id; // Set auto-selected value based on plan ID
      }
    }
    setValue("packageId", autoSelectValue);
    saveDataForm("packageId", autoSelectValue);
  };

  useEffect(() => {
    filterSubscription(form.noOfChild);
  }, [form.noOfChild]);

  console.log("");
  console.log("form: ", form);
  console.log("");

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="page-body">
      {props.isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1",
          }}
        >
          <Spinner size="lg" color="primary">
            Loading...
          </Spinner>
        </div>
      )}
      <Container fluid className={`${props.isLoading ? "custom-opacity" : ""}`}>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add User Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/users/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row>
                        <Col md={4} className="position-relative">
                          <Label>
                            {`Nursery Type`}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div
                            className="custom-radio d-flex"
                            style={{ gap: "2rem" }}
                          >
                            <div
                              className="d-flex justify-align-center align-items-center"
                              style={{ gap: "0.5rem" }}
                            >
                              <Controller
                                name="nurseryType"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="radio"
                                      id="singleNursery"
                                      {...field}
                                      value="singleNursery"
                                      checked={field.value === "singleNursery"} // controlled checked state
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        saveDataForm(
                                          "nurseryType",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="singleNursery">
                                      Single Nursery
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                            <div
                              className="d-flex justify-align-center align-items-center"
                              style={{ gap: "0.5rem" }}
                            >
                              <Controller
                                name="nurseryType"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="radio"
                                      id="groupNursery"
                                      {...field}
                                      value="groupNursery"
                                      checked={field.value === "groupNursery"} // controlled checked state
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        saveDataForm(
                                          "nurseryType",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="groupNursery">
                                      Group Nursery
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <span className="h4 mt-3 d-block mb-4">
                        <i
                          className={`icon-angle-double-right text-primary text-bold`}
                        ></i>{" "}
                        Nursery Detail
                      </span>
                      <div>
                        <Row>
                          <Col md={6} className="position-relative">
                            <Label>
                              {"How many nurseries in a group?"}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <InputGroup>
                              <input
                                type="number"
                                className={`form-control ${
                                  errors.noOfNurseries ? "is-invalid" : ""
                                } ${
                                  validate && !errors.noOfNurseries
                                    ? "is-valid"
                                    : ""
                                }`}
                                placeholder="Enter your number of nurseries"
                                {...register(
                                  "noOfNurseries",
                                  form.nurseryType === "groupNursery"
                                    ? {
                                        pattern: {
                                          value: /^[0-9]+$/, // Validate only digits
                                          message:
                                            "Fee number must contain only digits",
                                        },
                                      }
                                    : {
                                        pattern: {
                                          value: /^[0-9]+$/, // Validate only digits
                                          message:
                                            "Fee number must contain only digits",
                                        },
                                      }
                                )}
                                disabled={
                                  form.nurseryType === "groupNursery"
                                    ? false
                                    : true
                                }
                                min={0}
                                value={
                                  form.nurseryType === "groupNursery"
                                    ? form.noOfNurseries
                                    : 1
                                }
                                onChange={(e) =>
                                  saveDataForm("noOfNurseries", e.target.value)
                                }
                              />
                              {errors.noOfNurseries && (
                                <FormFeedback tooltip>
                                  {errors.noOfNurseries.message}
                                </FormFeedback>
                              )}
                              {validate && !errors.noOfNurseries && (
                                <FormFeedback tooltip valid>
                                  {LooksGood}
                                </FormFeedback>
                              )}
                            </InputGroup>
                          </Col>
                          <Col md={4} className="d-flex align-items-end">
                            <button
                              className={`${
                                form.nurseryType === "singleNursery"
                                  ? "btn btn-light text-dark mb-1"
                                  : Number(form.noOfNurseries) ===
                                      form.nurseryObjArr.length ||
                                    Number(form.noOfNurseries) <=
                                      form.nurseryObjArr.length
                                  ? "btn btn-light text-dark mb-1"
                                  : "btn btn-primary mb-1"
                              }`}
                              disabled={
                                Number(form.noOfNurseries) ===
                                  form.nurseryObjArr.length ||
                                Number(form.noOfNurseries) <=
                                  form.nurseryObjArr.length ||
                                form.nurseryType === "singleNursery"
                              }
                              type="button"
                              onClick={handleAddField}
                            >
                              Add Nursery
                            </button>
                          </Col>
                        </Row>

                        {form.nurseryObjArr.map((nurseryName, index) => {
                          return (
                            <>
                              <Row
                                className={`mt-3 ${
                                  form.nurseryType === "singleNursery"
                                    ? index == 0
                                      ? ""
                                      : "d-none"
                                    : ""
                                }`}
                              >
                                <Col md={3}>
                                  <Label>
                                    {`Nursery Name`}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <div className="d-flex align-items-center position-relative">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.nurseryObjArr &&
                                        errors.nurseryObjArr?.[index]?.name
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        validate &&
                                        !(
                                          errors.nurseryObjArr &&
                                          errors.nurseryObjArr?.[index]?.name
                                        )
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      {...register(
                                        `nurseryObjArr.${index}.name`,
                                        form.nurseryType === "groupNursery"
                                          ? {
                                              required:
                                                "Please select valid Nursery Name",
                                            }
                                          : {}
                                      )}
                                      placeholder="Enter nursery name"
                                      value={nurseryName.name}
                                      onChange={(e: any) =>
                                        handleNurseryNameChange(
                                          e.target.value,
                                          index
                                        )
                                      }
                                    />
                                    {errors.nurseryObjArr &&
                                      errors.nurseryObjArr?.[index]?.name && (
                                        <FormFeedback tooltip>
                                          {
                                            errors.nurseryObjArr?.[index]?.name
                                              ?.message
                                          }
                                        </FormFeedback>
                                      )}
                                    {validate &&
                                      !errors.nurseryObjArr?.[index]?.name && (
                                        <FormFeedback tooltip valid>
                                          {LooksGood}
                                        </FormFeedback>
                                      )}
                                    {index != 0 && (
                                      <span
                                        className="p-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <i className="iconly-Close-Square icli h2"></i>
                                      </span>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                      </div>
                    </Col>
                    <Col md={12} className="mb-3 mt-4">
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Personal Detail
                      </span>
                      <Row className="mt-3">
                        <Col md={6} className="position-relative">
                          <Label>
                            {"Company Name"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.companyName ? "is-invalid" : ""
                            } ${
                              validate && !errors.companyName ? "is-valid" : ""
                            }`}
                            {...register("companyName", {
                              required: "Please enter valid company name",
                            })}
                            placeholder="Enter company name"
                            value={form.companyName}
                            onChange={(e) =>
                              saveDataForm("companyName", e.target.value)
                            }
                          />
                          {errors.companyName && (
                            <FormFeedback tooltip>
                              {errors.companyName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.companyName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={6} className="position-relative">
                          <Label>
                            {"Join date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="joinDate"
                              rules={{
                                required: "Please select joining date.",
                              }}
                              render={({ field }) => {
                                console.log("field: ", field);
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.joinDate ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.joinDate
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("joinDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />

                            {errors.joinDate && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{errors?.joinDate?.message}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.joinDate && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={6} className="position-relative mt-3">
                          <Label>
                            {"Current number of children?"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.noOfChild ? "is-invalid" : ""
                              } ${
                                validate && !errors.noOfChild ? "is-valid" : ""
                              }`}
                              placeholder="Enter your number of children"
                              {...register("noOfChild", {
                                required:
                                  "Please enter your valid number of children",
                                pattern: {
                                  value: /^[0-9]+$/, // Validate only digits
                                  message:
                                    "Fee number must contain only digits",
                                },
                              })}
                              min={0}
                              value={form.noOfChild}
                              onChange={(e) =>
                                saveDataForm("noOfChild", e.target.value)
                              }
                            />
                            {errors.noOfChild && (
                              <FormFeedback tooltip>
                                {errors.noOfChild.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.noOfChild && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={6} className="position-relative mt-3">
                          <Label>
                            {"Subscription Type"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="packageId"
                            control={control}
                            rules={{
                              required: "Please select any one package",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="subscription_type"
                                className={`${
                                  errors.packageId ? "is-invalid" : ""
                                } ${
                                  validate && !errors.packageId
                                    ? "is-valid"
                                    : ""
                                }`}
                                options={plansData}
                                isDisabled={true}
                                value={plansData.find(
                                  (option: any) =>
                                    option.value === form.packageId
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "packageId",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.packageId && (
                            <FormFeedback tooltip style={{ display: "block" }}>
                              <>{errors?.packageId?.message}</>
                            </FormFeedback>
                          )}
                          {validate && !errors.packageId && (
                            <FormFeedback
                              tooltip
                              valid
                              style={{ display: "block" }}
                            >
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12}>
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Admins Detail
                      </span>
                      <Row>
                        <Col md={6} className="position-relative mt-3">
                          <Label>
                            {"How many admins ?"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="number"
                            className={`form-control ${
                              errors.noOfAdmins ? "is-invalid" : ""
                            } ${
                              validate && !errors.noOfAdmins ? "is-valid" : ""
                            }`}
                            {...register("noOfAdmins", {
                              pattern: {
                                value: /^[0-9]+$/, // Validate only digits
                                message:
                                  "admins number must contain only digits",
                              },
                            })}
                            min={0}
                            placeholder="Enter no of admins"
                            value={form.noOfAdmins}
                            onChange={(e) =>
                              saveDataForm("noOfAdmins", e.target.value)
                            }
                          />
                          {errors.noOfAdmins && (
                            <FormFeedback tooltip>
                              {errors.noOfAdmins.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.noOfAdmins && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={2} className="d-flex align-items-end">
                          <button
                            className={`${
                              Number(form.noOfAdmins) ===
                              form.adminsObjArr.length
                                ? "btn btn-light text-dark mb-1"
                                : "btn btn-primary mb-1"
                            }`}
                            type="button"
                            onClick={handleAddMoreAdmins}
                            disabled={
                              Number(form.noOfAdmins) ===
                              form.adminsObjArr.length
                            }
                          >
                            Add More Admins
                          </button>
                        </Col>

                        <Col md={12}>
                          <table className="mt-3">
                            <thead>
                              <tr>
                                <th></th>
                                <th>FirstName</th>
                                <th>LastName</th>
                                <th>Password</th>
                                <th>Confirm Password</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Alternate Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              {form.adminsObjArr.map((row, index) => {
                                console.log("row: ", row);
                                const passwordValue = watch(
                                  `adminsObjArr.${index}.password`
                                ); // Watch the password field for real-time matching
                                return (
                                  <tr>
                                    <td>
                                      {index != 0 && (
                                        <span
                                          className="p-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleRemove(index)}
                                        >
                                          <i className="iconly-Close-Square icli h2"></i>
                                        </span>
                                      )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]
                                            ?.firstName
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]
                                              ?.firstName
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        placeholder="Enter first name"
                                        {...register(
                                          `adminsObjArr.${index}.firstName`,
                                          {
                                            required:
                                              "Please enter valid first name",
                                          }
                                        )}
                                        value={row.firstName}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "firstName"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]
                                          ?.firstName && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]
                                                ?.firstName?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]
                                            ?.firstName
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.lastName
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]
                                              ?.lastName
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        {...register(
                                          `adminsObjArr.${index}.lastName`,
                                          {
                                            required:
                                              "Please enter valid last name",
                                          }
                                        )}
                                        placeholder="Enter last name"
                                        value={row.lastName}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "lastName"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]
                                          ?.lastName && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]
                                                ?.lastName?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.lastName
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.password
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]
                                              ?.password
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        {...register(
                                          `adminsObjArr.${index}.password`,
                                          {
                                            required:
                                              "Please enter valid password",
                                          }
                                        )}
                                        placeholder="Enter password"
                                        value={row.password}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "password"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]
                                          ?.password && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]
                                                ?.password?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.password
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="password"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]
                                            ?.confirmPassword
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]
                                              ?.confirmPassword
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        {...register(
                                          `adminsObjArr.${index}.confirmPassword`,
                                          {
                                            required:
                                              "Please enter valid password",
                                            validate: (value) =>
                                              value === passwordValue ||
                                              "Passwords do not match", // Custom validation to check match
                                          }
                                        )}
                                        placeholder="Enter confirm pwd"
                                        value={row.confirmPassword}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "confirmPassword"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]
                                          ?.confirmPassword && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]
                                                ?.confirmPassword?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]
                                            ?.confirmPassword
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="email"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.email
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]?.email
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        {...register(
                                          `adminsObjArr.${index}.email`,
                                          {
                                            required:
                                              "Please enter valid email",
                                            pattern: {
                                              value:
                                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                              message: "Invalid email address",
                                            },
                                          }
                                        )}
                                        placeholder="Enter email"
                                        value={row.email}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "email"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]?.email && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]?.email
                                                ?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.email
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td className="position-relative">
                                      <input
                                        type="mobile"
                                        className={`form-control ${
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.phoneNo
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          validate &&
                                          !(
                                            errors.adminsObjArr &&
                                            errors.adminsObjArr?.[index]
                                              ?.phoneNo
                                          )
                                            ? "is-valid"
                                            : ""
                                        }`}
                                        {...register(
                                          `adminsObjArr.${index}.phoneNo`,
                                          {
                                            required:
                                              "Mobile number is required", // Mobile number is required
                                            pattern: {
                                              value: /^[0-9]+$/, // Validate only digits
                                              message:
                                                "Mobile number must contain only digits",
                                            },
                                            minLength: {
                                              value: 10, // Minimum length (for example 10 digits)
                                              message:
                                                "Mobile number must be at least 10 digits long",
                                            },
                                            maxLength: {
                                              value: 10, // Maximum length (for example 10 digits)
                                              message:
                                                "Mobile number must not exceed 10 digits",
                                            },
                                          }
                                        )}
                                        placeholder="Enter mobile"
                                        value={row.phoneNo}
                                        onChange={(e) =>
                                          handleAdminsChange(
                                            e.target.value,
                                            index,
                                            "phoneNo"
                                          )
                                        }
                                      />
                                      {errors.adminsObjArr &&
                                        errors.adminsObjArr?.[index]
                                          ?.phoneNo && (
                                          <span
                                            className="error-icon"
                                            data-tooltip={
                                              errors.adminsObjArr[index]
                                                ?.phoneNo?.message
                                            }
                                          >
                                            <i className="bi bi-exclamation-circle-fill text-danger"></i>
                                          </span>
                                        )}
                                      {validate &&
                                        !(
                                          errors.adminsObjArr &&
                                          errors.adminsObjArr?.[index]?.phoneNo
                                        ) && (
                                          <FormFeedback tooltip valid>
                                            {LooksGood}
                                          </FormFeedback>
                                        )}
                                    </td>
                                    <td>
                                      <InputGroup>
                                        <input
                                          type="mobile"
                                          className={`form-control`}
                                          {...register(
                                            `adminsObjArr.${index}.altPhoneNo`,
                                            {
                                              pattern: {
                                                value: /^[0-9]+$/, // Validate only digits
                                                message:
                                                  "Mobile number must contain only digits",
                                              },
                                              minLength: {
                                                value: 10, // Minimum length (for example 10 digits)
                                                message:
                                                  "Mobile number must be at least 10 digits long",
                                              },
                                              maxLength: {
                                                value: 10, // Maximum length (for example 10 digits)
                                                message:
                                                  "Mobile number must not exceed 10 digits",
                                              },
                                            }
                                          )}
                                          placeholder="Enter alt mobile"
                                          value={row.altPhoneNo}
                                          onChange={(e) =>
                                            handleAdminsChange(
                                              e.target.value,
                                              index,
                                              "altPhoneNo"
                                            )
                                          }
                                        />
                                      </InputGroup>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/users/`
                          );
                        }}
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(AddUsers);
