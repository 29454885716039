import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { connectComponent } from "./connector";
import { EyfsDataItem } from "../../../ReduxToolkit/Slices/Eyfs/EyfsReducer";
import Swal from "sweetalert2";
import { flattenObject } from "../../../Utils";

function Eyfs(props: any) {
  const navigate = useNavigate();
  const [saveData, setSaveData] = useState<EyfsDataItem[]>([]);
  const [filterText, setFilterText] = useState("");
  const [filterObj, setFilterObj] = useState<any>({
    ageRange: "0-2",
  });

  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  useEffect(() => {
    const data = props.eyfs.map((row: any) => {
      return flattenObject(row);
    });
    setSaveData(data);
  }, [props.eyfs]);

  useEffect(() => {
    props.fetchEyfs(filterObj);
  }, [filterObj]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/eyfs/edit/${id}`);
  };

  const handleStatusChange = (row: any) => {
    const selectedValue = row?.isActive === true ? "active" : "inactive";
    const id = row.id;
    Swal.fire({
      title: "Update Status",
      input: "select",
      inputOptions: {
        active: "ACTIVE",
        inactive: "IN ACTIVE",
      },
      inputPlaceholder: "Select a status",
      inputValue: selectedValue,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.updateForm({
          id: id,
          body: { isActive: result.value === "active" ? true : false },
          filterObj,
        });
      }
    });
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Eyfs Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/eyfs/add`);
                  }}
                >
                  Add New Eyfs
                </Button>
              </div>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                  <FilterComponent
                    onFilter={(value) => setFilterObj({ ageRange: value })}
                    filterText={filterObj.ageRange}
                    type="dropdown"
                    dropdown={{
                      id: "dropdown",
                      options: [
                        {
                          value: "0-2",
                          label: "0-2 Years",
                        },
                        {
                          value: "3-4",
                          label: "3-4 Years",
                        },
                        {
                          value: "5+",
                          label: "5+ Years",
                        },
                      ],
                      title: "Eyfs Years",
                    }}
                  />
                  <FilterComponent
                    onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFilterText(e.target.value)
                    }
                    filterText={filterText}
                  />
                </div>

                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true, // This applies to all rows
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [
                    props.isLoading,
                    props.success,
                    saveData,
                    filterObj,
                    props.eyfs,
                  ])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(Eyfs);
