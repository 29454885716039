import { connect } from "react-redux";
import Layout from "./Layout";
import {
  LOGOUT,
  SET_NURSERY,
} from "../../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  console.log("state: ", state);
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(LOGOUT()),
    setNursery: (value: string) => dispatch(SET_NURSERY(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
