import React from "react";
import { connect } from "react-redux";
import { SignIn } from ".";
import {
  authenticateUser,
  SET_ERROR,
  SET_SUCCESS,
} from "../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  console.log("state: ", state);
  return {
    error: state.auth.error,
    success: state.auth.success,
    isLoadingUser: state.auth.isLoadingUser,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setSuccess: (value: string) => dispatch(SET_SUCCESS(value)),
  setError: (value: string) => dispatch(SET_ERROR(value)),
  authenticateUser: (loginObj: any) => dispatch(authenticateUser(loginObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
