import React from "react";
import SearchContainer from "./SearchContainer";
import FullSearch from "./FullSearch";
import NurseryDropDown from "./NurseryDropDown";

export default function HeaderLeft(props: any) {
  return (
    <div className="header-left">
      <FullSearch />
      <NurseryDropDown
        user={props.user}
        setNursery={props.setNursery}
        selectedNursery={props.selectedNursery}
      />
      {/* <SearchContainer /> */}
    </div>
  );
}
