import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../Components/Forms/Common/DropItem";
import { Btn } from "../../../AbstractElements";
import { FirstName, LastName, LooksGood } from "../../../Utils/Constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormDataTypes } from "../../../Types/Forms.type";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
function EditDocuments() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<null | string>(
    "Everyone"
  );
  const [validate, setValidate] = useState(false);
  const [value, setValue] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataTypes>();
  const onSubmit = () => {
    setValidate(true);
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Documents Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/documents/all`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-3">
                        <Col md={8} className="position-relative">
                          <Label>
                            {`Name`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            } ${
                              validate && !errors.firstName ? "is-valid" : ""
                            }`}
                            placeholder="Enter name"
                            {...register("firstName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Share With"}
                            id={"share With"}
                            options={[
                              {
                                value: "Everyone",
                                label: "Everyone",
                              },
                              {
                                value: "Employee Only",
                                label: "Employee Only",
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-3">
                      <Label>
                        {`Description`} <span className="text-danger">*</span>
                      </Label>
                      <ReactQuill value={value} onChange={setValue} />
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Update & Save`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EditDocuments;
