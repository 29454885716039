import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import General from "./General/General";
import Room from "./Room/Room";
import AdminProfile from "../Profile/connector";
import NewPlan from "./NewPlan/NewPlan";

const Settings = (props: any) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          {/* Left Sidebar with Tabs */}
          <Col md={3}>
            <Card className="shadow-sm rounded border-0">
              <CardBody>
                <Nav vertical pills className="flex-column">
                  {/* <Nav tabs className="flex-column"> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1", // Apply active class when the tab is selected
                        "bg-primary": activeTab === "1", // Apply primary background color when active
                        "text-white": activeTab === "1", // Apply white text when active
                        "text-dark": activeTab !== "1", // Apply black text when not active
                      })}
                      onClick={() => toggle("1")}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                        padding: "12px 20px",
                        marginBottom: "5px",
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2", // Apply active class when the tab is selected
                        "bg-primary": activeTab === "2", // Apply primary background color when active
                        "text-white": activeTab === "2", // Apply white text when active
                        "text-dark": activeTab !== "2", // Apply black text when not active
                      })}
                      onClick={() => toggle("2")}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                        padding: "12px 20px",
                        marginBottom: "5px",
                      }}
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "3", // Apply active class when the tab is selected
                        "bg-primary": activeTab === "3", // Apply primary background color when active
                        "text-white": activeTab === "3", // Apply white text when active
                        "text-dark": activeTab !== "3", // Apply black text when not active
                      })}
                      onClick={() => toggle("3")}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                        padding: "12px 20px",
                        marginBottom: "5px",
                      }}
                    >
                      Rooms
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "4", // Apply active class when the tab is selected
                        "bg-primary": activeTab === "4", // Apply primary background color when active
                        "text-white": activeTab === "4", // Apply white text when active
                        "text-dark": activeTab !== "4", // Apply black text when not active
                      })}
                      onClick={() => toggle("4")}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                        padding: "12px 20px",
                        marginBottom: "5px",
                      }}
                    >
                      New Plan
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>

          {/* Right Content for each tab */}
          <Col md={9}>
            <Card className="shadow-sm rounded border-0">
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <CommonCardHeader
                        headClass="pb-0 card-no-border"
                        title="Profile Settings"
                        //   subTitle={[{ text: "Manage your dashboard here" }]}
                        titleClass="mb-0"
                      />
                    </div>
                    <AdminProfile />
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <CommonCardHeader
                        headClass="pb-0 card-no-border"
                        title="General Settings"
                        //   subTitle={[{ text: "Manage your dashboard here" }]}
                        titleClass="mb-0"
                      />
                    </div>
                    <General nurseryId={props.auth.selectedNursery} />
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <CommonCardHeader
                        headClass="pb-0 card-no-border"
                        title="Rooms Settings"
                        //   subTitle={[{ text: "Manage your dashboard here" }]}
                        titleClass="mb-0"
                      />
                    </div>
                    <Room />
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <CommonCardHeader
                        headClass="pb-0 card-no-border"
                        title="Rooms Settings"
                        //   subTitle={[{ text: "Manage your dashboard here" }]}
                        titleClass="mb-0"
                      />
                    </div>
                    <NewPlan />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
