import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Btn, H3, P } from "../../../../AbstractElements";
import { Href, JobToday } from "../../../../Utils/Constants";
import JobCardList from "./JobCardList";
import JobCardTable from "./JobCardTable";
import DropdownCommon from "../../../../Utils/CommonComponents/DropdownCommon";
import { monthlyDropdownList } from "../../../../Data/Dashboard/Default";
import Select from "react-select";

export default function JobCard() {
  const [selectedOption, setSelectedOption] = useState<null | string>(
    "diamond-plan"
  );
  const options = [
    {
      value: "diamond-plan",
      label: "All Room",
    },
    {
      value: "demo-plan",
      label: "Room 1",
    },
  ];

  const handleChange: any = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as any;
      setSelectedOption(newSelectedOption?.value || null);
    }
  };

  return (
    <Col xxl={8} xl={8} lg={12} className="proorder-xxl-7 box-col-12">
      <Card className="job-card">
        <CardHeader className="pb-0 card-no-border">
          <div className="header-top">
            <H3>{"Site Overview"}</H3>
            <div className="d-flex gap-2">
              <div>
                <div className={""}>
                  <Select
                    id={"nationality"}
                    options={options}
                    value={options.find(
                      (option: any) => option.value === selectedOption
                    )}
                    onChange={handleChange}
                    className={`nationality-form w-100`}
                  />
                </div>
              </div>
              <div>
                <Btn color="primary" size="s">
                  Add Room
                </Btn>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-4">
          <JobCardList />
          <JobCardTable />
        </CardBody>
      </Card>
    </Col>
  );
}
