import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkLogin, getUserData } from "./meditator";
import { FcmApiSlice } from "../FCM/FcmApiSlice";
import { PermissionApiSlice } from "../Permission/PermissionApiSlice";
import { fetchRolesByRoleDetail } from "../Permission/meditator";
import UserActivityAPi from "../../../services/UserActivityApi";
import { FirstName } from "../../../Utils/Constants";
import { getUserActivityFormattedData } from "../../../Utils";

interface IChildHelper {
  id: string;
  firstName: string;
  lastName: string;
  nursery: {
    id: string;
    name: string;
  };
  room: {
    id: string;
    roomName: string;
  };
}

interface IUser extends Record<string, unknown> {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "admin" | "employee" | "parent" | "super_admin";
  avatarUrl: string;
  createdAt: string;
  employeeRooms?: any[];
  jobTitle: any;
  children: IChildHelper[];
}

interface IAuthInitialState {
  user: IUser | null;
  isLoadingUser: boolean;
  token: string | null;
  error: string | null;
  success: string | null;
  userIp: string;
  selectedNursery: string;
}

const initialState: IAuthInitialState = {
  user: null,
  isLoadingUser: false,
  token: "",
  error: "",
  success: "",
  userIp: "",
  selectedNursery: "",
};

const UserSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGOUT: (state: IAuthInitialState) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
    },
    SET_USER_DATA: (state, action) => {
      if (action.payload.success) {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.success = "Login Success...!";
      } else {
        state.user = null;
        state.token = "";
        state.success = "";
        state.error = action.payload.error;
      }
    },
    SET_USER: (state, action) => {
      console.log("action: ", action);
      state.user = action.payload;
    },
    SET_ERROR: (state, action) => {
      state.error = action.payload;
    },
    SET_SUCCESS: (state, action) => {
      state.success = action.payload;
    },
    SET_USER_IP: (state, action) => {
      state.userIp = action.payload;
    },
    SET_NURSERY: (state, action) => {
      state.selectedNursery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateUser.pending, (state) => {
      state.isLoadingUser = true;
    });
    builder.addCase(authenticateUser.rejected, (state) => {
      state.isLoadingUser = false;
    });
    builder.addCase(authenticateUser.fulfilled, (state) => {
      state.isLoadingUser = false;
    });

    builder.addCase(getUserInfoData.pending, (state) => {
      state.isLoadingUser = true;
    });
    builder.addCase(getUserInfoData.rejected, (state) => {
      state.isLoadingUser = false;
    });
    builder.addCase(getUserInfoData.fulfilled, (state) => {
      state.isLoadingUser = false;
    });
  },
});

export const authenticateUser = createAsyncThunk(
  "authenticateUser",
  async (loginFormObj: { email: string; password: string }, thunkApi) => {
    const response = await checkLogin(loginFormObj, thunkApi);
    if (response?.data && response?.data?.success) {
      let { token, user } = response?.data;
      user = user ? { ...user, role: user?.role?.toLowerCase() } : null;
      if (token) localStorage.setItem("token", token);

      const storedItem = localStorage.getItem("fcmToken");
      await thunkApi.dispatch(
        FcmApiSlice.endpoints.createFCM.initiate({ token: storedItem })
      );

      if (
        user.role != "super_admin" &&
        (user.nursery != null || user.nursery.length > 0)
      ) {
        const nurseryId = user.nursery[0].id;
        thunkApi.dispatch(SET_NURSERY(nurseryId));
      }

      const roleTitleId = user?.roleTitle?.id;
      if (roleTitleId)
        await thunkApi.dispatch(
          PermissionApiSlice.endpoints.getPermissionByRoleTitleId.initiate(
            roleTitleId
          )
        );

      thunkApi.dispatch(SET_USER_DATA({ token, user, success: true }));

      await UserActivityAPi.addUserActivity(
        getUserActivityFormattedData(
          "Login",
          `${user.firstName} ${user.lastName} is login successfully.`
        )
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      thunkApi.dispatch(SET_USER_DATA({ error, success: false }));
    }
  }
);

export const getUserInfoData = createAsyncThunk(
  "getUserInfoData",
  async (data: {}, thunkApi) => {
    try {
      const res = await getUserData(thunkApi);
      if (res?.error?.data && res?.error?.data?.error) {
        return thunkApi.dispatch(SET_USER(null));
      }

      if (res?.data?.userDetails) {
        const roleTitleId = res?.data?.userDetails?.roleTitle?.id;
        if (roleTitleId) {
          const rolesDetail = await fetchRolesByRoleDetail(
            roleTitleId,
            thunkApi
          );
        }

        let userDetails = res?.data?.userDetails;

        userDetails = userDetails
          ? { ...userDetails, role: userDetails?.role?.toLowerCase() }
          : null;

        // thunkApi.dispatch(SET_USER(userDetails));
      }
    } catch (err) {
      console.log("err: ", err);
    }
  }
);

export const {
  SET_USER_DATA,
  SET_ERROR,
  SET_SUCCESS,
  SET_USER,
  LOGOUT,
  SET_USER_IP,
  SET_NURSERY,
} = UserSlice.actions;
export default UserSlice.reducer;
