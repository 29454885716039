import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UserApi from "../../../services/UserApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";

interface IForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<IForm>();

  const onSubmit = async (data: IForm) => {
    if (data.newPassword !== data.confirmPassword) {
      Swal.fire({
        title: "Error!",
        text: "New password and confirm password do not match.",
        icon: "error",
      });
      return;
    }

    try {
      await UserApi.changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      Swal.fire({
        title: "Success!",
        text: "Password changed successfully",
        icon: "success",
      }).then(() => {
        navigate(`${process.env.PUBLIC_URL}/dashboard`); // Navigate to the dashboard or a relevant page
      });
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Change Password`}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <div className="col-md-6 offset-3"> {/* Centering the form */}
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="g-3">
                      {/* Old Password Field */}
                      <Col md={12}>
                        <Label for="oldPassword">Old Password</Label>
                        <input
                          type="password"
                          {...register("oldPassword", { required: true })}
                          className={`form-control ${errors.oldPassword ? "is-invalid" : ""}`}
                        />
                        {errors.oldPassword && <div className="invalid-feedback">Old password is required</div>}
                      </Col>

                      {/* New Password Field */}
                      <Col md={12}>
                        <Label for="newPassword">New Password</Label>
                        <input
                          type="password"
                          {...register("newPassword", { required: true, minLength: 6 })}
                          className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                        />
                        {errors.newPassword && <div className="invalid-feedback">New password must be at least 6 characters</div>}
                      </Col>

                      {/* Confirm Password Field */}
                      <Col md={12}>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <input
                          type="password"
                          {...register("confirmPassword", { required: true })}
                          className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                        />
                        {errors.confirmPassword && <div className="invalid-feedback">Please confirm your new password</div>}
                      </Col>

                      <Col md={12} className="text-end">
                        <Button type="submit" color="primary">Change Password</Button>
                      </Col>
                    </Row>
                  </Form>
                </div> {/* End of centered form div */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
