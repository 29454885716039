import { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { manageRoutes } from "./Helper";

const CustomRoutes = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search, hash } = location;
  const [ip, setIP] = useState("");

  useEffect(() => {
    props.getUserInfo();

    // if (
    //   !skipAuthenticationPublicPath.includes(pathname) &&
    //   props.auth.user === null
    // ) {
    //   navigate("/login");
    // }

    // Replace the URL below with any public IP API
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIP(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  useEffect(() => {
    props.setUserIP(ip);
  }, [ip]);

  return <MyRoutes auth={props.auth} />;
};

const MyRoutes = (props: any) => {
  return useRoutes([...manageRoutes(props.auth)]);
};

export default CustomRoutes;
