import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import {
  AuditLogTitle,
  DashboardTitle,
  DefaultDashboardTitle,
  LatestActivityTitle,
} from "../../../Utils/Constants";
import EarningCard from "../../../Components/Dashboard/Default/EarningCard";
import JobCard from "../../../Components/Dashboard/Default/JobCard";
import TransitionHistory from "../../../Components/Dashboard/Default/TransitionHistory";
import CommonListComponent from "../../../Components/Dashboard/Default/Common/CommonListComponent";
import TotalInvestment from "../../../Utils/CommonComponents/CommonDashboard/CommonDefault/TotalInvestment";
import GrowthCard from "../../../Utils/CommonComponents/CommonDashboard/CommonDefault/GrowthCard";
import TopUsers from "../../../Components/Dashboard/Default/TopUsers";
import NewsUpdate from "../../../Components/Dashboard/Default/NewsUpdate";
import ManageInvoice from "../../../Components/Dashboard/Default/ManageInvoice";
import TotalInvest from "../../../Components/Dashboard/Default/TotalInvest";
import {
  auditLogData,
  investmentChart,
  latestActivityData,
} from "../../../Data/Dashboard/Default";
import WelcomeBanner from "./Component/WelcomeBanner";
import DropdownCommon from "../../../Utils/CommonComponents/DropdownCommon";
import { Fragment } from "react/jsx-runtime";
import UpcomingCard from "../../../Utils/CommonComponents/CommonDashboard/CommonProject/UpcomingCard";
import NewCourses from "../../../Components/Dashboard/Education/NewCourses";
import ActivityTimeline from "../../../Components/Dashboard/Ecommerce/ActivityTimeline";
import ScheduleLesson from "../../../Utils/CommonComponents/CommonDashboard/CommonProject/ScheduleLesson";
import SmallWidget from "../../../Components/Widgets/General/WidgetCharts/SmallWidget";
import WidgetCharts from "../../../Components/Widgets/General/WidgetCharts";

export default function AdminDashboard() {
  const item = {
    class: "secondary",
    bodyClass: "dropdown-block",
    text: "Ecommerce",
    menulist: ["Product", "Product details", "Cart"],
  };
  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={`Dashboard`}
        parent={DashboardTitle}
        title={`Dashboard`}
        paragraph='"Welcome back! Let’s start from where you left."'
      />
      <Container className="default-dashboard dashboard-3 dashboard-2" fluid>
        <Row>
          <WelcomeBanner />
          <Col xxl={8} xl={12}>
            <Container
              className="general-widget"
              fluid
              style={{ marginTop: "4%" }}
            >
              <Row>
                <WidgetCharts />
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <JobCard />
          <UpcomingCard
            colClass="col-xl-4 order-sm-1 order-xl-0 box-col-5"
            cardClass="upcoming-card"
          />
          <NewCourses />
          <ActivityTimeline />
          <CommonListComponent
            colClass="proorder-xxl-12 box-col-6"
            title={`User Activity`}
            bodyClass="latest-activity"
            data={latestActivityData}
          />
          <ScheduleLesson colClass="col-xxl-8 col-xl-5 box-col-6" />
        </Row>
      </Container>
    </div>
  );
}
