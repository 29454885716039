export const sidebarIconList = [
    {
        id: '1', class: 'default', check: true, text: 'Stroke', iconClass: 'stroke-svg'
    },
    { id: '2', class: 'colorfull', check: false, text: 'Colorfull icon', iconClass: 'iconcolor-sidebar' },
]

export const layoutTypeData = [
    { id: 1, type: "ltr", label: "LTR", check: true, class: 'ltr' },
    { id: 2, type: "rtl", label: "RTL", check: false, class: 'rtl' },
    { id: 3, type: "box-layout", label: "Box", check: false, class: 'box' },
];

export const unlimitedColorOptions = [
    { name: "1", primary: "#308e87", secondary: "#f39159" },
    { name: "2", primary: "#57375D", secondary: "#FF9B82" },
    { name: "3", primary: "#0766AD", secondary: "#29ADB2" },
    { name: "4", primary: "#025464", secondary: "#E57C23" },
    { name: "5", primary: "#884A39", secondary: "#C38154" },
    { name: "6", primary: "#0C356A", secondary: "#FFC436" },
];