import api from "./api"; // Import the Axios instance with interceptor

export default class UserApi {
  // Fetch user information
  static async getUserInfo() {
    try {
      const response = await api.get("/user/detail");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Login user
  static async login(credentials: { email: string; password: string }) {
    try {
      const response = await api.post("/user/login", credentials);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Register email for password reset
  static async registerEmailForResetPassword(credentials: { email: string }) {
    try {
      const response = await api.post(
        "/user/reset-password-email",
        credentials
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Reset password with token
  static async resetPassword(credentials: {
    token: string;
    newPassword: string;
  }) {
    try {
      const response = await api.patch(
        `/user/reset-password/?token=${credentials.token}`,
        {
          newPassword: credentials.newPassword,
        }
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Change password
  static async changePassword(credentials: {
    oldPassword: string;
    newPassword: string;
  }) {
    try {
      const response = await api.patch("/user/change-password", credentials);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // delete admin users
  static async deleteAdminUser(
    credentials: { isDeleted: boolean },
    id: string
  ) {
    try {
      const response = await api.patch(
        `/user/deleteAdminUser/${id}`,
        credentials
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // update user
  static async updateUserProfile(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/updateAdmin/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // update user
  static async checkPassword(credentials: any) {
    console.log("credentials: ", credentials);
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.post(`/user/getPin/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
