import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../../Components/Forms/Common/DropItem";
import { Btn } from "../../../../AbstractElements";
import { FirstName, LastName, LooksGood } from "../../../../Utils/Constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormDataTypes } from "../../../../Types/Forms.type";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function AddChildren() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  const [validate, setValidate] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataTypes>();
  const onSubmit = () => {
    setValidate(true);
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Children Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/users/children`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Personal Detail
                      </span>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {FirstName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            } ${
                              validate && !errors.firstName ? "is-valid" : ""
                            }`}
                            placeholder="Enter first name"
                            {...register("firstName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {LastName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter last name"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your email"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="mobile"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your mobile"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Password"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter password"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Join date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className={`form-control flatpickr-input ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              selected={new Date()}
                              onChange={() => {}}
                              dateFormat="MMMM dd,yyyy"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Date of Birth"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className={`form-control flatpickr-input ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              selected={new Date()}
                              onChange={() => {}}
                              dateFormat="MMMM dd,yyyy"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Nationality"}
                            id={"nationality"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Roles"}
                            id={"roles"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Photo"}</Label>
                          <div style={{ display: "flex" }}>
                            <FilePond
                              files={files}
                              allowReorder={true}
                              allowMultiple={true}
                              maxFiles={1}
                              onupdatefiles={() => setFiles}
                              labelIdle={`Upload attachment`}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-download text-primary" />
                            </InputGroupText>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Home Address
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"Address Line 1"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter Address Line 1"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Address Line 2"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter Address Line 2"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"City"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter City"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Post Code"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter Post Code"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Emergency Contact
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"First Name"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter First Name"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Last Name"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter Last Name"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your email"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="mobile"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your mobile"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Relationship"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            placeholder="Enter Relationship"
                            {...register("lastName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Employment Details
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Employment Type"}
                            id={"employment Type"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4}>
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Job Title"}
                            id={"job Title"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Monthly Salary"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Nursery Details
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Nursery"}
                            id={"nursery"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Classroom"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter All Room"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Assigned as"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter Main Staff"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Upload Files
                      </span>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>{"Attachment"}</Label>
                          <div style={{ display: "flex" }}>
                            <FilePond
                              files={files}
                              allowReorder={true}
                              allowMultiple={true}
                              maxFiles={1}
                              onupdatefiles={() => setFiles}
                              labelIdle={`Upload attachment`}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-download text-primary" />
                            </InputGroupText>
                          </div>
                        </Col>
                        <Col md={4}>
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Save Folder"}
                            id={"save Folder"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
