import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  TabPane,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import UserApi from "../../../../services/UserApi";
import Swal from "sweetalert2";

function Password(props: any) {
  return (
    <TabPane tabId={`2`} key={1}>
      <Card className="mt-2">
        <PasswordForm user={props.user} />
        <PinForm user={props.user} />
      </Card>
    </TabPane>
  );
}

function PasswordForm(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const initialState = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  console.log("errors: ", errors);

  const [form, setForm] = useState<any>(initialState);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmitPasswordForm = async () => {
    let formData = JSON.parse(JSON.stringify(form));
    formData.id = props.user != null ? props.user.id : "";
    try {
      const data = await UserApi.updateUserProfile(formData);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating
            reset(initialState);
            setForm(initialState);
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("err: ", error.message);
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  const passwordValue = watch(`password`); // Watch the password field for real-time matching
  return (
    <Form onSubmit={handleSubmit(onSubmitPasswordForm)}>
      <CardBody>
        <Row>
          <Col md={7}>
            <FormGroup>
              <Label>{"Current Password"}</Label>
              <input
                className="form-control"
                type="password"
                placeholder="Current Password"
                {...register("currentPassword", { required: true })}
                value={form.currentPassword}
                onChange={(e) =>
                  saveDataForm("currentPassword", e.target.value)
                }
              />
              {errors.currentPassword && (
                <span style={{ color: "red" }}>
                  {"Current Password is required"}{" "}
                </span>
              )}
            </FormGroup>
          </Col>

          <Col md={7}>
            <FormGroup>
              <Label>{"New Password"}</Label>
              <input
                className="form-control"
                type="password"
                placeholder="New Password"
                {...register("password", { required: true })}
                value={form.password}
                onChange={(e) => saveDataForm("password", e.target.value)}
              />
              {errors.password && (
                <span style={{ color: "red" }}>{"Password is required"} </span>
              )}
            </FormGroup>
          </Col>

          <Col md={7}>
            <FormGroup>
              <Label>{"Confirm New Password"}</Label>
              <input
                className="form-control"
                type="password"
                placeholder="Confirm New Password"
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) =>
                    value === passwordValue || "Passwords do not match",
                })}
                value={form.confirmPassword}
                onChange={(e) =>
                  saveDataForm("confirmPassword", e.target.value)
                }
              />
              {errors.confirmPassword && (
                <span style={{ color: "red" }}>
                  {errors.confirmPassword.message
                    ? "Passwords do not match"
                    : "Confirm Password is required"}{" "}
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={7}>
            <Btn color="primary">{`Save Password`}</Btn>
          </Col>
        </Row>
      </CardBody>
    </Form>
  );
}

function PinForm(props: any) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const initialState = {
    pin: "",
    password: "",
    id: "",
  };

  useEffect(() => {
    if (props.user) {
      const formData = {
        pin: props.user.pinNo,
        password: "",
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    }
  }, [props]);

  const [form, setForm] = useState<any>(initialState);
  const [fieldType, setFieldType] = useState<any>("password");

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmitPinForm = async () => {
    try {
      const data = await UserApi.checkPassword(form);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            if (props.user) {
              const formData = {
                pin: props.user.pinNo,
                password: "",
                id: props.user.id,
              };

              reset(formData);
              setForm(formData);
              setFieldType("text");
            }
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitPinForm)}>
      <CardBody>
        <Row>
          <Col md={7}>
            <FormGroup>
              <Label>{"Pin"}</Label>
              <input
                className="form-control"
                type={fieldType}
                placeholder="Pin"
                {...register("pin", { required: true })}
                value={form.pin}
                disabled={true}
              />
              {errors.company && (
                <span style={{ color: "red" }}>{"Pin is required"} </span>
              )}
            </FormGroup>
          </Col>

          <Col md={7}>
            <FormGroup>
              <Label>{"Password"}</Label>
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                {...register("password", { required: true })}
                value={form.password}
                onChange={(e) => saveDataForm("password", e.target.value)}
              />
              {errors.password && (
                <span style={{ color: "red" }}>{"Password is required"} </span>
              )}
            </FormGroup>
          </Col>
          <Col md={7}>
            <Btn color="primary">{`Show Pin`}</Btn>
          </Col>
        </Row>
      </CardBody>
    </Form>
  );
}

export default Password;
