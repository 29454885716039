import { EditForm, IForm } from "../../../Pages/SuperAdmin/Users/Helper/types";
import { AdminUserApiSlice } from "./AdminApiSlice";

export const getAdminData = async (filterObj: any, dispatch: any) => {
  return dispatch(
    AdminUserApiSlice.endpoints.getAdmin.initiate(filterObj, {
      forceRefetch: true,
    })
  );
};

export const addAdminData = async (formData: IForm, thunkApi: any) => {
  return thunkApi.dispatch(
    AdminUserApiSlice.endpoints.addAdmin.initiate(formData)
  );
};

export const updateAdminData = async (formData: EditForm, thunkApi: any) => {
  return thunkApi.dispatch(
    AdminUserApiSlice.endpoints.updateAdmin.initiate(formData)
  );
};
