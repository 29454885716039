import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import { colorOptions, LooksGood } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { connectComponent } from "./connector";
import { IForm } from "./Helper/types";
import Swal from "sweetalert2";
import Select from "react-select";

function EditEyfs(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();

  const initialState: IForm = {
    title: "",
    color: "",
    ageRange: "0-2",
    content: "",
  };
  const [form, setForm] = useState<IForm>({
    ...initialState,
  });

  const [validate, setValidate] = useState(false);
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();
  const onSubmit = () => {
    setValidate(true);
    // console.log("form: ", form);
    props.updateForm({
      id,
      body: form,
      filterObj: { ageRange: "0-2" },
    });
  };

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const editItem = props.eyfs.filter((data: any) => data.id === id);
    if (editItem.length > 0) {
      const formData = {
        title: editItem[0].title,
        color: editItem[0].color,
        ageRange: editItem[0].ageRange,
        content: editItem[0].content,
      };
      reset(formData); // Reset the form with fetched user data
      setForm(formData);
    }
  }, [id, reset]);

  useEffect(() => {
    if (props.success && props.success?.message) {
      Swal.fire({
        title: "Successfully !!!",
        text: props.success.message,
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`${process.env.PUBLIC_URL}/dashboard/eyfs/`, {
            replace: true,
          });
          // Clear the success message after navigating
          props.setMessage({ success: {}, error: {} });
        }
      });
    }

    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Eyfs Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/eyfs/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {`Years Type`}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div
                            className="custom-radio d-flex"
                            style={{ gap: "2rem" }}
                          >
                            <FormGroup className={`radio radio-info`} check>
                              <Input
                                id={`radio1`}
                                type="radio"
                                defaultChecked={true}
                                value={"0-2"}
                                {...register("ageRange")}
                                onChange={(e) => {
                                  saveDataForm("ageRange", e.target.value);
                                }}
                              />
                              <Label htmlFor={`radio1`} check>
                                0-2 Years
                              </Label>
                            </FormGroup>
                            <FormGroup className={`radio radio-info`} check>
                              <Input
                                id={`radio2`}
                                type="radio"
                                defaultChecked={false}
                                value={"3-4"}
                                {...register("ageRange")}
                                onChange={(e) => {
                                  saveDataForm("ageRange", e.target.value);
                                }}
                              />
                              <Label htmlFor={`radio2`} check>
                                3-4 Years
                              </Label>
                            </FormGroup>
                            <FormGroup className={`radio radio-info`} check>
                              <Input
                                id={`radio3`}
                                type="radio"
                                value={"5+"}
                                {...register("ageRange")}
                                onChange={(e) => {
                                  saveDataForm("ageRange", e.target.value);
                                }}
                                defaultChecked={false}
                              />
                              <Label htmlFor={`radio3`} check>
                                5+ Years
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={9} className="position-relative">
                          <Label>
                            {`Title`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            } ${validate && !errors.title ? "is-valid" : ""}`}
                            placeholder="Enter title name"
                            {...register("title", {
                              required: "Please enter your valid name",
                            })}
                            value={form.title}
                            onChange={(e) => {
                              saveDataForm("title", e.target.value);
                            }}
                          />
                          {errors.title && (
                            <FormFeedback tooltip>
                              {errors.title.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.title && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={3} className="position-relative">
                          <Label>
                            {"Customize Color"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="color"
                            control={control}
                            rules={{
                              required: "Please select any one color",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="customize-color"
                                className={`${
                                  errors.color ? "is-invalid" : ""
                                } ${
                                  validate && !errors.color ? "is-valid" : ""
                                }`}
                                options={colorOptions}
                                value={colorOptions.find(
                                  (option: any) => option.value === field.value
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm("color", selectedOption.value);
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.color && (
                            <FormFeedback tooltip style={{ display: "block" }}>
                              <>{errors?.color?.message}</>
                            </FormFeedback>
                          )}
                          {validate && !errors.color && (
                            <FormFeedback
                              tooltip
                              valid
                              style={{ display: "block" }}
                            >
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-3 position-relative">
                      <Label>
                        {`Content`} <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Content is required" }} // Validation for the content field
                        render={({ field }) => {
                          console.log("field: ", field);
                          return (
                            <ReactQuill
                              theme="snow"
                              value={field.value}
                              onChange={(value) => {
                                field.onChange(
                                  value === "<p><br></p>" ? "" : value
                                );
                                saveDataForm(
                                  "content",
                                  value === "<p><br></p>" ? "" : value
                                );
                              }} // Update form value on change
                              onBlur={field.onBlur} // Optional: handle onBlur event
                            />
                          );
                        }}
                      />
                      {errors.content && (
                        <FormFeedback
                          tooltip
                          style={{
                            display: "block",
                            top: "-7px",
                            left: "120px",
                            right: "unset",
                          }}
                        >
                          {errors.content.message}
                        </FormFeedback>
                      )}
                      {validate && !errors.content && (
                        <FormFeedback
                          tooltip
                          valid
                          style={{ display: "block" }}
                        >
                          {LooksGood}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Update & Save`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                        type="button"
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/dashboard/eyfs/`);
                        }}
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(EditEyfs);
