import { authApiSlice } from "./AuthApiSlice";

export const checkLogin = async (loginFormObj: any, thunkApi: any) => {
  return await thunkApi.dispatch(
    authApiSlice.endpoints.login.initiate(loginFormObj)
  );
};

export const getUserData = async (thunkApi: any) => {
  return await thunkApi.dispatch(
    authApiSlice.endpoints.getUserInfo.initiate({}, { forceRefetch: true })
  );
};
