import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import Select from "react-select";
import { FaImage, FaSmile, FaTimes, FaVideo } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";
import UploadAPi from "../../../services/UploadApi";
import PostAPi from "../../../services/PostApi";
import { baseUrl } from "../../../Utils/Constants/config";
import { fetchImage } from "../../../Utils";
import { H3, H6 } from "../../../AbstractElements";

function NewsFeed(props: any) {
  const [postContent, setPostContent] = useState<string>("");
  const [postImage, setPostImage] = useState<string | null>(null);
  const [media, setMedia] = useState({ mediaUrl: "", mediaType: "" });
  const [postVideo, setPostVideo] = useState<string | null>(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
  const [postVisibility, setPostVisibility] = useState<string>("Everyone");
  const [post, setPost] = useState<any[]>([]);
  const [mediaUrls, setMediaUrls] = useState<any[]>([]);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData: any = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder", "newsFeed");
      try {
        const response = await UploadAPi.uploadFile(
          formData,
          "upload-news-feed"
        );
        setMedia({ mediaUrl: response.filePath, mediaType: "image" });
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setPostImage(imageUrl);
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData: any = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder", "newsFeed");
      try {
        const response = await UploadAPi.uploadFile(
          formData,
          "upload-news-feed"
        );
        setMedia({ mediaUrl: response.filePath, mediaType: "video" });
        const videoUrl = URL.createObjectURL(e.target.files[0]);
        setPostVideo(videoUrl);
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    setPostContent((prevContent) => prevContent + emoji.emoji);
    setEmojiPickerOpen(false);
  };

  const handleRemove = async (type: string) => {
    try {
      await UploadAPi.removeUploadedFile(
        { filePath: media.mediaUrl },
        "remove-news-feed"
      );
      setMedia({ mediaUrl: "", mediaType: "" });
      if (type == "image") {
        setPostImage(null);
      } else {
        setPostVideo(null);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const handleSubmit = async () => {
    // Post submission logic
    if (postContent != "" && (postImage != null || postVideo != null)) {
      const body = {
        content: postContent,
        mediaUrl: media.mediaUrl, // will replaced by image url which comes in response of upload api
        mediaType: media.mediaType.toUpperCase(),
        visibility: postVisibility.toUpperCase(),
        authorId: props.auth.user?.id,
        nurseryId: props.auth.selectedNursery,
      };
      try {
        const response = await PostAPi.createPost(body);
        console.log("response: ", response);
        const updated = [...post, response.data];
        setPost(updated);

        setPostContent("");
        setPostImage(null);
        setPostVideo(null);
        setMedia({ mediaUrl: "", mediaType: "" });
        setPostVisibility("Everyone");
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  useEffect(() => {
    const getPosts = async () => {
      try {
        const responseData = await PostAPi.fetchPost({
          page: 1,
          pageSize: 1000,
          nurseryId: props.auth.selectedNursery,
        });

        if (responseData) {
          setPost(responseData.data.list);
        }
        console.log("responseData: ", responseData);
      } catch (err) {
        console.log("err: ", err);
      }
    };

    getPosts();
  }, [props.auth.selectedNursery]);

  useEffect(() => {
    const fetchAllMedia = async () => {
      const urls = await Promise.all(
        post.map(async (row: any) => {
          const mediaUrl = `${baseUrl}${row.mediaUrl}`;
          const urlData = await fetchImage(mediaUrl);
          return { ...row, mediaUrl: urlData }; // Return the row data with the resolved urlData
        })
      );
      setMediaUrls(urls); // Update state with fetched media URLs
    };

    fetchAllMedia();
  }, [post, baseUrl]);
  console.log("post: ", post);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Create Post"
                titleClass="mb-3"
              />
              <CardBody>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row className="align-items-center mb-3">
                    <Col md={10}>
                      <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center me-3">
                          <div
                            className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                            style={{
                              width: "40px",
                              height: "40px",
                              fontSize: "16px",
                            }}
                          >
                            DE
                          </div>
                          <span className="ms-2">
                            {props.auth.user.firstName}{" "}
                            {props.auth.user.lastName}
                          </span>
                        </div>
                      </div>
                      <Label for="postContent" className="form-label">
                        Write a post
                      </Label>
                      <Input
                        type="textarea"
                        id="postContent"
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                        rows={4}
                        placeholder="What's on your mind?"
                      />
                    </Col>
                    <Col md={2}>
                      <select
                        value={postVisibility}
                        onChange={(e) => setPostVisibility(e.target.value)}
                        className="form-select"
                      >
                        <option value="Everyone">Everyone</option>
                        <option value="Children">Children</option>
                        <option value="Parents">Parents</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="mb-4 position-relative">
                    {/* Icon buttons for Image, Video, and Emoji */}
                    <Col>
                      {postImage == null && postVideo == null && (
                        <>
                          <FaImage
                            size={24}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() =>
                              document.getElementById("postImageInput")?.click()
                            }
                          />
                          <Input
                            type="file"
                            id="postImageInput"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />
                        </>
                      )}

                      {postImage == null && postVideo == null && (
                        <>
                          <FaVideo
                            size={24}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() =>
                              document.getElementById("postVideoInput")?.click()
                            }
                          />
                          <Input
                            type="file"
                            id="postVideoInput"
                            accept="video/*"
                            style={{ display: "none" }}
                            onChange={handleVideoChange}
                          />
                        </>
                      )}

                      <FaSmile
                        size={24}
                        style={{ cursor: "pointer" }}
                        onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                      />
                      {emojiPickerOpen && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "6.5rem",
                          }}
                        >
                          <FaTimes
                            size={24}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "22px",
                              right: "6px",
                              zIndex: 1,
                            }}
                            onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                          />
                          <EmojiPicker
                            className="mt-3 pt-3"
                            style={{ zIndex: 1 }}
                            onEmojiClick={handleEmojiSelect}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  {/* Preview section */}
                  <div className="preview-section mb-4">
                    {postImage && (
                      <>
                        <FaTimes
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove("image")}
                        />
                        <img
                          src={postImage}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ width: "100px", marginBottom: "10px" }}
                        />
                      </>
                    )}
                    {postVideo && (
                      <>
                        <FaTimes
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove("video")}
                        />
                        <video
                          controls
                          src={postVideo}
                          style={{ width: "100px", marginBottom: "10px" }}
                        />
                      </>
                    )}
                  </div>

                  <Button color="primary" onClick={handleSubmit}>
                    Post
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <CardBody>
                <div>
                  {mediaUrls.map((row: any) => {
                    return (
                      <div>
                        <H3>{row.visibility}</H3>
                        <H6>{row.content}</H6>
                        {row.mediaType === "IMAGE" ? (
                          <img
                            src={row.mediaUrl}
                            className="img-thumbnail"
                            style={{ width: "100px", marginBottom: "10px" }}
                          />
                        ) : (
                          <video
                            controls
                            src={row.mediaUrl}
                            className="img-thumbnail"
                            style={{ width: "100px", marginBottom: "10px" }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewsFeed;
