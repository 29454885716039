import { PackagePlanApiSlice } from "./PackageApiSlice";

export const fetchPlans = async (params: any, dispatch: any) => {
  return dispatch(
    PackagePlanApiSlice.endpoints.getPackagePlan.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const createPlans = async (params: any, thunkApi: any) => {
  return thunkApi.dispatch(
    PackagePlanApiSlice.endpoints.addPlan.initiate(params)
  );
};

export const updatePlan = async (params: any, thunkApi: any) => {
  return thunkApi.dispatch(
    PackagePlanApiSlice.endpoints.updatePlan.initiate(params)
  );
};
