import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import DropdownWithHeader from "../../../../Utils/CommonComponents/DropdownWithHeader";
import { NewCoursesTitle } from "../../../../Utils/Constants";
import { monthlyDropdownList } from "../../../../Data/Dashboard/Default";
import { newCoursesData } from "../../../../Data/Dashboard/Project";
import { Btn, H3, H5, Image, P } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Utils";

export default function NewCourses() {
  return (
    <Col
      xxl={4}
      xl={6}
      lg={6}
      md={7}
      className="order-xxl-0 order-xl-4 box-col-6"
    >
      <Card style={{ height: "480px" }}>
        <CardHeader className={`card-no-border pb-0`}>
          <div className={`header-top`}>
            <H3 className={""}>{`Upcoming Medication`}</H3>
            <div className={""}>
              <Btn color="primary" size="s">
                Add Form
              </Btn>
            </div>
          </div>
        </CardHeader>
        <CardBody className="course-table pt-3">
          <div className="table-responsive">
            <Table borderless>
              <tbody>
                {newCoursesData.map((item) => (
                  <tr key={item.id} style={{ borderBottom: "2px solid #eee" }}>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <div className="flex-shrink-0 comman-round">
                          <div className="icon">
                            <Image
                              className="img-fluid"
                              src={dynamicImage(
                                `dashboard-3/user/${item.image}`
                              )}
                              alt="chair"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <Link
                            to={`${process.env.PUBLIC_URL}/ecommerce/product_page`}
                          >
                            <H5>{"Person Name"}</H5>
                          </Link>
                          <P>{"sub title"} . 2 Puffs</P>
                        </div>
                      </div>
                    </td>
                    <td>
                      <H5>{`Explorers`}</H5>
                      <P>{`${item.time} hours`}</P>
                    </td>
                    <td>{`${item.left} days left`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
