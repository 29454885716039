import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";

// Dummy room data for dropdown options
const roomOptions = [
  { id: 1, name: "Room 1" },
  { id: 2, name: "Room 2" },
  { id: 3, name: "Room 3" },
];

// Interface for form data
interface IForm {
  whenLeaving: string;
  children: string;
  nextRoom?: string;
  moveRule: string;
}

const RoomMoves: React.FC = () => {
  const { register, handleSubmit, control, watch, formState: { errors } } = useForm<IForm>();
  const childrenSelection = watch("children"); // Watches 'children' field to conditionally render `Next Room`

  // Dummy function to handle form submission
  const onSubmit = (data: IForm) => {
    Swal.fire({
      title: "Form Submitted",
      text: JSON.stringify(data, null, 2),
      icon: "success",
    });
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="flex-grow-1 text-left"
                  title="Room Moves"
                />                
              </div>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    
                    {/* When Leaving: Rooms Dropdown */}
                    <Col md={4}>
                      <Label for="whenLeaving">When Leaving</Label>
                    </Col>
                    <Col md={8}>
                      <Controller
                        name="whenLeaving"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select a room" }}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={`form-control ${errors.whenLeaving ? "is-invalid" : ""}`}
                          >
                            <option value="">Select Room</option>
                            {roomOptions.map((room) => (
                              <option key={room.id} value={room.name}>
                                {room.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.whenLeaving && (
                        <FormFeedback>{errors.whenLeaving.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Children: Radio Options */}
                    <Col md={4}>
                      <Label>Children</Label>
                    </Col>
                    <Col md={8}>
                      <div className="d-flex align-items-center">
                        <Label className="me-3">
                          <input
                            type="radio"
                            value="Advance"
                            {...register("children", { required: "Select an option" })}
                          />{" "}
                          Advance to the Next Group
                        </Label>
                        <Label>
                          <input
                            type="radio"
                            value="Leave"
                            {...register("children")}
                          />{" "}
                          Leave the Nursery
                        </Label>
                      </div>
                      {errors.children && (
                        <FormFeedback className="d-block">
                          {errors.children.message}
                        </FormFeedback>
                      )}
                    </Col>

                    {/* Next Room: Rooms Dropdown (only shows when "Advance" is selected) */}
                    {childrenSelection === "Advance" && (
                      <>
                        <Col md={4}>
                          <Label for="nextRoom">Next Room</Label>
                        </Col>
                        <Col md={8}>
                          <Controller
                            name="nextRoom"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Please select a room" }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`form-control ${errors.nextRoom ? "is-invalid" : ""}`}
                              >
                                <option value="">Select Room</option>
                                {roomOptions.map((room) => (
                                  <option key={room.id} value={room.name}>
                                    {room.name}
                                  </option>
                                ))}
                              </select>
                            )}
                          />
                          {errors.nextRoom && (
                            <FormFeedback>{errors.nextRoom.message}</FormFeedback>
                          )}
                        </Col>
                      </>
                    )}

                    {/* Move Rules: Radio Options */}
                    <Col md={4}>
                      <Label>Move Rules</Label>
                    </Col>
                    <Col md={8}>
                      <div className="d-flex align-items-center">
                        <Label className="me-3">
                          <input
                            type="radio"
                            value="Specific Date"
                            {...register("moveRule", { required: "Select an option" })}
                          />{" "}
                          On a Specific Date after reaching a certain age
                        </Label>
                        <Label>
                          <input
                            type="radio"
                            value="Certain Age"
                            {...register("moveRule")}
                          />{" "}
                          When reaching a certain age
                        </Label>
                      </div>
                      {errors.moveRule && (
                        <FormFeedback className="d-block">
                          {errors.moveRule.message}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>

                  {/* Action Buttons */}
                  <Row className="mt-4">
                    <Col xs={12} className="text-end">
                      <Button type="submit" color="primary" className="btn-md me-3">
                        Save
                      </Button>                      
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RoomMoves;
