import { connect } from "react-redux";
import {
  addAdminUser,
  LOAD_ADMIN_USER,
  SET_MESSAGE,
  updateAdminUser,
} from "../../../ReduxToolkit/Slices/Admin/AdminReducer";
import { FETCH_PLANS } from "../../../ReduxToolkit/Slices/Package/PackageReducer";

const mapStateToProps = (state: any) => {
  return {
    admins: state.adminUser.admins,
    count: state.adminUser.count,
    nurseries: state.adminUser.nurseries,
    packages: state.adminUser.packages,
    packagesCount: state.adminUser.packagesCount,
    plans: state.plans.plans,
    purchasedPlans: state.plans.purchasedPlans,
    isLoading: state.adminUser.isLoading,
    error: state.adminUser.error,
    success: state.adminUser.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadAdminData: (value: any) => dispatch(LOAD_ADMIN_USER(value)),
    fetchPlans: (value: any) => dispatch(FETCH_PLANS(value)),
    addForm: (value: any) => dispatch(addAdminUser(value)),
    updateForm: (value: any) => dispatch(updateAdminUser(value)),
    setMessage: (value: any) => dispatch(SET_MESSAGE(value)),
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

// export default connect(mapStateToProps, mapDispatchToProps)(Users);
