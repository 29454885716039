import api from "./api"; // Use the Axios instance with interceptor

export default class FaqApi {
  static async getFaqs() {
    try {
      const response = await api.get("/faq/all"); // Changed to /faq/all
      
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createFaq(data: { title: string; content: string }) {
    try {
      const response = await api.post("/faq/", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;

    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateFaq(id: string, data: { title: string; content: string }) {
      try {
          const response = await api.patch(`/faq/${id}`, data); // Change to PATCH
          console.log("Updated FAQ: ", response.data); // Log the fetched data
          return response.data;
      } catch (error: any) {
          const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
          throw new Error(errorMessage);
      }
  }


  // New method for deleting an FAQ
  static async deleteFaq(id: string) {
    try {
      const response = await api.delete(`/faq/${id}`); // Changed to /faq/${id}
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
