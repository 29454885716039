import React from "react";
import { ToastContainer } from "react-toastify";
import Routers from "./routes/connector";

function App() {
  return (
    <>
      <Routers />
      <ToastContainer />
    </>
  );
}

export default App;
