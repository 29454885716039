import { createListenerMiddleware } from "@reduxjs/toolkit";
import { FETCH_EYFS, SET_EYFS } from "./EyfsReducer";
import { fetchEyfs } from "./meditator";

export const EyfsListener = createListenerMiddleware();

EyfsListener.startListening({
  actionCreator: FETCH_EYFS,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    const { dispatch } = listenerAPI;
    const resp = await fetchEyfs(action.payload, dispatch);
    if (resp.isSuccess || resp.data.data) {
      const eyfsData = resp.data.data;
      if (eyfsData.length > 0) {
        dispatch(SET_EYFS(eyfsData));
      } else {
        dispatch(SET_EYFS([]));
      }
    }
  },
});
