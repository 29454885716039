import api from "./api"; // Use the Axios instance with interceptor

export default class HelpCenterApi {
  static async getHelpCenter() {
    try {
      const response = await api.get("/helpCenter/all");
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createHelpCenter(data: { title: string; subTitle: string, content: string}) {
    try {
      const response = await api.post("/helpCenter/", data);
      console.log(response.data); // Log the fetched data

      return response.data;

    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateHelpCenter(id: string, data: { title: string; subTitle: string, content: string }) {
    try {
      const response = await api.patch(`/helpCenter/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async deleteHelpCenter(id: string) {
    try {
      const response = await api.delete(`/helpCenter/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
