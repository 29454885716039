import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../../Components/Forms/Common/DropItem";
import { Btn } from "../../../../AbstractElements";
import { LooksGood } from "../../../../Utils/Constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormDataTypes } from "../../../../Types/Forms.type";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

export default function AddActivePlans() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  const [validate, setValidate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataTypes>();
  const onSubmit = () => {
    setValidate(true);
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Active Plans Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/plans/active-plans`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Session Detail
                      </span>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Session Type"}
                            id={"session Type"}
                            options={[
                              {
                                value: "session",
                                label: "Session",
                              },
                              {
                                value: "extra session",
                                label: "Extra Session",
                              },
                            ]}
                          />
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Session Title"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            } ${
                              validate && !errors.firstName ? "is-valid" : ""
                            }`}
                            placeholder={`Eg: "AM-Session", "PM-Session" `}
                            {...register("firstName", {
                              required: "Please enter your valid name",
                            })}
                          />
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <DropItem
                            tip={true}
                            validate={true}
                            colClass="position-relative tooltip-form"
                            className={`${
                              validate && !selectedOption ? "is-invalid" : ""
                            } ${validate && selectedOption ? "is-valid" : ""}`}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            title={"Pricing"}
                            id={"pricing"}
                            options={[
                              {
                                value: "diamond-plan",
                                label: "Diamond Plan - $60/yearly (Active)",
                              },
                              {
                                value: "demo-plan",
                                label: "Demo Plan - $10/yearly (Active)",
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Session Pricing
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"Brands"} <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex">
                            <div className={`icon-state`}>
                              <Label className="switch mb-0">
                                <Input type="checkbox" defaultChecked />
                                <span
                                  className={`switch-state bg-primary`}
                                ></span>
                              </Label>
                            </div>
                            <Label className="col-form-label ms-3">
                              {`Demo Testing`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Monthly Price"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Adhoc Pricing"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"Brands"} <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex">
                            <div className={`icon-state`}>
                              <Label className="switch mb-0">
                                <Input type="checkbox" defaultChecked />
                                <span
                                  className={`switch-state bg-primary`}
                                ></span>
                              </Label>
                            </div>
                            <Label className="col-form-label ms-3">
                              {`Demo Testing`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Monthly Price"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            {"Adhoc Pricing"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              } ${
                                validate && !errors.firstName ? "is-valid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("firstName", {
                                required: "Please enter your valid name",
                              })}
                            />
                          </InputGroup>

                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Availability
                      </span>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"Working Day"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex">
                            <div className={`icon-state`}>
                              <Label className="switch mb-0">
                                <Input type="checkbox" defaultChecked />
                                <span
                                  className={`switch-state bg-primary`}
                                ></span>
                              </Label>
                            </div>
                            <Label className="col-form-label ms-3">
                              {`Monday`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Start Time"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative">
                          <Label>
                            {"End Time"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={4}>
                          <Label>
                            {"Working Day"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex">
                            <div className={`icon-state`}>
                              <Label className="switch mb-0">
                                <Input type="checkbox" defaultChecked />
                                <span
                                  className={`switch-state bg-primary`}
                                ></span>
                              </Label>
                            </div>
                            <Label className="col-form-label ms-3">
                              {`Tuesday`}
                            </Label>
                          </div>
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Start Time"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative">
                          <Label>
                            {"End Time"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </InputGroup>
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
