import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditIForm, IForm } from "../../../Pages/SuperAdmin/Plans/Helper/types";
import { handleErrors } from "../../../Utils";
import { createEyfs, updateEyfs } from "./meditator";

export interface EyfsDataItem {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  content: string;
  ageRange: string;
  color: string;
}

interface IInitialState {
  eyfs: EyfsDataItem[] | [];
  isLoading: boolean;
  error: any;
  success: any;
}

const initialState: IInitialState = {
  eyfs: [],
  isLoading: false,
  error: {},
  success: {},
};

const EyfsSlice = createSlice({
  name: "eyfs",
  initialState,
  reducers: {
    SET_EYFS: (state: IInitialState, action) => {
      state.eyfs = action.payload;
    },
    FETCH_EYFS: (state, action) => {
      // * To get the user data
    },
    SET_MESSAGE: (state, action) => {
      state.error = action.payload.error;
      state.success = action.payload.success;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addForm.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateForm.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const addForm = createAsyncThunk(
  "addForm",
  async (formData: IForm, thunkApi) => {
    const response = await createEyfs(formData, thunkApi);
    console.log("response: ", response);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      thunkApi.dispatch(
        SET_MESSAGE({
          success: {
            status: true,
            message: message ? message : "Data added successfully",
          },
          error: {},
        })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while adding plan.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const updateForm = createAsyncThunk(
  "updateForm",
  async (formData: EditIForm, thunkApi) => {
    const response = await updateEyfs(formData, thunkApi);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      thunkApi.dispatch(
        SET_MESSAGE({
          success: {
            status: true,
            message: message ? message : "Data updated successfully",
          },
          error: {},
        })
      );

      thunkApi.dispatch(FETCH_EYFS(formData.filterObj));
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while updating plan.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const { FETCH_EYFS, SET_EYFS, SET_MESSAGE } = EyfsSlice.actions;
export default EyfsSlice.reducer;
