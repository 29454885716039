import { Container, Row } from "reactstrap";
import MyProfile from "./Components/MyProfile";
import EditProfile from "./Components/EditProfile";

export default function AdminProfile(props: any) {
  return (
    <>
      <Container fluid className="pt-3">
        <div className="edit-profile">
          <Row>
            {/* <MyProfile user={props.user} /> */}
            <EditProfile user={props.user} setUser={props.setUser} />
          </Row>
        </div>
      </Container>
    </>
  );
}
