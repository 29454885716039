import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            us: {
                translations: {
                    "General": "General",
                    "Dashboards": "Dashboards",
                    "Default": "Default",
                    "Project": "Project",
                    "Ecommerce": "Ecommerce",
                    "Education": "Education",
                    "Widgets": "Widgets",
                    "Chart": "Chart",
                    "Applications": "Applications",
                    "Project List": "Project List",
                    "Create New": "Create New",
                    "File Manager": "File Manager",
                    "Add Product": "Add Product",
                    "Product": "Product",
                    "Product Page": "Product Page",
                    "Product List": "Product List",
                    "Payment Details": "Payment Details",
                    "Order History": "Order History",
                    "Invoice": "Invoice",
                    "Invoice-1": "Invoice-1",
                    "Invoice-2": "Invoice-2",
                    "Invoice-3": "Invoice-3",
                    "Invoice-4": "Invoice-4",
                    "Invoice-5": "Invoice-5",
                    "Invoice-6": "Invoice-6",
                    "Cart": "Cart",
                    "Wishlist": "Wishlist",
                    "Checkout": "Checkout",
                    "Pricing": "Pricing",
                    "Chat": "Chat",
                    "Users": "Users",
                    "Letter Box": "Letter Box",
                    "Private Chat": "Private Chat",
                    "Group Chat": "Group Chat",
                    "User Profile": "User Profile",
                    "User Edit": "User Edit",
                    "User Cards": "User Cards",
                    "Bookmarks": "Bookmarks",
                    "Contacts": "Contacts",
                    "Tasks": "Tasks",
                    "Calendar": "Calendar",
                    "Social App": "Social App",
                    "To-Do": "To-Do",
                    "Search Result": "Search Result",
                    "Form Validation": "Form Validation",
                    "Base Inputs": "Base Inputs",
                    "Checkbox & Radio": "Checkbox & Radio",
                    "Input Groups": "Input Groups",
                    "Input Mask": "Input Mask",
                    "Mega Options": "Mega Option",
                    "Datepicker": "Datepicker",
                    "Touchspin": "Touchspin",
                    "Switch": "Switch",
                    "Forms": "Forms",
                    "Form Controls": "Form Controls",
                    "Forms & Table": "Forms & Table",
                    "Typeahead": "Typeahead",
                    "Clipboard": "Clipboard",
                    "Form Widgets": "Form Widgets",
                    "Form Wizard 1": "Form Wizard 1",
                    "Form Wizard 2": "Form Wizard 2",
                    "Two Factor": "Two Factor",
                    "Form Layout": "Form Layout",
                    "Tables": "Tables",
                    "Reactstrap Tables": "Reactstrap Tables",
                    "Basic Tables": "Basic Tables",
                    "Table Components": "Table Components",
                    "Data Tables": "Data Tables",
                    "Basic Init": "Basic Init",
                    "Advance Init": "Advance Init",
                    "API": "API",
                    "Data Sources": "Data Sources",
                    "Components": "Components",
                    "Typography": "Typography",
                    "Avatars": "Avatars",
                    "Helper Classes": "Helper Classes",
                    "Grid": "Grid",
                    "Tag & Pills": "Tag & Pills",
                    "Progress": "Progress",
                    "Modal": "Modal",
                    "Alert": "Alert",
                    "Popover": "Popover",
                    "Tooltip": "Tooltip",
                    "Dropdown": "Dropdown",
                    "Accordion": "Accordion",
                    "Tabs": "Tabs",
                    "Lists": "Lists",
                    "Bonus Ui": "Bonus Ui",
                    "Scrollable": "Scrollable",
                    "Tree View": "Tree View",
                    "Toasts": "Toasts",
                    "Rating": "Rating",
                    "Dropzone": "Dropzone",
                    "Tour": "Tour",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Owl Carousel",
                    "Ribbons": "Ribbons",
                    "Pagination": "Pagination",
                    "Breadcrumb": "Breadcrumb",
                    "Range Slider": "Range Slider",
                    "Image Cropper": "Image Cropper",
                    "Basic Card": "Basic Card",
                    "Creative Card": "Creative Card",
                    "Timeline": "Timeline",
                    "Icons": "Icons",
                    "Flag Icon": "Flag Icon",
                    "Font Awesome Icon": "Font Awesome Icon",
                    "Ico Icon": "Ico Icon",
                    "Themify Icon": "Themify Icon",
                    "Feather Icon": "Feather Icon",
                    "Weather Icon": "Weather Icon",
                    "Buttons": "Buttons",
                    "Charts": "Charts",
                    "Apex Chart": "Apex Chart",
                    "Google Chart": "Google Chart",
                    "Chartjs Chart": "Chartjs Chart",
                    "Pages": "Pages",
                    "Sample Page": "Sample Page",
                    "Others": "Others",
                    "Error Pages": "Error Pages",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Login Simple": "Login Simple",
                    "Visual Login": "Visual Login",
                    "Visual Login 2": "Visual Login 2",
                    "Validate Login": "Validate Login",
                    "Tooltip Login": "Tooltip Login",
                    "Alert Login": "Alert Login",
                    "Register": "Register",
                    "Register Image": "Register Image",
                    "Visual Reg 2": "Register With Bg Two",
                    "Reg Wizard": "Reg Wizard",
                    "Unlock User": "Unlock User",
                    "Pwd Forget": "Pwd Forget",
                    "Pwd Reset": "Pwd Reset",
                    "Maintenance": "Maintenance",
                    "Coming Simple": "Coming Simple",
                    "Coming Bg Vid": "Coming Bg Vid",
                    "Coming Bg Img": "Coming Bg Img",
                    "Miscellaneous": "Miscellaneous",
                    "Gallery": "Gallery",
                    "Gallery Grid": "Gallery Grid",
                    "Gallery Grid Desc": "Gallery Grid Desc",
                    "Masonry Gallery": "Masonry Gallery",
                    "Masonry With Desc": "Masonry With Desc",
                    "Hover Effects": "Hover Effects",
                    "Blog": "Blog",
                    "Blog Details": "Blog Details",
                    "Blog Single": "Blog Single",
                    "Add Post": "Add Post",
                    "FAQ": "FAQ",
                    "Job Search": "Job Search",
                    "Cards View": "Cards View",
                    "List View": "List View",
                    "Job Detail": "Job Detail",
                    "Apply": "Apply",
                    "Learning": "Learning",
                    "Learning List": "Learning List",
                    "Detailed Course": "Detailed Course",
                    "Map": "Map",
                    "Google Maps": "Google Maps",
                    "Leaflet Maps": "Leaflet Maps",
                    "Editors": "Editors",
                    "Quill Editor": "Quill Editor",
                    "ACE Editor": "ACE Editor",
                    "Knowledgebase": "Knowledgebase",
                    "Support Ticket": "Support Ticket",
                    "Authentication": "Authentication",
                    "Login With Bg Image": "Login With Bg Image",
                    "Coming Soon": "Coming Soon",
                    "Pinned": "Pinned",
                    "Ui Kits": "Ui Kits"
                },
            },
            es: {
                translations: {
                    "General": "General",
                    "Dashboards": "Paneles",
                    "Default": "Predeterminado",
                    "Project": "Proyecto",
                    "Ecommerce": "Comercio Electrónico",
                    "Education": "Educación",
                    "Widgets": "Widgets",
                    "Chart": "Gráfico",
                    "Applications": "Aplicaciones",
                    "Project List": "Lista de Proyectos",
                    "Create New": "Crear Nuevo",
                    "File Manager": "Gestor de Archivos",
                    "Add Product": "Agregar Producto",
                    "Product": "Producto",
                    "Product Page": "Página de Producto",
                    "Product List": "Lista de Productos",
                    "Payment Details": "Detalles de Pago",
                    "Order History": "Historial de Pedidos",
                    "Invoice": "Factura",
                    "Invoice-1": "Factura-1",
                    "Invoice-2": "Factura-2",
                    "Invoice-3": "Factura-3",
                    "Invoice-4": "Factura-4",
                    "Invoice-5": "Factura-5",
                    "Invoice-6": "Factura-6",
                    "Cart": "Carrito",
                    "Wishlist": "Lista de Deseos",
                    "Checkout": "Pagar",
                    "Pricing": "Precios",
                    "Chat": "Chat",
                    "Users": "Usuarios",
                    "Letter Box": "Buzón de Correo",
                    "Private Chat": "Chat Privado",
                    "Group Chat": "Chat de Grupo",
                    "User Profile": "Perfil de Usuario",
                    "User Edit": "Editar Usuario",
                    "User Cards": "Tarjetas de Usuario",
                    "Bookmarks": "Marcadores",
                    "Contacts": "Contactos",
                    "Tasks": "Tarea",
                    "Calendar": "Calendario",
                    "Social App": "Aplicación Social",
                    "To-Do": "Tareas Pendientes",
                    "Search Result": "Resultado de Búsqueda",
                    "Form Validation": "Validación de Formulario",
                    "Base Inputs": "Entradas Básicas",
                    "Checkbox & Radio": "Casillas de Verificación y Radio",
                    "Input Groups": "Grupos de Entrada",
                    "Input Mask": "Máscara de Entrada",
                    "Mega Options": "Opción Mega",
                    "Datepicker": "Selector de Fecha",
                    "Touchspin": "Selector Numérico",
                    "Switch": "Interruptor",
                    "Forms": "Formularios",
                    "Form Controls": "Controles de Formulario",
                    "Forms & Table": "Formularios y Tabla",
                    "Typeahead": "Búsqueda Predictiva",
                    "Clipboard": "Portapapeles",
                    "Form Widgets": "Widget de Formulario",
                    "Form Wizard 1": "Asistente de Formulario 1",
                    "Form Wizard 2": "Asistente de Formulario 2",
                    "Two Factor": "Doble Factor",
                    "Form Layout": "Diseño de Formulario",
                    "Tables": "Tabla",
                    "Reactstrap Tables": "Tablas Reactstrap",
                    "Basic Tables": "Tablas Básicas",
                    "Table Components": "Componentes de Tabla",
                    "Data Tables": "Tablas de Datos",
                    "Basic Init": "Inicio Básico",
                    "Advance Init": "Inicio Avanzado",
                    "API": "API",
                    "Data Source": "Fuente de Datos",
                    "Components": "Componentes",
                    "Typography": "Tipografía",
                    "Avatars": "Avatares",
                    "Helper Classes": "Clases de Ayuda",
                    "Grid": "Cuadrícula",
                    "Tag & Pills": "Etiquetas y Píldoras",
                    "Progress": "Progreso",
                    "Modal": "Modal",
                    "Alert": "Alerta",
                    "Popover": "Popover",
                    "Tooltip": "Información sobre Herramientas",
                    "Dropdown": "Menú Desplegable",
                    "Accordion": "Acordeón",
                    "Tabs": "Pestañas",
                    "Lists": "Listas",
                    "Bonus-Ui": "UI Adicional",
                    "Scrollable": "Desplazable",
                    "Tree View": "Vista de Árbol",
                    "Toasts": "Notificaciones",
                    "Rating": "Valoración",
                    "Dropzone": "Zona de Descarga",
                    "Tour": "Recorrido",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Carrusel Owl",
                    "Ribbons": "Cintas",
                    "Pagination": "Paginación",
                    "Breadcrumb": "Migas de Pan",
                    "Range Slider": "Control Deslizante de Rango",
                    "Image Cropper": "Recortador de Imagen",
                    "Basic Card": "Tarjeta Básica",
                    "Creative Card": "Tarjeta Creativa",
                    "Timeline": "Línea de Tiempo",
                    "Icons": "Iconos",
                    "Flag Icon": "Icono de Bandera",
                    "Fontawesome Icon": "Icono Font Awesome",
                    "Ico Icon": "Icono Ico",
                    "Themify Icon": "Icono Themify",
                    "Feather Icon": "Icono Feather",
                    "Weather Icon": "Icono del Tiempo",
                    "Buttons": "Botones",
                    "Charts": "Gráficos",
                    "Apex Chart": "Gráfico Apex",
                    "Google Chart": "Gráfico de Google",
                    "Chartjs Chart": "Gráfico de Chartjs",
                    "Pages": "Páginas",
                    "Sample Page": "Página de Ejemplo",
                    "Others": "Otros",
                    "Error Pages": "Páginas de Error",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Login Simple": "Inicio de Sesión Simple",
                    "Login with Bg Image": "Inicio de Sesión con Imagen de Fondo",
                    "Login With Image Two": "Inicio de Sesión con Dos Imágenes",
                    "Login With Validation": "Inicio de Sesión con Validación",
                    "Login With Tooltip": "Inicio de Sesión con Información Emergente",
                    "Login With Sweetalert": "Inicio de Sesión con SweetAlert",
                    "Register Simple": "Registro Simple",
                    "Register With Bg Image": "Registro con Imagen de Fondo",
                    "Register With Bg Two": "Registro con Dos Imágenes",
                    "Register Wizard": "Asistente de Registro",
                    "Unlock User": "Desbloquear Usuario",
                    "Forget Password": "Olvidé mi Contraseña",
                    "Reset Password": "Restablecer Contraseña",
                    "Maintenance": "Mantenimiento",
                    "Coming Simple": "Próximamente Simple",
                    "Coming With Bg Video": "Próximamente con Video de Fondo",
                    "Coming With Bg Image": "Próximamente con Imagen de Fondo",
                    "Miscellaneous": "Misceláneo",
                    "Gallery": "Galería",
                    "Gallery Grid": "Galería en Cuadrícula",
                    "Gallery Grid Desc": "Descripción de la Galería en Cuadrícula",
                    "Masonry Gallery": "Galería de Albañilería",
                    "Masonry With Desc": "Albañilería con Descripción",
                    "Hover Effect": "Efecto de Desplazamiento",
                    "Blog": "Blog",
                    "Blog Details": "Detalles del Blog",
                    "Blog Single": "Entrada de Blog Individual",
                    "Add Post": "Agregar Entrada",
                    "FAQ": "Preguntas Frecuentes",
                    "Job Search": "Búsqueda de Trabajo",
                    "Cards View": "Vista de Tarjetas",
                    "List View": "Vista de Lista",
                    "Job Detail": "Detalle del Trabajo",
                    "Apply": "Solicitar",
                    "Learning": "Aprendizaje",
                    "Learning List": "Lista de Aprendizaje",
                    "Detailed Course": "Curso Detallado",
                    "Map": "Mapa",
                    "Google Map": "Mapa de Google",
                    "Leaflet Map": "Mapa de Leaflet",
                    "Editors": "Editors",
                    "Quill Editor": "Editor Quill",
                    "ACE Editor": "Editor ACE",
                    "Knowledgebase": "Base de Conocimientos",
                    "Ui-Kits": "Kits de Interfaz de Usuario.",
                    "SupportTicket": "Ticket de Soporte",
                    "Authentication": "Autenticación",
                    "Login With Bg Image": "Inicio de sesión con imagen de fondo",
                    "Pinned": "Fijado",
                    "Coming Soon": "Próximamente"
                },
            },
            fr: {
                translations: {
                    "General": "Général",
                    "Dashboards": "Tableaux de bord",
                    "Default": "Défaut",
                    "Project": "Projet",
                    "Ecommerce": "Commerce électronique",
                    "Education": "Éducation",
                    "Widgets": "Widgets",
                    "Chart": "Graphique",
                    "Applications": "Applications",
                    "Project List": "Liste de projets",
                    "Create New": "Créer un nouveau",
                    "File Manager": "Gestionnaire de fichiers",
                    "Add Product": "Ajouter un produit",
                    "Product": "Produit",
                    "Product Page": "Page de produit",
                    "Product List": "Liste de produits",
                    "Payment Details": "Détails de paiement",
                    "Order History": "Historique des commandes",
                    "Invoice": "Facture",
                    "Invoice-1": "Facture-1",
                    "Invoice-2": "Facture-2",
                    "Invoice-3": "Facture-3",
                    "Invoice-4": "Facture-4",
                    "Invoice-5": "Facture-5",
                    "Invoice-6": "Facture-6",
                    "Cart": "Panier",
                    "Wishlist": "Liste de souhaits",
                    "Checkout": "Paiement",
                    "Pricing": "Tarification",
                    "Chat": "Discussion",
                    "Users": "Utilisateurs",
                    "Letter Box": "Boîte aux lettres",
                    "Private Chat": "Discussion privée",
                    "Group Chat": "Discussion de groupe",
                    "User Profile": "Profil de l'utilisateur",
                    "User Edit": "Modifier l'utilisateur",
                    "User Cards": "Cartes d'utilisateur",
                    "Bookmarks": "Signets",
                    "Contacts": "Contacts",
                    "Tasks": "Tâche",
                    "Calendar": "Calendrier",
                    "Social App": "Application sociale",
                    "To-Do": "À faire",
                    "Search Result": "Résultat de la recherche",
                    "Form Validation": "Validation de formulaire",
                    "Base Inputs": "Entrées de base",
                    "Checkbox & Radio": "Cases à cocher et boutons radio",
                    "Input Groups": "Groupes d'entrée",
                    "Input Mask": "Masque de saisie",
                    "Mega Options": "Option méga",
                    "Datepicker": "Sélecteur de date",
                    "Touchspin": "Touchspin",
                    "Switch": "Interrupteur",
                    "Forms": "Formulaires",
                    "Form Controls": "Contrôles de formulaire",
                    "Forms & Table": "Formulaires et table",
                    "Typeahead": "Suggestion de texte",
                    "Clipboard": "Presse-papiers",
                    "Form Widgets": "Widget de formulaire",
                    "Form Wizard 1": "Assistant de formulaire 1",
                    "Form Wizard 2": "Assistant de formulaire 2",
                    "Two Factor": "Double authentification",
                    "Form Layout": "Mise en page du formulaire",
                    "Tables": "Tableau",
                    "Reactstrap Tables": "Tableaux Reactstrap",
                    "Basic Tables": "Tables de base",
                    "Table Components": "Composants de tableau",
                    "Data Tables": "Tableaux de données",
                    "Basic Init": "Initialisation de base",
                    "Advance Init": "Initialisation avancée",
                    "API": "API",
                    "Data Source": "Source de données",
                    "Components": "Composants",
                    "Typography": "Typographie",
                    "Avatars": "Avatars",
                    "Helper Classes": "Classes d'aide",
                    "Grid": "Grille",
                    "Tag & Pills": "Étiquettes et pastilles",
                    "Progress": "Progression",
                    "Modal": "Fenêtre modale",
                    "Alert": "Alerte",
                    "Popover": "Infobulle",
                    "Tooltip": "Info-bulle",
                    "Dropdown": "Menu déroulant",
                    "Accordion": "Accordéon",
                    "Tabs": "Onglets",
                    "Lists": "Listes",
                    "Bonus-Ui": "Bonus-UI",
                    "Scrollable": "Déroulant",
                    "Tree View": "Vue arborescente",
                    "Toasts": "Notifications",
                    "Rating": "Évaluation",
                    "Dropzone": "Zone de dépôt",
                    "Tour": "Visite guidée",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Carrousel Owl",
                    "Ribbons": "Rubans",
                    "Pagination": "Pagination",
                    "Breadcrumb": "Fil d'Ariane",
                    "Range Slider": "Curseur de plage",
                    "Image Cropper": "Recadrage d'image",
                    "Basic Card": "Carte de base",
                    "Creative Card": "Carte créative",
                    "Timeline": "Chronologie",
                    "Icons": "Icônes",
                    "Flag Icon": "Icône de drapeau",
                    "Fontawesome Icon": "Icône Fontawesome",
                    "Ico Icon": "Icône Ico",
                    "Themify Icon": "Icône Themify",
                    "Feather Icon": "Icône Feather",
                    "Weather Icon": "Icône météo",
                    "Buttons": "Boutons",
                    "Charts": "Graphiques",
                    "Apex Chart": "Graphique Apex",
                    "Google Chart": "Graphique Google",
                    "Chartjs Chart": "Graphique Chartjs",
                    "Pages": "Pages",
                    "Sample Page": "Page d'exemple",
                    "Others": "Autres",
                    "Error Pages": "Pages d'erreur",
                    "Error 400": "Erreur 400",
                    "Error 401": "Erreur 401",
                    "Error 403": "Erreur 403",
                    "Error 404": "Erreur 404",
                    "Error 500": "Erreur 500",
                    "Error 503": "Erreur 503",
                    "Login Simple": "Connexion simple",
                    "Login with Bg Image": "Connexion avec image de fond",
                    "Login With Image Two": "Connexion avec deux images",
                    "Login With Validation": "Connexion avec validation",
                    "Login With Tooltip": "Connexion avec info-bulle",
                    "Login With Sweetalert": "Connexion avec SweetAlert",
                    "Register Simple": "Inscription simple",
                    "Register With Bg Image": "Inscription avec image de fond",
                    "Register With Bg Two": "Inscription avec deux images de fond",
                    "Register Wizard": "Assistant d'inscription",
                    "Unlock User": "Déverrouiller l'utilisateur",
                    "Forget Password": "Mot de passe oublié",
                    "Reset Password": "Réinitialiser le mot de passe",
                    "Maintenance": "Maintenance",
                    "Coming Simple": "Bientôt disponible (version simple)",
                    "Coming With Bg Video": "Bientôt disponible avec vidéo de fond",
                    "Coming With Bg Image": "Bientôt disponible avec image de fond",
                    "Miscellaneous": "Divers",
                    "Gallery": "Galerie",
                    "Gallery Grid": "Grilles de galerie",
                    "Gallery Grid Desc": "Description de la grille de galerie",
                    "Masonry Gallery": "Galerie en maçonnerie",
                    "Masonry With Desc": "Maçonnerie avec description",
                    "Hover Effect": "Effet de survol",
                    "Blog": "Blog",
                    "Blog Details": "Détails du blog",
                    "Blog Single": "Article de blog unique",
                    "Add Post": "Ajouter un article",
                    "FAQ": "FAQ",
                    "Job Search": "Recherche d'emploi",
                    "Cards View": "Vue en cartes",
                    "List View": "Vue en liste",
                    "Job Detail": "Détails de l'emploi",
                    "Apply": "Postuler",
                    "Learning": "Apprentissage",
                    "Learning List": "Liste d'apprentissage",
                    "Detailed Course": "Cours détaillé",
                    "Map": "Carte",
                    "Google Map": "Carte Google",
                    "Leaflet Map": "Carte Leaflet",
                    "Editors": "Éditeur",
                    "Quill Editor": "Éditeur Quill",
                    "ACE Editor": "Éditeur ACE",
                    "Knowledgebase": "Base de connaissances",
                    "Ui-Kits": "Kits d'interface utilisateur.",
                    "SupportTicket": "Ticket de support",
                    "Authentication": "Authentification",
                    "Login With Bg Image": "Connexion avec Image de Fond",
                    "Pinned": "Épinglé",
                    "Coming Soon": "Prochainement"
                },
            },
        },
        fallbackLng: "us",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;