import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import DropdownWithHeader from "../../../../Utils/CommonComponents/DropdownWithHeader";
import { ActivityTimelineTitle } from "../../../../Utils/Constants";
import { monthlyDropdownList } from "../../../../Data/Dashboard/Default";
import { activityTimelineData } from "../../../../Data/Dashboard/Ecommerce";
import {
  Badges,
  Btn,
  H3,
  Image,
  LI,
  P,
  UL,
} from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Utils";

export default function ActivityTimeline() {
  return (
    <Col xxl={4} xl={5} sm={6} className="box-col-6">
      <Card style={{ height: "480px" }}>
        <CardHeader className={`card-no-border pb-0`}>
          <div className={`header-top`}>
            <H3 className={""}>{`Upcoming Room Moves`}</H3>
            <div className={""}>
              <Btn color="primary" size="s">
                See More
              </Btn>
            </div>
          </div>
        </CardHeader>
        <CardBody className="timeline-card">
          <div className="activity-timeline">
            {activityTimelineData.map((item) => (
              <div className="d-flex align-items-start" key={item.id}>
                <div className="activity-line" />
                <div className={`activity-dot-${item.color}`} />
                <div className="flex-grow-1">
                  <P className="mt-0 todo-font">
                    <span className={`font-${item.color}`}>{item.date}</span>
                    &nbsp;{item.day}
                    {item.id === 3 && (
                      <Badges color="primary" className="ms-2">
                        {"New"}
                      </Badges>
                    )}
                  </P>
                  <span className="f-w-700">{"Title"}</span>
                  <P className="mb-0 activity-text">{"Sub title"}</P>
                </div>
                <i
                  className={`fa-solid fa-circle circle-dot-${item.color} pull-right`}
                />
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
