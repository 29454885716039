import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ILandingGraph {
  count: number;
  month: string;
}
interface IUserGraph {
  count: number;
  date: string;
}
interface IRevenueGraph {
  fee: number;
  month: string;
}
interface ISubsSummary {
  count: number;
  title: string;
}
interface IVisitors {
  id: string | number;
  count: number;
  browser: string;
  type: string;
}

interface IInitialState {
  landingPageGraph: ILandingGraph[] | [];
  landingPageTotal: number;
  visitors: IVisitors[] | [];
  visitorsCount: number;
  revenueTotal: number;
  revenueGraph: IRevenueGraph[] | [];
  usersGraph: IUserGraph[] | [];
  subscribedUsers: number;
  freeUsers: number;
  newUsers: number;
  newUsersPercentage: number;
  pageVisitors: number;
  subsSummary: ISubsSummary[] | [];
  subsSummaryTotal: number;
}

const initialState: IInitialState = {
  landingPageGraph: [],
  visitors: [],
  landingPageTotal: 0,
  visitorsCount: 0,
  revenueTotal: 0,
  revenueGraph: [],
  usersGraph: [],
  subscribedUsers: 0,
  freeUsers: 0,
  newUsers: 0,
  newUsersPercentage: 0,
  pageVisitors: 0,
  subsSummary: [],
  subsSummaryTotal: 0,
};

const ReportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    FETCH_VISITORS: (state, action) => {},
    FETCH_VISITORS_GRAPH: (state, action) => {},
    FETCH_REVENUE_GRAPH: (state, action) => {},
    FETCH_USERS_GRAPH: (state, action) => {},
    FETCH_SUB_SUMMARY: (state, action) => {},
    FETCH_DASHBOARD_CARD: (state, action) => {},

    SET_VISITORS: (state, action) => {
      state.visitors = action.payload.visitors;
      state.visitorsCount = action.payload.count;
    },
    SET_VISITORS_GRAPH: (state, action) => {
      state.landingPageGraph = action.payload.data;
      state.landingPageTotal = action.payload.totalVisitors;
    },
    SET_REVENUE_GRAPH: (state, action) => {
      state.revenueGraph = action.payload.data;
      state.revenueTotal = action.payload.totalRevenue;
    },
    SET_USERS_GRAPH: (state, action) => {
      state.usersGraph = action.payload.data.graph;
    },
    SET_SUB_SUMMARY: (state, action) => {
      state.subsSummary = action.payload.data;
      state.subsSummaryTotal = action.payload.total;
    },
    SET_DASHBOARD_CARD: (state, action) => {
      state.subscribedUsers = action.payload.data.subscribedUsers;
      state.freeUsers = action.payload.data.freeUsers;
      state.pageVisitors = action.payload.data.pageVisitors;
      state.newUsers = action.payload.data.newUsers[0].new_users_count || 0;
      state.newUsersPercentage =
        action.payload.data.newUsers[0].new_users_percentage || 0;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  FETCH_VISITORS,
  FETCH_VISITORS_GRAPH,
  FETCH_REVENUE_GRAPH,
  FETCH_USERS_GRAPH,
  FETCH_SUB_SUMMARY,
  FETCH_DASHBOARD_CARD,
  SET_VISITORS,
  SET_VISITORS_GRAPH,
  SET_REVENUE_GRAPH,
  SET_USERS_GRAPH,
  SET_SUB_SUMMARY,
  SET_DASHBOARD_CARD,
} = ReportSlice.actions;
export default ReportSlice.reducer;
