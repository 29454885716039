import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import DropdownWithHeader from "../../DropdownWithHeader";
import { monthlyDropdownList } from "../../../../Data/Dashboard/Default";
import { ScheduleLessonTitle } from "../../../Constants";
import { ScheduleLessonProp } from "../../../../Types/Dashboard.type";

export default function ScheduleLesson({ colClass }: ScheduleLessonProp) {
  const [startDate, setStartDate] = useState(new Date());
  const handleChange = (date: Date) => {
    setStartDate(date);
  };
  return (
    <Col sm={6} className={colClass}>
      <Card className="overflow-hidden">
        <DropdownWithHeader
          end
          headerClass="card-no-border pb-0"
          heading={`My Calender`}
          dropDownClass="icon-dropdown"
          dropDownIcon
          dropDownList={monthlyDropdownList}
        />
        <CardBody className="p-0">
          <div className="default-datepicker">
            <div className="datepicker-here">
              <ReactDatePicker
                selected={startDate}
                onChange={handleChange}
                inline
                renderDayContents={(day, date) => {
                  // Customize the day cell content here
                  if (day == new Date(startDate).getDate()) {
                    return <div>{day + " 🎉"}</div>;
                  } else if (day == 15) {
                    return <div>{day + " 🎃"}</div>;
                  } else if (day == 20) {
                    return <div>{day + " 🎯"}</div>;
                  } else {
                    return <div>{day}</div>;
                  }
                }}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
