import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import SmallWidget from "./SmallWidget";
import { H4 } from "../../../../AbstractElements";
import { widgetWithChart } from "../../../../Data/Widgets/General";

export default function WidgetCharts() {
  return (
    <Col xxl={12} xl={12} className="box-col-12">
      <Row className="g-sm-3 height-equal-2 widget-charts">
        <SmallWidget />
      </Row>
    </Col>
  );
}
