import { TableColumn } from "react-data-table-component";
import { Badges, LI, UL } from "../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";

// export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [model, setModel] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 237, 236)",
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            padding: "4px 0px",
            color: "rgb(224, 49, 55)",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
      <span
        style={{ padding: "4px 4px", fontWeight: "700", fontSize: "16px" }}
        onClick={() => setModel(!model)}
      >
        <i className="iconly-Arrow-Down-2 icli"></i>
      </span>
      {model && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            zIndex: "1",
            width: "150px",
            height: "90px",
            background: "#000",
          }}
        >
          <h2>Hello</h2>
        </div>
      )}
    </>
  );
};

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns: TableColumn<any>[] = [
  {
    name: <CustomHeader title="Employee Name" />,
    selector: (row) => row.title,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="From" />,
    selector: (row) => row.billing,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="To" />,
    selector: (row) => row.fee,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Total" />,
    selector: (row) => row.maxNumOfChild,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Type" />,
    selector: (row) => row.description,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Attachment" />,
    selector: (row) => row.description,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Status" />,
    selector: (row) => row.badge,
    style: { maxWidth: "50%" },
    cell: (row) => <CustomBadge color={row.color} position={row.badge} />,
    sortable: true,
    center: true,
  },
];
