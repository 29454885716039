import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import RoomOverview from "./RoomOverview";
import RoomCustomStatus from "./RoomCustomStatus";
import RoomMoves from "./RoomMoves";

const Room: React.FC = () => {
  const [activeRoomTab, setActiveRoomTab] = useState("1");

  const toggleRoomTab = (tab: string) => {
    if (activeRoomTab !== tab) setActiveRoomTab(tab);
  };

  return (
    <Container fluid>
      <Row className="pt-3">
        <Col sm={12}>
          <Card>
            {/* <CommonCardHeader
              headClass="pb-0 card-no-border custom-card-header"
              title="Room Settings"
              titleClass="mb-3"
            /> */}
              <CardBody>
              {/* Room Navigation Tabs */}
              <Nav tabs className="mb-4" style={{ marginBottom: "20px" }}>
                  <NavItem>
                  <NavLink
                      className={classnames({
                      active: activeRoomTab === "1", // Apply active class when the tab is selected
                      "bg-primary": activeRoomTab === "1", // Apply primary background color when active
                      "text-white": activeRoomTab === "1", // Apply white text when active
                      "text-dark": activeRoomTab !== "1", // Apply black text when not active
                      })}
                      onClick={() => toggleRoomTab("1")}
                      style={{
                      fontSize: "16px",
                      borderRadius: "5px",
                      transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                      padding: "12px 20px",
                      marginBottom: "5px",
                      }}
                  >
                      Overview
                  </NavLink>
                  </NavItem>
                  <NavItem>
                  <NavLink
                      className={classnames({
                      active: activeRoomTab === "2", // Apply active class when the tab is selected
                      "bg-primary": activeRoomTab === "2", // Apply primary background color when active
                      "text-white": activeRoomTab === "2", // Apply white text when active
                      "text-dark": activeRoomTab !== "2", // Apply black text when not active
                      })}
                      onClick={() => toggleRoomTab("2")}
                      style={{
                      fontSize: "16px",
                      borderRadius: "5px",
                      transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                      padding: "12px 20px",
                      marginBottom: "5px",
                      }}
                  >
                      Custom Status
                  </NavLink>
                  </NavItem>
                  <NavItem>
                  <NavLink
                      className={classnames({
                      active: activeRoomTab === "3", // Apply active class when the tab is selected
                      "bg-primary": activeRoomTab === "3", // Apply primary background color when active
                      "text-white": activeRoomTab === "3", // Apply white text when active
                      "text-dark": activeRoomTab !== "3", // Apply black text when not active
                      })}
                      onClick={() => toggleRoomTab("3")}
                      style={{
                      fontSize: "16px",
                      borderRadius: "5px",
                      transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
                      padding: "12px 20px",
                      marginBottom: "5px",
                      }}
                  >
                      Room Moves
                  </NavLink>
                  </NavItem>
              </Nav>

              {/* Room Tab Content */}
              <TabContent activeTab={activeRoomTab}>
                  <TabPane tabId="1">
                  <RoomOverview />
                  </TabPane>
                  <TabPane tabId="2">
                  <RoomCustomStatus />
                  </TabPane>
                  <TabPane tabId="3">
                  <RoomMoves />
                  </TabPane>
              </TabContent>
              </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Room;
