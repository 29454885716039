import React, { useEffect, useState } from "react";
import HelpCenterApi from "../../../services/HelpCenterApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { LI, UL } from "../../../AbstractElements";

interface HelpCenter {
  id: string;
  title: string;
  subTitle: string;
  content: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListHelpCenter: React.FC = () => {
  const [helpCenters, setHelpCenters] = useState<HelpCenter[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHelpcenters = async () => {
      try {
        const response = await HelpCenterApi.getHelpCenter();
        if (response.success && Array.isArray(response.data)) {
          setHelpCenters(response.data);
        } else {
          throw new Error("Expected an array of Help Centers");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching Help Centers");
      } finally {
        setLoading(false);
      }
    };

    fetchHelpcenters();
  }, []);

  const filteredItems = helpCenters.filter((helpCenter) =>
    helpCenter.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDetailPage = (id: string) => {
    navigate(`/dashboard/help-center/edit/${id}`);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "50%" },
      sortable: true,
      cell: (row) => (
        <div
          style={{ maxWidth: "50%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {row.title}
        </div>
      ),
    },
    {
      name: <CustomHeader title="Sub Title" />,
      selector: (row) => row.subTitle,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Content" />,
      selector: (row) => row.content,
      style: { maxWidth: "50%" },
      sortable: true,
      cell: (row) => (
        <div
          style={{ maxWidth: "50%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          dangerouslySetInnerHTML={{ __html: row.content }}
        />
      ),
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Help Center`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/add`)}
                >
                  <i className="fa fa-plus mr-2"></i> Add Help Center
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[{
                      when: (row) => true,
                      style: {
                        "&:hover": {
                          backgroundColor: "rgba(48, 142, 135, 0.2)",
                          cursor: "pointer",
                        },
                      },
                    }]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListHelpCenter;
