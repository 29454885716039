import { forEach, isArray, isObject } from "lodash";
import store from "../ReduxToolkit/Store";
import { roleWiseMenuList } from "../Data/Layout/SidebarMenuList";
interface AnyObject {
  [key: string]: any;
}

//dynamic image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image: string | undefined) => {
  return images(`./${image}`);
};

//dynamic Number
export function dynamicNumber(totalLength: number) {
  return Array.from({ length: totalLength }, (_, index) => index + 1);
}

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  return `${firstName || ""} ${lastName || ""}`;
};

export const flattenObject = (
  obj: any,
  parent = "",
  res: any = {},
  visited: any = new Set()
) => {
  if (visited.has(obj)) {
    // Avoid circular reference
    res[parent] = "[Circular]";
    return res;
  }

  visited.add(obj); // Add current object to visited

  forEach(obj, (value, key) => {
    const newKey = parent ? `${parent}.${key}` : key;

    if (isObject(value) && !isArray(value) && value !== null) {
      flattenObject(value, newKey, res, visited);
    } else if (isArray(value)) {
      forEach(value, (item, index) => {
        flattenObject(item, `${newKey}[${index}]`, res, visited);
      });
    } else {
      res[newKey] = value;
    }
  });

  visited.delete(obj); // Remove object from visited after processing
  return res;
};

export const handleErrors = (errors: any) => {
  return Object.fromEntries(Object.keys(errors).map((key) => [key, ""]));
};

export function removeKeys(obj: AnyObject, keysToRemove: string[]): AnyObject {
  // Create a new object to store the result
  const result: AnyObject = { ...obj };

  // Remove specified keys from the result object
  keysToRemove.forEach((key) => delete result[key]);

  return result;
}

export const delay = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000); // Convert seconds to milliseconds
  });
};

export const getDataFromArray = (array: any, field: string, id: any) => {
  const filteredData = array.filter((row: any) => row.id === id);
  if (filteredData.length > 0) {
    return filteredData[0][field];
  }
  return "";
};

// Function to convert data to CSV format
export const convertToCSV = (data: any) => {
  const csvRows = [];

  // Get headers (keys of the object)
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(",")); // Push headers row

  // Loop through data and push each row
  for (const row of data) {
    const values = headers.map((header) => row[header]);
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

// Helper function to search recursively through items and children
const findOperationInItems = (
  items: any[],
  operationName: string,
  mainMenuTitle = ""
): any => {
  for (const item of items) {
    if (item.title === operationName) {
      // If found at the top level, set mainMenuTitle to item.title
      return { mainMenuTitle: mainMenuTitle || item.title, matchedItem: item };
    }
    if (item.children) {
      // If children exist, pass the current item title as the main menu
      const foundInChildren: any = findOperationInItems(
        item.children,
        operationName,
        item.title
      );
      if (foundInChildren) return foundInChildren; // Return if found in children
    }
  }
  return null;
};

export const getUserActivityFormattedData = (
  operationName: string,
  description: string
) => {
  let operationId = "";
  const state = store.getState();
  const userID = state.auth.user?.id;
  const userIP = state.auth.userIp;
  const role: any = state.auth.user?.role.toUpperCase() || "";
  const sidebarData: any = roleWiseMenuList.filter((obj) => {
    return Object.keys(obj)[0] === (role ?? "SUPER_ADMIN");
  });

  if (operationName == "Login") {
    operationId = "0";
  }

  if (sidebarData.length > 0) {
    const roleItems = sidebarData[0][role ?? "SUPER_ADMIN"].items;
    const result = findOperationInItems(roleItems, operationName);

    if (result && result.matchedItem) {
      const { mainMenuTitle, matchedItem } = result;
      operationId = `${matchedItem.id}`;

      if (operationName !== "Login" && operationName !== "Logout") {
        // * Check if the item has children (i.e., sub-menu items)
        if (matchedItem.children) {
          // * Include both main menu and sub-menu in the description
          description = `Role: ${role}, Main Menu: ${mainMenuTitle}, Sub Menu: ${matchedItem.title}, Message: ${description}`;
        } else {
          // * Only include the main menu in the description for top-level items without children
          description = `Role: ${role}, Main Menu: ${mainMenuTitle}, Message: ${description}`;
        }
      }
    }
  }

  return {
    operationId: operationId,
    operationName: operationName,
    description: description,
    createdBy: userID,
    createdFrom: userIP,
  };
};

export const getLoggedInUserId = () => {
  const state = store.getState();
  return state.auth.user?.id;
};

export const fetchImage = async (url: any) => {
  let imageObjectUrl = "";
  try {
    // Fetch the image as a Blob
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }

    // Convert the response to a Blob
    const imageBlob = await response.blob();

    // Create a URL for the image Blob
    imageObjectUrl = URL.createObjectURL(imageBlob);
    return imageObjectUrl;
    // Set the image URL to displa
  } catch (error) {
    return imageObjectUrl;
    console.error("Error fetching image:", error);
  }
};
