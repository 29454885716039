import { Outlet, useNavigate } from "react-router-dom";

export default function PrivateRoutes(props: any) {
  // const login = props?.auth?.user || false;
  // const navigate = useNavigate();
  // if (login && !!props?.checkLogin) {
  //   navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
  // }
  return <Outlet />;
}
