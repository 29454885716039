import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { Btn } from "../../../../AbstractElements";
import { connectComponent } from "../connector";
import {
  convertToCSV,
  flattenObject,
  getDataFromArray,
} from "../../../../Utils";
import moment from "moment";
import Swal from "sweetalert2";

interface initialFilter {
  pageNo: number;
  pageSize: number;
}

function VisitorsReport(props: any) {
  const navigate = useNavigate();

  const initialFilter: initialFilter = {
    pageNo: 1,
    pageSize: 10000,
  };
  const [filterObj, setFilterObj] = useState(initialFilter);
  const [saveData, setSaveData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  useEffect(() => {
    props.fetchVisitors(filterObj);
  }, []);

  const handleDownloadCSV = () => {
    const formattedData = saveData.map((row: any) => {
      return {
        browser: row.browser,
        type: row.type,
        visits: row.count,
      };
    });

    const jsonToCsvData = convertToCSV(formattedData);
    const blob = new Blob([jsonToCsvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `VisitorsReport_${new Date().getTime()}.csv`);
    a.click();
  };

  useEffect(() => {
    let data: any = props.visitors.map((row: any) => {
      return flattenObject(row);
    });

    setSaveData(data);
  }, [props.visitors]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/users/edit/${id}`);
  };

  const handleStatusChange = (row: any) => {
    const selectedValue = row?.isActive === true ? "active" : "inactive";
    const id = row.id;
    Swal.fire({
      title: "Update Status",
      input: "select",
      inputOptions: {
        active: "ACTIVE",
        inactive: "IN ACTIVE",
      },
      inputPlaceholder: "Select a status",
      inputValue: selectedValue,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.updateForm({
          id: id,
          body: {
            isActive: result.value === "active" ? true : false,
          },
          filterObj,
        });
      }
    });
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View Visitors Report`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => handleDownloadCSV()}
                >
                  Download Report
                </Button>
              </div>
              <CardBody>
                {/* <Card color="light" inverse={true} className="custom-card">
                  <CardBody>
                    <span className="h4 text-bold">Filter : </span>
                    <div
                      className="d-flex align-items-center mt-3"
                      style={{ gap: "1.5rem" }}
                    >
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={new Date()}
                        type="datepicker"
                      />
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "status",
                          options: [
                            {
                              value: "active",
                              label: "Active",
                            },
                            {
                              value: "inactive",
                              label: "Inactive",
                            },
                          ],
                          title: "Status",
                        }}
                      />
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "plan",
                          options: [
                            {
                              value: "Diamond Plan",
                              label: "Diamond Plan",
                            },
                            {
                              value: "Demo Plan",
                              label: "Demo Plan",
                            },
                          ],
                          title: "Plans",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center mt-4"
                      style={{ gap: "1rem" }}
                    >
                      <Btn color="dark">Apply Filter</Btn>
                      <Btn color="primary" outline={true}>
                        Clear
                      </Btn>
                    </div>
                  </CardBody>
                </Card> */}

                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />

                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true, // This applies to all rows
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [props.isLoading, props.success, saveData])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(VisitorsReport);
