import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabPane,
} from "reactstrap";
import { Btn, LI, P, UL } from "../../../../AbstractElements";
import UserApi from "../../../../services/UserApi";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

function Notification(props: any) {
  return (
    <TabPane tabId={`3`} key={2}>
      <Card className="mt-2">
        {/* <hr /> */}
        <NotificationEnableForm user={props.user} setUser={props.setUser} />
        <hr />
        <LimitNotificationForm user={props.user} setUser={props.setUser} />
        <hr />
        <OutOfOfficeForm user={props.user} setUser={props.setUser} />

      </Card>
    </TabPane>
  );
}

function OutOfOfficeForm(props: any) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  console.log("errors: ", errors);
  // * outOfOffice false == No, true == Yes

  const initialState = {
    outOfOffice: false,
    emailFrom: "",
    emailTo: "",
    message: "",
    id: "",
  };

  useEffect(() => {
    if (props.user) {
      const formData = {
        outOfOffice: false,
        emailForm: "",
        emailTo: "",
        message: "",
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    }
  }, [props]);

  const [form, setForm] = useState<any>(initialState);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    console.log("Form is Submitted successfully", form);

    let formData = JSON.parse(JSON.stringify(form));
    formData.isOutOfOfficeForm = true;
    try {
      const data = await UserApi.updateUserProfile(formData);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            if (props.user) {
              const formData = {
                outOfOffice: false,
                emailForm: "",
                emailTo: "",
                message: "",
                id: props.user.id,
              };

              reset(formData);
              setForm(formData);
            }
            props.setUser({
              ...data.user,
              role: data.user?.role?.toLowerCase(),
            });
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("err: ", error.message);
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <CardBody>
        <Row>
          <Col md={7}>
            <FormGroup>
              <UL className="tg-list common-flex simple-list flex-row">
                <Fragment key={0}>
                  <LI className="tg-list-item">
                    <Input
                      className={`tgl tgl-skewed`}
                      id={`cb1`}
                      type="checkbox"
                      {...register("outOfOffice", { required: false })}
                      checked={form.outOfOffice}
                      onChange={(e) =>
                        saveDataForm("outOfOffice", e.target.checked)
                      }
                    />
                    <Label
                      className="tgl-btn"
                      data-tg-off={"No"}
                      data-tg-on={"Yes"}
                      htmlFor={`cb1`}
                    />
                  </LI>
                  <LI>
                    <P>{"Out Of Office"}</P>
                  </LI>
                </Fragment>
              </UL>
            </FormGroup>
          </Col>

          <Col md={7} className={`${form.outOfOffice ? "" : "d-none"}`}>
            <Row>
              <Col md={12}>
                <FormGroup className="d-flex gap-2">
                  <Label style={{ flexShrink: 0 }}>{"From :"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter email"
                    {...register("emailFrom", { required: form.outOfOffice })}
                    value={form.emailFrom}
                    onChange={(e) => saveDataForm("emailFrom", e.target.value)}
                  />
                  {errors.emailFrom && (
                    <span style={{ color: "red" }}>
                      {"Email From is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="d-flex gap-2">
                  <Label style={{ flexShrink: 0 }}>{"To :"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter email"
                    {...register("emailTo", { required: form.outOfOffice })}
                    value={form.emailTo}
                    onChange={(e) => saveDataForm("emailTo", e.target.value)}
                  />
                  {errors.emailTo && (
                    <span style={{ color: "red" }}>
                      {"Email To is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={12}>
                <div>
                  <Label>{"Message"}</Label>
                  <Input
                    type="textarea"
                    rows="4"
                    placeholder="Add a personal message (optional)"
                    {...register("message", { required: form.outOfOffice })}
                    value={form.message}
                    onChange={(e) => saveDataForm("message", e.target.value)}
                  />
                </div>
                {errors.message && (
                  <span style={{ color: "red" }}>{"Message is required"} </span>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={7} className="mt-3">
            <Btn color="primary">{`Save Data`}</Btn>
          </Col>
        </Row>
      </CardBody>
    </Form>
  );
}

function NotificationEnableForm(props: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const permissionData: Record<string, Record<string, string>> = {
    "Receive a new post": {
      post_push: "Push",
      post_push_checked_in: "Push While Checked In",
      post_email: "Email",
      post_in_house: "In Bloomkidz",
    },
    "New comment on a post I follow": {
      post_comment_push: "Push",
      post_comment_push_checked_in: "Push While Checked In",
      post_comment_email: "Email",
      post_comment_in_house: "In Bloomkidz",
    },
    "Get a private message": {
      private_message_push: "Push",
      private_message_push_checked_in: "Push While Checked In",
      private_message_email: "Email",
      private_message_in_house: "In Bloomkidz",
    },
    "Get a team message": {
      team_message_push: "Push",
      team_message_push_checked_in: "Push While Checked In",
      team_message_email: "Email",
      team_message_in_house: "In Bloomkidz",
    },
    "New calendar invitation": {
      calendar_push: "Push",
      calendar_push_checked_in: "Push While Checked In",
      calendar_email: "Email",
      calendar_in_house: "In Bloomkidz",
    },
    "My child is signed in or out": {
      child_signed_push: "Push",
      child_signed_push_checked_in: "Push While Checked In",
      child_signed_email: "Email",
      child_signed_in_house: "In Bloomkidz",
    },
    "My child sleeps": {
      child_sleeps_push: "Push",
      child_sleeps_push_checked_in: "Push While Checked In",
      child_sleeps_email: "Email",
      child_sleeps_in_house: "In Bloomkidz",
    },
    "My child is sick or on holiday": {
      child_leave_push: "Push",
      child_leave_push_checked_in: "Push While Checked In",
      child_leave_email: "Email",
      child_leave_in_house: "In Bloomkidz",
    },
    "My child details updated": {
      child_detail_push: "Push",
      child_detail_push_checked_in: "Push While Checked In",
      child_detail_email: "Email",
      child_detail_in_house: "In Bloomkidz",
    },
    "Website form enquiry signup": {
      enquiry_form_push: "Push",
      enquiry_form_push_checked_in: "Push While Checked In",
      enquiry_form_email: "Email",
      enquiry_form_in_house: "In Bloomkidz",
    },
  };
  const initialSelection: Record<string, string> = {
    "Receive a new post": "post_push",
    "New comment on a post I follow": "post_comment_push",
    // ... initial selections for other categories
  };

  useEffect(() => {
    console.log("props.user.notificationList: ", props.user.notificationList);
    if (props.user && props.user.notificationList) {
      const selections = JSON.parse(props.user.notificationList);
      setSelectedPermissions(selections);
    }
  }, [props]);

  const [selectedPermissions, setSelectedPermissions] =
    useState<Record<string, string>>(initialSelection);

  // Function to handle radio button change
  const handleRadioChange = (category: any, permissionKey: any) => {
    setSelectedPermissions((prevSelected) => ({
      ...prevSelected,
      [category]: permissionKey,
    }));
  };

  const onEditSubmit = async () => {
    console.log("Form is Submitted successfully", selectedPermissions);
    try {
      let formData = {
        id: props.user.id,
        notificationList: `${JSON.stringify(selectedPermissions)}`,
        isNotificationEnableForm: true,
      };

      const data = await UserApi.updateUserProfile(formData);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            console.log("data.notificationList: ", data.user.notificationList);
            if (data.user) {
              const selections = JSON.parse(data.user.notificationList);
              setSelectedPermissions(selections);
            }

            props.setUser({
              ...data.user,
              role: data.user?.role?.toLowerCase(),
            });
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("err: ", error.message);
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <CardBody>
        <h3>Notification</h3>
        <div className="table-responsive signal-table mb-3">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" className="text-center">
                  Push
                </th>
                <th scope="col" className="text-center">
                  Push while checked in{" "}
                </th>
                <th scope="col" className="text-center">
                  Email
                </th>
                <th scope="col" className="text-center">
                  In Bloomkidz
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(permissionData).map(
                ([category, permissions], index) => (
                  <tr key={index} className="capital">
                    {/* Category Name */}
                    <td className="text-left text-primary font-weight-bold">
                      {category}
                    </td>

                    {/* Permission Radio Buttons */}
                    {Object.entries(permissions).map(([key, label]) => (
                      <td key={key} className="text-center">
                        <label className="radio">
                          <input
                            type="radio"
                            name={category} // Set the `name` to the category so only one radio per category can be selected
                            value={key}
                            checked={selectedPermissions[category] === key}
                            onChange={() => handleRadioChange(category, key)}
                          />{" "}
                          <span>{label}</span>
                        </label>
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <Btn color="primary">{`Save Data`}</Btn>
      </CardBody>
    </Form>
  );
}

function LimitNotificationForm(props: any) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const limitList = [
    "All day",
    "Only during certain hours",
    "Don't send push notifications on weekends",
  ];

  const initialState = {
    limitType: 0,
    startTime: new Date(),
    endTime: new Date(),
  };

  const [form, setForm] = useState<any>(initialState);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (props.user) {
      const formData = {
        limitType: props.user.limitNotificationType,
        startTime: Number(props.user.limitNotificationStartTime),
        endTime: Number(props.user.limitNotificationEndTime),
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    }
  }, [props]);

  useEffect(() => {
    reset(initialState);
  }, []);

  const onEditSubmit = async () => {
    let formData = JSON.parse(JSON.stringify(form));
    formData.isLimitForm = true;
    formData.startTime = new Date(formData.startTime).getTime();
    formData.endTime = new Date(formData.endTime).getTime();

    try {
      const data = await UserApi.updateUserProfile(formData);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            if (props.user) {
              const formData = {
                limitType: props.user.limitNotificationType,
                startTime: Number(props.user.limitNotificationStartTime),
                endTime: Number(props.user.limitNotificationEndTime),
                id: props.user.id,
              };

              reset(formData);
              setForm(formData);
            }

            props.setUser({
              ...data.user,
              role: data.user?.role?.toLowerCase(),
            });
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("err: ", error.message);
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <CardBody>
        <h3>Limit Push Notification</h3>
        <FormGroup className="mt-2">
          <Input
            type="select"
            className="btn-square"
            {...register("limitType", { required: form.outOfOffice })}
            onChange={(e) => saveDataForm("limitType", e.target.value)}
          >
            {limitList.map((items, i) => (
              <option
                key={i}
                value={i}
                selected={items === form.limitType ? true : false}
              >
                {items}
              </option>
            ))}
          </Input>
        </FormGroup>

        <div className={`${form.limitType == 1 ? "" : "d-none"}`}>
          <FormGroup>
            <Label>{"Start Time"}</Label>
            <Controller
              control={control}
              name="startTime"
              rules={{
                required: form.limitType == 1 ? true : false,
              }}
              render={({ field }) => {
                console.log("field: ", field);
                return (
                  <DatePicker
                    className={`form-control flatpickr-input`}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                      saveDataForm("startTime", date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                );
              }}
            />
            {errors.startTime && (
              <span style={{ color: "red" }}>{"Start Time is required"} </span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{"End Time"}</Label>
            <Controller
              control={control}
              name="endTime"
              rules={{
                required: form.limitType == 1 ? true : false,
              }}
              render={({ field }) => {
                console.log("field: ", field);
                return (
                  <DatePicker
                    className={`form-control flatpickr-input`}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                      saveDataForm("endTime", date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                );
              }}
            />
            {errors.endTime && (
              <span style={{ color: "red" }}>{"End Time is required"} </span>
            )}
          </FormGroup>
        </div>

        <Btn color="primary">{`Save Data`}</Btn>
      </CardBody>
    </Form>
  );
}
export default Notification;
