import api from "./api"; // Use the Axios instance with interceptor

export default class UploadAPi {
  static async uploadFile(data: any, url: string) {
    try {
      const response = await api.post(`/uploadFiles/${url}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      }); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async removeUploadedFile(data: any, url: string) {
    try {
      const response = await api.post(`/uploadFiles/${url}`, data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
