import SignIn from "../../Auth/connector";
import { LandingLayout, Layout } from "../../Layout";
import {
  AddEyfs,
  AddFaq,
  AddHelpCenter,
  AddPlan,
  AddSuperAdmin,
  AddUsers,
  EditEyfs,
  EditFaq,
  EditHelpCenter,
  EditPlan,
  EditSuperAdmin,
  EditUsers,
  Eyfs,
  ListFaq,
  ListHelpCenter,
  ListSuperAdmin,
  MyCalendar,
  Plan,
  RevenueReport,
  Users,
  UsersReport,
  VisitorsReport,
  ChangePassword,
  SuperAdminAddEvents,
  SuperAdminEditEvents,
  ListUserActivity,
  Notification,
  ListDemoRequest,
  EditDemoRequest,
} from "../../Pages/SuperAdmin";
import Default from "../../Pages/Dashboard/Default";
import { ContactUs, Home } from "../../Pages/Home";
import { ROLES } from "../../Utils/Constants";
import PrivateRoutes from "../PrivateRoutes";
import Root from "./Root";
import {
  ActivePlans,
  AddActivePlans,
  AddAnnouncements,
  AddChildren,
  AddDocuments,
  AddEmployees,
  AddEvents,
  AddParents,
  AdminDashboard,
  AdminMyCalendar,
  AdminProfile,
  Announcements,
  Children,
  ChildrenAttendance,
  ChildrenReport,
  DetailDocuments,
  Documents,
  EditAnnouncements,
  EditChildren,
  EditDocuments,
  EditEmployees,
  EditEvents,
  EditParents,
  EmployeeAttendance,
  EmployeePayroll,
  EmployeeReport,
  Employees,
  HeadCountReport,
  ParentReport,
  Parents,
  TeamTimeOff,
  Settings,
  NewsFeed,
} from "../../Pages/Admin";

export const manageRoutes = (props: any) => {
  const user = props?.user;

  //   const WithPermissionsRoutes = {
  //     path: "/",
  //     element: <PrivateRoute navLink="/" component={LoggedinLayout} />,
  //     children: [
  //       {
  //         path: "users",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "employees",
  //             element: <Employees />,
  //             permissionkey: SECTIONS.USER_EMPLOYEE,
  //           },
  //           {
  //             path: "employees/:id",
  //             element: <EmployeeDetail />,
  //             permissionkey: SECTIONS.USER_EMPLOYEE,
  //           },
  //           {
  //             path: "parents",
  //             element: <Parents />,
  //             permissionkey: SECTIONS.USER_PARENT,
  //           },
  //           {
  //             path: "parents/:id",
  //             element: <ParentDetail />,
  //             permissionkey: SECTIONS.USER_PARENT,
  //           },
  //           {
  //             path: "children",
  //             element: <Children />,
  //             permissionkey: SECTIONS.CHILDREN,
  //           },
  //           {
  //             path: "children/:id",
  //             element: <ChildrenDetail />,
  //             permissionkey: SECTIONS.CHILDREN,
  //           },
  //         ],
  //       },
  //       {
  //         path: "attendance",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "children-attendance",
  //             element: <ChildrenAttendance />,
  //             permissionkey: SECTIONS.ATTENDANCE_CHILDREN,
  //           },
  //           {
  //             path: "employee-attendance",
  //             element: <EmployeeAttendance />,
  //             permissionkey: SECTIONS.ATTENDANCE_EMPLOYEE,
  //           },
  //         ],
  //       },
  //       {
  //         path: "finance",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "employee-payroll",
  //             element: <EmployeePayroll />,
  //             permissionkey: SECTIONS.FINANCE,
  //           },
  //           {
  //             path: "employee-payroll/:id",
  //             element: <EmployeePayrollDetail />,
  //             permissionkey: SECTIONS.FINANCE,
  //           },
  //           {
  //             path: "settings",
  //             element: <FinanceSettings />,
  //             permissionkey: SECTIONS.FINANCE,
  //           },
  //         ],
  //       },
  //       {
  //         path: "plans",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "active-plans",
  //             element: <ActivePlans2 />,
  //             permissionkey: SECTIONS.PLAN_ACTIVE,
  //           },
  //           {
  //             path: "pricing-bands",
  //             element: <PricingBands />,
  //             permissionkey: SECTIONS.PLAN_ACTIVE,
  //           },
  //           {
  //             path: "session-requests",
  //             element: <SessionRequest />,
  //             permissionkey: SECTIONS.PLAN_ACTIVE,
  //           },
  //           {
  //             path: "session-requests/:id",
  //             element: <SessionRequestDetail />,
  //             permissionkey: SECTIONS.PLAN_ACTIVE,
  //           },
  //           {
  //             path: "manage-users",
  //             element: <ManageUsers2 />,
  //             permissionkey: SECTIONS.PLAN_MANAGE_USER,
  //           },
  //         ],
  //       },
  //       {
  //         path: "reports",
  //         element: <Root />,
  //         children: [
  //           { path: "", element: <AllReports />, permissionkey: SECTIONS.REPORT },
  //           {
  //             path: "head-count",
  //             element: <HeadCount />,
  //             permissionkey: SECTIONS.REPORT,
  //           },
  //           {
  //             path: "employee-data",
  //             element: <EmployeeDataReports />,
  //             permissionkey: SECTIONS.REPORT,
  //           },
  //           {
  //             path: "parent-data",
  //             element: <ParentDataReports />,
  //             permissionkey: SECTIONS.REPORT,
  //           },
  //           {
  //             path: "children-data",
  //             element: <ChildrenDataReports />,
  //             permissionkey: SECTIONS.REPORT,
  //           },
  //         ],
  //       },
  //       {
  //         path: "calendar",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "my-calendar",
  //             element: <MyCalendar />,
  //             permissionkey: SECTIONS.CALENDAR_EVENT,
  //           },
  //           {
  //             path: "team-time-off",
  //             element: <TeamTimeOff />,
  //             permissionkey: SECTIONS.CALENDAR_TEAM_TIME_OFF,
  //           },
  //         ],
  //       },
  //       {
  //         path: "documents",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "all",
  //             element: <AllDocuments />,
  //             permissionkey: SECTIONS.DOCUMENT,
  //           },
  //           {
  //             path: "all/:id",
  //             element: <DocumentDetail />,
  //             permissionkey: SECTIONS.DOCUMENT,
  //           },
  //         ],
  //       },
  //       {
  //         path: "announcements",
  //         element: <Root />,
  //         children: [
  //           {
  //             path: "",
  //             element: <Announcements />,
  //             permissionkey: SECTIONS.ANNOUNCEMENT,
  //           },
  //           {
  //             path: ":id",
  //             element: <AnnouncementDetail />,
  //             permissionkey: SECTIONS.ANNOUNCEMENT,
  //           },
  //           {
  //             path: "create",
  //             element: <CreateAnnouncement />,
  //             permissionkey: SECTIONS.ANNOUNCEMENT,
  //           },
  //           {
  //             path: "edit/:id",
  //             element: <EditAnnouncement />,
  //             permissionkey: SECTIONS.ANNOUNCEMENT,
  //           },
  //         ],
  //       },
  //     ],
  //   };

  //   const EmployeeRoutes: any = {
  //     path: "/",
  //     element: <PrivateRoute navLink="/" component={LoggedinLayout} />,
  //     children: [
  //       {
  //         path: "",
  //         element: <Root />,
  //         children: [{ path: "", element: <Newsfeed /> }],
  //       },
  //       {
  //         path: "children",
  //         element: <Root />,
  //         children: [
  //           { path: "list", element: <ECList /> },
  //           {
  //             path: "list/:id",
  //             element: <EListDetail />,
  //           },
  //           {
  //             path: "list/:id/parent-detail/:parentid",
  //             element: <EParentProfileDetail />,
  //           },
  //           { path: "attendance", element: <ChildrenAttendance /> },
  //         ],
  //       },
  //       {
  //         path: "my-attendance",
  //         element: <Root />,
  //         children: [
  //           { path: "own", element: <EMAMyAttendance /> },
  //           { path: "activity-panner", element: <EMAActivityPlanner /> },
  //         ],
  //       },
  //       {
  //         path: "help-center",
  //         element: <Root />,
  //         children: [
  //           { path: "", element: <HelpCenter /> },
  //           { path: "faqs", element: <Faqs /> },
  //           { path: "privacy-policy", element: <PrivacyPolicy /> },
  //           { path: "contact-support", element: <ContactSupport /> },
  //         ],
  //       },
  //       {
  //         path: "settings",
  //         element: <Root />,
  //         children: [{ path: "", element: <Settings /> }],
  //       },
  //       {
  //         path: "inbox",
  //         element: <Root />,
  //         children: [{ path: "", element: <Inbox /> }],
  //       },
  //       {
  //         path: "notifications",
  //         element: <Root />,
  //         children: [{ path: "", element: <Notifications /> }],
  //       },
  //       {
  //         path: "profile",
  //         element: <Root />,
  //         children: [{ path: "", element: <Profile /> }],
  //       },
  //     ],
  //   };

  //   const ParentRoutes = {
  //     path: "/",
  //     element: <PrivateRoute navLink="/" component={LoggedinLayout} />,
  //     children: [
  //       {
  //         path: "",
  //         element: <Root />,
  //         children: [{ path: "", element: <PDashbaord /> }],
  //       },
  //       {
  //         path: "news-feed",
  //         element: <Root />,
  //         children: [{ path: "", element: <Newsfeed /> }],
  //       },
  //       {
  //         path: "children",
  //         element: <Root />,
  //         children: [
  //           { path: "child-profile", element: <PCChildProfile /> },
  //           { path: "child-profile/:id", element: <PChildDetail /> },
  //           { path: "attendance", element: <ChildrenAttendance /> },
  //         ],
  //       },
  //       {
  //         path: "calendar",
  //         element: <Root />,
  //         children: [{ path: "", element: <MyCalendar /> }],
  //       },
  //       {
  //         path: "my-plan",
  //         element: <Root />,
  //         children: [
  //           { path: "", element: <PMyPlan /> },
  //           { path: "booking", element: <PBooking /> },
  //         ],
  //       },
  //       {
  //         path: "announcements",
  //         element: <Root />,
  //         children: [
  //           { path: "", element: <Announcements /> },
  //           { path: ":id", element: <AnnouncementDetail /> },
  //         ],
  //       },
  //       {
  //         path: "help-center",
  //         element: <Root />,
  //         children: [
  //           { path: "", element: <HelpCenter /> },
  //           { path: "faqs", element: <Faqs /> },
  //           { path: "privacy-policy", element: <PrivacyPolicy /> },
  //           { path: "contact-support", element: <ContactSupport /> },
  //         ],
  //       },
  //       {
  //         path: "settings",
  //         element: <Root />,
  //         children: [{ path: "", element: <Settings /> }],
  //       },
  //       {
  //         path: "inbox",
  //         element: <Root />,
  //         children: [{ path: "", element: <Inbox /> }],
  //       },
  //       {
  //         path: "notifications",
  //         element: <Root />,
  //         children: [{ path: "", element: <Notifications /> }],
  //       },
  //       {
  //         path: "profile",
  //         element: <Root />,
  //         children: [{ path: "", element: <Profile /> }],
  //       },
  //     ],
  //   };

  const AdminRoutes = {
    path: "/dashboard",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Root />,
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
        ],
      },
      {
        path: "profile",
        element: <Root />,
        children: [
          {
            path: "",
            element: <AdminProfile />,
          },
        ],
      },
      {
        path: "news-feed",
        element: <Root />,
        children: [{ path: "", element: <NewsFeed /> }],
      },
      {
        path: "users",
        element: <Root />,
        children: [
          {
            path: "employees",
            element: <Root />,
            children: [
              { path: "", element: <Employees /> },
              { path: "add", element: <AddEmployees /> },
              { path: "edit/:id", element: <EditEmployees /> },
            ],
          },
          {
            path: "parents",
            element: <Root />,
            children: [
              { path: "", element: <Parents /> },
              { path: "add", element: <AddParents /> },
              { path: "edit/:id", element: <EditParents /> },
            ],
          },
          {
            path: "children",
            element: <Root />,
            children: [
              { path: "", element: <Children /> },
              { path: "add", element: <AddChildren /> },
              { path: "edit/:id", element: <EditChildren /> },
            ],
          },
        ],
      },
      {
        path: "attendance",
        element: <Root />,
        children: [
          { path: "children-attendance", element: <ChildrenAttendance /> },
          { path: "employee-attendance", element: <EmployeeAttendance /> },
        ],
      },
      {
        path: "finance",
        element: <Root />,
        children: [
          { path: "employee-payroll", element: <EmployeePayroll /> },
          // { path: "employee-payroll/:id", element: <EmployeePayrollDetail /> },
          // { path: "settings", element: <FinanceSettings /> },
        ],
      },
      {
        path: "documents",
        element: <Root />,
        children: [
          {
            path: "all",
            element: <Root />,
            children: [
              { path: "", element: <Documents /> },
              { path: "add", element: <AddDocuments /> },
              { path: "edit/:id", element: <EditDocuments /> },
              { path: "view/:id", element: <DetailDocuments /> },
            ],
          },
        ],
      },
      {
        path: "calendar",
        element: <Root />,
        children: [
          {
            path: "my-calendar",
            element: <Root />,
            children: [
              { path: "", element: <AdminMyCalendar /> },
              { path: "add", element: <AddEvents /> },
              { path: "edit/:id", element: <EditEvents /> },
            ],
          },
          { path: "team-time-off", element: <TeamTimeOff /> },
        ],
      },
      {
        path: "plans",
        element: <Root />,
        children: [
          {
            path: "active-plans",
            element: <Root />,
            children: [
              { path: "", element: <ActivePlans /> },
              { path: "add", element: <AddActivePlans /> },
            ],
          },
          // { path: "pricing-bands", element: <PricingBands /> },
          // { path: "session-requests", element: <SessionRequest /> },
          // { path: "session-requests/:id", element: <SessionRequestDetail /> },
          // { path: "manage-users", element: <ManageUsers2 /> },
        ],
      },
      {
        path: "reports",
        element: <Root />,
        children: [
          {
            path: "head-count",
            element: <HeadCountReport />,
          },
          {
            path: "employee-data",
            element: <EmployeeReport />,
          },
          {
            path: "parent-data",
            element: <ParentReport />,
          },
          {
            path: "children-data",
            element: <ChildrenReport />,
          },
        ],
      },
      {
        path: "announcements",
        element: <Root />,
        children: [
          { path: "", element: <Announcements /> },
          { path: "add", element: <AddAnnouncements /> },
          { path: "edit/:id", element: <EditAnnouncements /> },
        ],
      },
      //       {
      //         path: "help-center",
      //         element: <Root />,
      //         children: [
      //           { path: "", element: <HelpCenter /> },
      //           { path: "faqs", element: <Faqs /> },
      //           { path: "detail/:id", element: <HelpCenterDetail /> },
      //         ],
      //       },
      {
        path: "settings",
        element: <Root />,
        children: [{ path: "", element: <Settings /> }],
      },
      //       {
      //         path: "inbox",
      //         element: <Root />,
      //         children: [{ path: "", element: <Inbox /> }],
      //       },
      //       {
      //         path: "notifications",
      //         element: <Root />,
      //         children: [{ path: "", element: <Notifications /> }],
      //       },
      //       {
      //         path: "profile",
      //         element: <Root />,
      //         children: [{ path: "", element: <Profile /> }],
      //       },
    ],
  };

  // ! Partially Completed
  const SuperAdminRoutes = {
    path: "/dashboard",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Root />,
        children: [
          {
            path: ``,
            element: <Default />,
          },
        ],
      },
      {
        path: "admin",
        element: <Root />,
        children: [
          { path: "", element: <ListSuperAdmin /> },
          { path: "add", element: <AddSuperAdmin /> },
          { path: "edit/:id", element: <EditSuperAdmin /> },
          // { path: ":id", element: <UserDetail /> },
        ],
      },
      {
        path: "users",
        element: <Root />,
        children: [
          { path: "", element: <Users /> },
          { path: "add", element: <AddUsers /> },
          { path: "edit/:id", element: <EditUsers /> },
          // { path: ":id", element: <UserDetail /> },
        ],
      },
      {
        path: "bookings",
        element: <Root />,
        children: [
          { path: "", element: <ListDemoRequest /> },
          // { path: "add", element: <AddDemoRequest /> },
          { path: "edit/:id", element: <EditDemoRequest /> },
        ],
      },
      {
        path: "calendar",
        element: <Root />,
        children: [
          {
            path: "",
            element: <MyCalendar />,
          },
          { path: "add", element: <SuperAdminAddEvents /> },
          { path: "edit/:id", element: <SuperAdminEditEvents /> },
        ],
      },
      {
        path: "plans",
        element: <Root />,
        children: [
          { path: "", element: <Plan /> },
          { path: "add", element: <AddPlan /> },
          { path: "edit/:id", element: <EditPlan /> },
        ],
      },
      {
        path: "reports",
        element: <Root />,
        children: [
          {
            path: "all-users",
            element: <UsersReport />,
          },
          {
            path: "revenue",
            element: <RevenueReport />,
          },
          {
            path: "landing-page-visitors",
            element: <VisitorsReport />,
          },
        ],
      },
      {
        path: "eyfs",
        element: <Root />,

        children: [
          { path: "", element: <Eyfs /> },
          { path: "add", element: <AddEyfs /> },
          { path: "edit/:id", element: <EditEyfs /> },
        ],
      },
      {
        path: "help-center",
        element: <Root />,
        children: [
          {
            path: "",
            element: <Root />,
            children: [
              { path: "", element: <ListHelpCenter /> },
              { path: "add", element: <AddHelpCenter /> },
              { path: "edit/:id", element: <EditHelpCenter /> },
            ],
          },
          {
            path: "faq",
            element: <Root />,

            children: [
              { path: "", element: <ListFaq /> }, // List FAQs at /faq
              { path: "add", element: <AddFaq /> }, // Add FAQ at /faq/add
              { path: "edit/:id", element: <EditFaq /> }, // Edit FAQ at /faq/edit/:id
            ],
          },
        ],
      },
      {
        path: "user-activity",
        element: <Root />,
        children: [{ path: "", element: <ListUserActivity /> }],
      },
      {
        path: "change-password",
        element: <Root />,
        children: [{ path: "", element: <ChangePassword /> }],
      },
      {
        path: "notofication-setting",
        element: <Root />,
        children: [{ path: "", element: <Notification /> }],
      },

      // {
      //   path: "settings",
      //   element: <Root />,
      //   children: [{ path: "", element: <Settings /> }],

      // },
      //   {
      //     path: "inbox",
      //     element: <Root />,
      //     children: [{ path: "", element: <Inbox /> }],
      //   },
      //   {
      //     path: "notifications",
      //     element: <Root />,
      //     children: [{ path: "", element: <Notifications /> }],
      //   },
      //   {
      //     path: "profile",
      //     element: <Root />,
      //     children: [{ path: "", element: <Profile /> }],
      //   },
    ],
  };

  const publicRoutes = [
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/contact-us", element: <ContactUs /> },
        // { path: "/request-demo", element: <RequestDemo /> },
        // { path: "/free-trial", element: <FreeTrial /> },
      ],
    },
    {
      path: "/login",
      element: <PrivateRoutes auth={{ user: user }} checkLogin={true} />,
      children: [{ path: "", element: <SignIn /> }],
    },
  ];

  const RolesRoutes = {
    [ROLES.ADMIN]: AdminRoutes,
    // [ROLES.EMPLOYEE]: EmployeeRoutes,
    // [ROLES.PARENT]: ParentRoutes,
    [ROLES.SUPER_ADMIN]: SuperAdminRoutes,
    publicRoutes,
    // withPermissionsRoutes: WithPermissionsRoutes,
  };

  return [getRolesRoutes(user, RolesRoutes), ...publicRoutes];
};

const getRolesRoutes = (user: any, RolesRoutes: any) => {
  if (user?.role === ROLES.ADMIN) {
    return RolesRoutes.admin;
    // } else if (user?.role === ROLES.EMPLOYEE) {
    //   return resultWithPermissionsRoutes;
  } else if (user?.role === ROLES.PARENT) {
    return RolesRoutes.parent;
  } else if (user?.role === ROLES.SUPER_ADMIN) {
    return RolesRoutes.super_admin;
  }
  return RolesRoutes.publicRoutes;
};

export const skipAuthenticationPublicPath = ["/", "/contact-us"];
