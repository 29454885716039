import api from "./api"; // Use the Axios instance with interceptor

export default class NotificationApi {
  // Fetch all notifications
  static async getAllNotifications(params?: any) {
    try {
      const response = await api.get("/user/notification/all", { params }); // Fetch notifications with query params
      console.log("Fetched Notifications: ", response.data); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Update a specific notification
  static async updateNotification(id: string, body: any) {
    try {
      const response = await api.patch(`/user/notification/${id}`, body); // Send a PATCH request to update the notification
      console.log("Updated Notification: ", response.data); // Log the updated data
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}