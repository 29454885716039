import React, { useEffect, useState } from "react";
import UserActivityApi from "../../../services/UserActivityApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row, Tooltip } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { LI, UL } from "../../../AbstractElements";
import { dynamicImage } from "../../../Utils";
import { formatDistanceToNow } from "date-fns";

interface UserActivity {
    id: string;
    operationName: string;
    description: string;
    createdAt: string;
    createdByUserDetail: {
        firstName?: string;
        lastName?: string;
        email?: string;
        avatarUrl?: string;
    };
}  

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListUserActivity: React.FC = () => {
  const [activities, setActivities] = useState<UserActivity[]>([]);
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>({});
  const toggleTooltip = (id: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const defaultProfileImage = dynamicImage(`login/default_profile_image.png`);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await UserActivityApi.getUserActivity();
        if (response.success && Array.isArray(response.data)) {
          setActivities(response.data);
        } else {
          throw new Error("Expected an array of user activities");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching user activities");
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  const filteredItems = activities.filter((activity) =>
    activity.operationName.toLowerCase().includes(filterText.toLowerCase())
  );


  const handleDetailPage = (id: string) => {
    navigate(`/dashboard/activity/detail/${id}`);
  };

  const columns: TableColumn<UserActivity>[] = [
    {
        name: <CustomHeader title="Date" />,
        selector: (row) => row.createdAt,
        sortable: true,
        cell: (row) => (
          <div>
            <div>{new Date(row.createdAt).toLocaleDateString()}</div>
            <span style={{ fontSize: "0.85em", color: "gray" }}>
              {formatDistanceToNow(new Date(row.createdAt), { addSuffix: true })}!
            </span>
          </div>
        ),
    },
    {
        name: <CustomHeader title="User" />,
        cell: (row) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
                // src={row.createdByUserDetail.avatarUrl || defaultProfileImage}
              src={defaultProfileImage}              
              alt="Avatar"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <div>
              <span>{`${row.createdByUserDetail.firstName || ""} ${row.createdByUserDetail.lastName || ""}`}</span>
              <br />
              <span style={{ fontSize: "0.85em", color: "gray" }}>
                {row.createdByUserDetail.email || ""}
              </span>
            </div>
          </div>
        ),
      },
    {
      name: <CustomHeader title="Operation Name" />,
      selector: (row) => row.operationName,
      sortable: true,
    },
    {
        name: <CustomHeader title="Description" />,
        selector: (row) => row.description,
        sortable: true,
        cell: (row) => (
          <div
            id={`descriptionTooltip-${row.id}`}
            style={{
              maxWidth: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {row.description}
  
            <Tooltip
                placement="top"
                isOpen={tooltipOpen[row.id]}
                target={`descriptionTooltip-${row.id}`}
                toggle={() => toggleTooltip(row.id)}
                style={{
                    // backgroundColor: "white",
                    // color: "black",
                    border: "1px solid #ccc",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                }}
                >
                {row.description}
                </Tooltip>

          </div>
        ),
    },
    {
      name: <CustomHeader title="Action" />,
      center: true,
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <Button
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-trash text-dark"></i>
            </Button>
          </LI>
        </UL>
      ),
    },
  ];


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`User Activity List`}
                  subTitle={[]}
                  titleClass="mb-3"
                />              
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[{
                      when: (row) => true,
                      style: {
                        "&:hover": {
                          backgroundColor: "rgba(48, 142, 135, 0.2)",
                          cursor: "pointer",
                        },
                      },
                    }]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListUserActivity;
