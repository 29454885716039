import { PermissionApiSlice } from "./PermissionApiSlice";

export const fetchRolesByRoleDetail = async (
  roleTitleId: string,
  thunkApi: any
) => {
  return await thunkApi.dispatch(
    PermissionApiSlice.endpoints.getPermissionByRoleTitleId.initiate(
      roleTitleId
    )
  );
};
