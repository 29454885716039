import { TableColumn } from "react-data-table-component";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleDetailPage,
  handleStatusChange,
}: {
  handleDetailPage: (id: number | string) => void;
  handleStatusChange: (row: any) => void;
}) => {
  const columnList: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Top Browsers" />,
      selector: (row) => row.browser,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Type" />,
      selector: (row) => row.type,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Visits" />,
      selector: (row) => row.count,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    // {
    //   name: <CustomHeader title="Action" />,
    //   sortable: true,
    //   center: true,
    //   style: { maxWidth: "50%" },
    //   cell: (row) => (
    //     <UL className="action simple-list flex-row">
    //       <LI className="edit">
    //         <a
    //           href={"javascript:void(0);"}
    //           onClick={() => handleDetailPage(row.id)}
    //           className="btn btn-light d-flex justify-content-center align-items-center"
    //           style={{ borderRadius: "50%", width: "40px", height: "40px" }}
    //         >
    //           <i className="icon-pencil-alt text-dark" />
    //         </a>
    //       </LI>
    //       {/* <LI className="delete">
    //         <a
    //           href={""}
    //           className="btn btn-danger d-flex justify-content-center align-items-center"
    //           style={{ borderRadius: "50%", width: "40px", height: "40px" }}
    //         >
    //           <i className="icon-trash text-white" />
    //         </a>
    //       </LI> */}
    //     </UL>
    //   ),
    // },
  ];

  return columnList;
};
