import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { FilterComponentProps } from "../../../../Types/Tables.type";
import { Title } from "../../../../Utils/Constants";
import Select from "react-select";
import { DropItemChangeProp, Option } from "../../../../Types/Forms.type";
import DatePicker from "react-datepicker";

export default function FilterComponent({
  onFilter,
  filterText,
  type = "search",
  dropdown,
}: FilterComponentProps) {
  const handleChange: DropItemChangeProp = (newValue, actionMeta) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as Option;
      onFilter(newSelectedOption?.value || null);
    }
  };
  return (
    <>
      {type === "search" ? (
        <Row className="align-items-center justify-content-end">
          <Col xs="auto">
            <Label>{"Search"}:</Label>
          </Col>
          <Col xs="auto" className="d-flex flex-row">
            <Input type="text" value={filterText} onChange={onFilter} />
          </Col>
        </Row>
      ) : (
        <>
          {type === "datepicker" ? (
            <Row className="align-items-center">
              <Col xs="auto">
                <Label>{"Date"}:</Label>
              </Col>
              <Col xs="auto" className="">
                <DatePicker
                  className={`form-control flatpickr-input`}
                  selected={filterText ?? new Date()}
                  onChange={onFilter}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Col>
            </Row>
          ) : (
            <Row className="align-items-center">
              <Col xs="auto">
                <Label>{dropdown?.title}:</Label>
              </Col>
              <Col xs="auto" className="">
                <Select
                  id={dropdown?.id}
                  options={dropdown?.options ?? []}
                  value={dropdown?.options?.find(
                    (option: any) => option.value === filterText
                  )}
                  onChange={handleChange}
                  className={`${dropdown?.id}-form`}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
