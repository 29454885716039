import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import FaqApi from "../../../services/FaqApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";

// Interface for form data
interface IForm {
  title: string;
  content: string;
}

const AddFaq: React.FC = () => {
  const navigate = useNavigate();

  // Using React Hook Form for handling form state and validation
  const { register, handleSubmit, control, formState: { errors } } = useForm<IForm>();

  // Function to handle form submission
  const onSubmit = async (data: IForm) => {
    try {
      // Call the API to create the FAQ using FaqApi
      await FaqApi.createFaq(data);
      Swal.fire({
        title: "Success!",
        text: "FAQ created successfully",
        icon: "success",
      }).then( async () => {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "FAQ's",
            `FAQ created successfully`
          )
        );

        // Navigate to FAQ list on success
        navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/faq`);
      });
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add FAQ Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    {/* Title Field */}
                    <Col md={6}>
                      <Label for="title">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                        {...register("title", { required: "Please enter a valid title" })}
                        placeholder="Enter FAQ title"
                      />
                      {errors.title && (
                        <FormFeedback>{errors.title.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Content Field */}
                    <Col md={12} className="mt-3">
                      <Label for="content">
                        Content <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Content is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter FAQ content"
                          />
                        )}
                      />
                      {errors.content && (
                        <FormFeedback className="d-block">
                          {errors.content.message}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>

                  {/* Action Buttons */}
                  <Row className="mt-4">
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddFaq;
