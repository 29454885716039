import { connect } from "react-redux";
import Routers from ".";
import {
  getUserInfoData,
  SET_USER_IP,
} from "../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserInfo: () => dispatch(getUserInfoData({})),
    setUserIP: (value: any) => dispatch(SET_USER_IP(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routers);
