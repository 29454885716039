import api from "./api"; // Use the Axios instance with interceptor

export default class PostAPi {
  static async createPost(data: any) {
    try {
      const response = await api.post(`/news-feed/create`, data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async fetchPost(data: any) {
    try {
      const response = await api.get(`/news-feed/all`, { params: data }); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
