import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";

export default function ActivePlans() {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const filteredItems: HtmlColumnsInterface[] = htmlColumnsTableData.filter(
    (item: HtmlColumnsInterface) => {
      return Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Active Plans Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    console.log("BUTTON CLICKED");
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/plans/active-plans/add`
                    );
                  }}
                >
                  Add New Session
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
