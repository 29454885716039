import api from "./api"; // Use the Axios instance with interceptor

export default class UserActivityAPi {
  static async addUserActivity(data: any) {
    try {
      const response = await api.post("/user/addUserActivity", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
  static async getUserActivity() {
    try {
      const response = await api.get("/user/getUserActivity");
      
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
