import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import PackageApi from "../../../services/PackageApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import Select from "react-select";
import { Btn } from "../../../AbstractElements";
import { billingOptions } from "../../../Utils/Constants";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";

// Interface for form data
interface IForm {
  title: string;
  subTitle?: string;
  billing: string;
  fee: number | string;
  maxNumOfChild: number | string;
  minNumOfChild: number | string;
  description: string;
  isActive: boolean;
}

const AddPlan: React.FC = () => {
  const navigate = useNavigate();

  // Using React Hook Form for handling form state and validation
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>();

  // Function to handle form submission
 // Function to handle form submission
const onSubmit = async (data: IForm) => {
  try {
    // Format the data before sending it to the API
    const formattedData = {
      ...data,
      fee: typeof data.fee === "string" ? parseFloat(data.fee) : data.fee, // Ensure fee is a number
      maxNumOfChild:
          typeof data.maxNumOfChild === "string"
            ? parseInt(data.maxNumOfChild, 10)
            : data.maxNumOfChild, // Ensure maxNumOfChild is a number
        minNumOfChild:
          typeof data.minNumOfChild === "string"
            ? parseInt(data.minNumOfChild, 10)
            : data.minNumOfChild, // Ensure minNumOfChild is a number
    };

    // Call the API to create the plan using PackageApi
    await PackageApi.addPlan(formattedData);
      Swal.fire({
        title: "Success!",
        text: "Plan created successfully",
        icon: "success",
      }).then( async () => {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "Plans",
            `Plan created successfully`
          )
        );
        // Navigate to plan list on success
        navigate(`${process.env.PUBLIC_URL}/dashboard/plans`);
      });
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Plan Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/plans`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    {/* Title Field */}
                    <Col md={4}>
                      <Label for="title">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        {...register("title", {
                          required: "Please enter a valid title",
                        })}
                        placeholder="Enter Plan Title"
                      />
                      {errors.title && (
                        <FormFeedback>{errors.title.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Sub Title Field */}
                    <Col md={4}>
                      <Label for="subTitle">Sub Title</Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.subTitle ? "is-invalid" : ""
                        }`}
                        {...register("subTitle")}
                        placeholder="Enter Sub Title"
                      />
                      {errors.subTitle && (
                        <FormFeedback>{errors.subTitle.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Billing Field */}
                    <Col md={4}>
                      <Label for="billing">
                        Billing <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="billing"
                        control={control}
                        rules={{ required: "Please select a billing option" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="subscription_type"
                            className={`${errors.billing ? "is-invalid" : ""}`}
                            options={billingOptions}
                            value={billingOptions.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                            }}
                          />
                        )}
                      />
                      {errors.billing && (
                        <FormFeedback>{errors.billing.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Fee Field */}
                    <Col md={4}>
                      <Label for="fee">
                        Fee <span className="text-danger">*</span>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="list-light-primary">
                          <i className="fas fa-pound-sign"></i>
                        </InputGroupText>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.fee ? "is-invalid" : ""
                          }`}
                          {...register("fee", {
                            required: "Please enter a valid fee",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Fee number must contain only digits",
                            },
                          })}
                          placeholder="Enter Fee"
                        />
                      </InputGroup>
                      {errors.fee && (
                        <FormFeedback>{errors.fee.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Min Number of Children */}
                    <Col md={4}>
                      <Label for="minNumOfChild">
                        Minimum Number of Children{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="number"
                        className={`form-control ${
                          errors.minNumOfChild ? "is-invalid" : ""
                        }`}
                        {...register("minNumOfChild", {
                          required: "Please enter a valid number of children",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "This field must contain only digits",
                          },
                        })}
                        placeholder="Enter Min Number of Children"
                        min={0}
                      />
                      {errors.minNumOfChild && (
                        <FormFeedback>
                          {errors.minNumOfChild.message}
                        </FormFeedback>
                      )}
                    </Col>

                    {/* Max Number of Children */}
                    <Col md={4}>
                      <Label for="maxNumOfChild">
                        Maximum Number of Children{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="number"
                        className={`form-control ${
                          errors.maxNumOfChild ? "is-invalid" : ""
                        }`}
                        {...register("maxNumOfChild", {
                          required: "Please enter a valid number of children",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "This field must contain only digits",
                          },
                        })}
                        placeholder="Enter Max Number of Children"
                        min={0}
                      />
                      {errors.maxNumOfChild && (
                        <FormFeedback>
                          {errors.maxNumOfChild.message}
                        </FormFeedback>
                      )}
                    </Col>

                    {/* Description Field */}
                    <Col md={12} className="mt-3">
                      <Label for="description">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Plan Description"
                          />
                        )}
                      />
                      {errors.description && (
                        <FormFeedback className="d-block">
                          {errors.description.message}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>

                  {/* Action Buttons */}
                  <Row className="mt-4">
                    <Col>
                      <Btn color="primary" type="submit">
                        Submit
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddPlan;
