import { createSlice } from "@reduxjs/toolkit";
import { PermissionApiSlice } from "./PermissionApiSlice";

interface ICardData {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
}
interface IInitialState {
  permissions: ICardData[] | [];
  singlePermissions: [];
}

const initialState: IInitialState = {
  permissions: [],
  singlePermissions: [],
};

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //   .addMatcher(
      //     PermissionApiSlice.endpoints.getRole.matchFulfilled,
      //     (state: IInitialState, { payload }) => {
      //       const { data } = payload;
      //       state.permissions = data;
      //     }
      //   )
      //   .addMatcher(
      //     PermissionApiSlice.endpoints.createPermission.matchFulfilled,
      //     (state: IInitialState, { payload }) => {
      //       const { data } = payload;
      //       state.permissions = [...state.permissions, data];
      //     }
      //   )
      //   .addMatcher(
      //     PermissionApiSlice.endpoints.updatePermission.matchFulfilled,
      //     (state: IInitialState, { payload }) => {
      //       const { data } = payload;
      //       const index = state.permissions.findIndex(
      //         (permission) => permission.id === data.id
      //       );
      //       if (index !== -1) {
      //         state.permissions[index] = data;
      //       }
      //     }
      //   )
      .addMatcher(
        PermissionApiSlice.endpoints.getPermissionByRoleTitleId.matchFulfilled,
        (state: IInitialState, { payload }) => {
          const { data } = payload;
          state.singlePermissions = data;
        }
      );
  },
});

export default slice.reducer;
