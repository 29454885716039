import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import CommonModal from "../../../Components/UiKits/Modal/Common/CommonModal";
import { Btn, H4, Image, LI, P, UL } from "../../../AbstractElements";
import { dynamicImage } from "../../../Utils";

const MyCalendar = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date>(new Date());
  const [modal, setModal] = useState(false);

  const tileContent = ({ date, view }: any) => {
    const toggle = () => {
      setModal(!modal);
    };
    const data = {
      isOpen: modal,
      center: true,
      toggler: toggle,
    };

    // Example: Add event markers
    const events = [15, 22]; // Dates with events
    return view === "month" && events.includes(date.getDate()) ? (
      <>
        <Btn color="success" onClick={toggle}>
          {"Demo Events"}
        </Btn>
        <CommonModal modalData={data}>
          <div className="modal-toggle-wrapper">
            <H4 className="b-2">{"Event Details"}</H4>
            <P className="pt-2">
              {
                "Attackers on malicious activity may trick you into doing something dangrous like installing software or revealing your personal informations."
              }
            </P>
            <div className="d-flex">
              <Btn color="secondary" className="d-flex" onClick={toggle}>
                {"Close"}
              </Btn>
              <Btn
                color="dark"
                className="d-flex"
                style={{ marginLeft: "0.5rem" }}
              >
                {"Delete"}
              </Btn>
              <Btn
                color="primary"
                className="d-flex"
                style={{ marginLeft: "0.5rem" }}
              >
                {"Edit"}
              </Btn>
            </div>
          </div>
        </CommonModal>
      </>
    ) : null;
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`My Calendar`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar/add`
                    );
                  }}
                >
                  Add Events
                </Button>
              </div>
              <CardBody>
                <Calendar
                  onChange={(value) => setDate(value as Date)}
                  value={date}
                  tileContent={tileContent}
                  className="custom-calendar w-100"
                  nextLabel="Next Month"
                  prevLabel="Previous Month"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyCalendar;
