import React, { useEffect, useState } from "react";
import { Card, CardBody, Table, Input } from "reactstrap";

// Type definition for the opening hours state
type OpeningHoursState = {
  Monday: { open: string; close: string };
  Tuesday: { open: string; close: string };
  Wednesday: { open: string; close: string };
  Thursday: { open: string; close: string };
  Friday: { open: string; close: string };
  Saturday: { open: string; close: string };
  Sunday: { open: string; close: string };
};

const OpeningHours = (props: any) => {
  // State to manage open and close times for each day (formatted as 'HH:MM')
  const [openingHours, setOpeningHours] = useState<OpeningHoursState>(
    props.form.openingHours
  );

  // Function to handle time input changes
  const handleTimeChange = (
    day: keyof OpeningHoursState,
    type: "open" | "close",
    value: string
  ) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [type]: value,
      },
    }));
  };

  useEffect(() => {
    props.saveDataForm("openingHours", openingHours);
  }, [openingHours, setOpeningHours]);

  return (
    <Card className="shadow-sm rounded">
      <CardBody>
        <h5 className="mb-4 text-dark">Opening Hours</h5>
        <p className="text-muted mb-4">
          Set the opening and closing times for each day of the week.
        </p>

        <Table responsive bordered hover>
          <thead>
            <tr>
              <th className="text-left text-primary">Day</th>
              <th className="text-left text-primary">Open</th>
              <th className="text-left text-primary">Close</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.form.openingHours).map((day) => {
              return (
                <tr key={day} className="hover-bg-light">
                  <td className="text-left">{day}</td>
                  <td className="text-center">
                    <Input
                      type="time"
                      value={`${
                        props.form.openingHours[day as keyof OpeningHoursState]
                          .open
                      }`}
                      onChange={(e) =>
                        handleTimeChange(
                          day as keyof OpeningHoursState,
                          "open",
                          e.target.value
                        )
                      }
                      className="form-control custom-input"
                    />
                  </td>
                  <td className="text-center">
                    <Input
                      type="time"
                      value={
                        props.form.openingHours[day as keyof OpeningHoursState]
                          .close
                      }
                      onChange={(e) =>
                        handleTimeChange(
                          day as keyof OpeningHoursState,
                          "close",
                          e.target.value
                        )
                      }
                      className="form-control custom-input"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default OpeningHours;
