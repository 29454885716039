import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  TabPane,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { UpdateProfile } from "../../../../Utils/Constants";
import DatePicker from "react-datepicker";
import {
  useMutation,
  UseMutationResult,
  useQuery,
} from "@tanstack/react-query";
import UserApi from "../../../../services/UserApi";
import Swal from "sweetalert2";

interface formData {
  firstName: string;
  lastName: string;
  joinDate: any;
  nationality: string;
  email: string;
  phoneNo: string;
  primaryAddress: string;
  country: string;
  city: string;
  postalCode: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNo: string;
  emergencyContactRelation: string;
  id: string;
}

function General(props: any) {
  console.log("props: ", props);
  const initialState = {
    firstName: "",
    lastName: "",
    joinDate: "",
    nationality: "",
    email: "",
    phoneNo: "",
    primaryAddress: "",
    country: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    id: "",
  };

  const [form, setForm] = useState<formData>(initialState);

  const {
    register,
    handleSubmit,
    reset,
    control,

    formState: { errors },
  } = useForm();

  const onEditSubmit = async () => {
    let formData = JSON.parse(JSON.stringify(form));
    formData.joinDate = new Date(formData.joinDate).getTime();
    try {
      const data = await UserApi.updateUserProfile(formData);
      console.log("response data: ", data);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            props.setUser({
              ...data.user,
              role: data.user?.role?.toLowerCase(),
            });
          }
        });
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    if (props.user) {
      let formData = {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        joinDate: Number(props.user.joinDate),
        nationality: props.user.nationality,
        email: props.user.email,
        phoneNo: props.user.phoneNo,
        primaryAddress: props.user.primaryAddress,
        country: props.user.country,
        city: props.user.city,
        postalCode: props.user.postalCode,
        emergencyContactFirstName: props.user.emergencyContactFirstName,
        emergencyContactLastName: props.user.emergencyContactLastName,
        emergencyContactEmail: props.user.emergencyContactEmail,
        emergencyContactPhoneNo: props.user.emergencyContactPhoneNo,
        emergencyContactRelation: props.user.emergencyContactRelation,
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    } else {
      reset(initialState);
      setForm(initialState);
    }
  }, [props]);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <TabPane tabId={`1`} key={0}>
      <Card className="mt-2">
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <CardBody>
            <span className="h4 mb-3">
              <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
              Personal Info
            </span>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>{"First Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    value={form.firstName}
                    onChange={(e) => saveDataForm("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <span style={{ color: "red" }}>
                      {"First Name is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Last Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    {...register("lastName", { required: true })}
                    value={form.lastName}
                    onChange={(e) => saveDataForm("lastName", e.target.value)}
                  />
                  {errors.lastName && (
                    <span style={{ color: "red" }}>
                      {"Last Name is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Join Date"}</Label>
                  <Controller
                    control={control}
                    name="joinDate"
                    rules={{
                      required: "Please select joining date.",
                    }}
                    render={({ field }) => {
                      console.log("field: ", field);
                      return (
                        <DatePicker
                          className={`form-control flatpickr-input`}
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            saveDataForm("joinDate", date);
                          }}
                          placeholderText="Select a date"
                          dateFormat="MMMM dd,yyyy"
                        />
                      );
                    }}
                  />
                  {errors.joinDate && (
                    <span style={{ color: "red" }}>
                      {"Join Date is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Nationality"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nationality"
                    {...register("nationality", { required: true })}
                    value={form.nationality}
                    onChange={(e) =>
                      saveDataForm("nationality", e.target.value)
                    }
                  />
                  {errors.nationality && (
                    <span style={{ color: "red" }}>
                      {"Nationality is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Email"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    {...register("email", { required: true })}
                    value={form.email}
                    onChange={(e) => saveDataForm("email", e.target.value)}
                  />
                  {errors.email && (
                    <span style={{ color: "red" }}>{"Email is required"} </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Phone"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone"
                    {...register("phoneNo", { required: true })}
                    value={form.phoneNo}
                    onChange={(e) => saveDataForm("phoneNo", e.target.value)}
                  />
                  {errors.phoneNo && (
                    <span style={{ color: "red" }}>
                      {"PhoneNo is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <span className="h4 mb-3 mt-3">
              <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
              Address
            </span>
            <Row>
              <Col md={7}>
                <FormGroup>
                  <Label>{"Primary/ address"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Primary/ address"
                    {...register("primaryAddress", { required: true })}
                    value={form.primaryAddress}
                    onChange={(e) =>
                      saveDataForm("primaryAddress", e.target.value)
                    }
                  />
                  {errors.primaryAddress && (
                    <span style={{ color: "red" }}>
                      {"PrimaryAddress is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={7}>
                <FormGroup>
                  <Label>{"Country"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                    value={form.country}
                    onChange={(e) => saveDataForm("country", e.target.value)}
                  />
                  {errors.country && (
                    <span style={{ color: "red" }}>
                      {"Country is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={7}>
                <FormGroup>
                  <Label>{"City"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="City"
                    {...register("city", { required: true })}
                    value={form.city}
                    onChange={(e) => saveDataForm("city", e.target.value)}
                  />
                  {errors.city && (
                    <span style={{ color: "red" }}>{"City is required"} </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={7}>
                <FormGroup>
                  <Label>{"Post Code"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Post Code"
                    {...register("postalCode", { required: true })}
                    value={form.postalCode}
                    onChange={(e) => saveDataForm("postalCode", e.target.value)}
                  />
                  {errors.postalCode && (
                    <span style={{ color: "red" }}>
                      {"PostalCode is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <span className="h4 mb-3 mt-3">
              <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
              Emergency Contact
            </span>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>{"First Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    {...register("emergencyContactFirstName", {
                      required: true,
                    })}
                    value={form.emergencyContactFirstName}
                    onChange={(e) =>
                      saveDataForm("emergencyContactFirstName", e.target.value)
                    }
                  />
                  {errors.emergencyContactFirstName && (
                    <span style={{ color: "red" }}>
                      {"Emergency Contact First Name is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Last Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    {...register("emergencyContactLastName", {
                      required: true,
                    })}
                    value={form.emergencyContactLastName}
                    onChange={(e) =>
                      saveDataForm("emergencyContactLastName", e.target.value)
                    }
                  />
                  {errors.emergencyContactLastName && (
                    <span style={{ color: "red" }}>
                      {"Emergency Contact Last Name is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Email"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    {...register("emergencyContactEmail", { required: true })}
                    value={form.emergencyContactEmail}
                    onChange={(e) =>
                      saveDataForm("emergencyContactEmail", e.target.value)
                    }
                  />
                  {errors.emergencyContactEmail && (
                    <span style={{ color: "red" }}>
                      {"Emergency Contact Email is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Phone"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone"
                    {...register("emergencyContactPhoneNo", { required: true })}
                    value={form.emergencyContactPhoneNo}
                    onChange={(e) =>
                      saveDataForm("emergencyContactPhoneNo", e.target.value)
                    }
                  />
                  {errors.emergencyContactPhoneNo && (
                    <span style={{ color: "red" }}>
                      {"Emergency Contact PhoneNo is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>{"Relation"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Relation"
                    {...register("emergencyContactRelation", {
                      required: true,
                    })}
                    value={form.emergencyContactRelation}
                    onChange={(e) =>
                      saveDataForm("emergencyContactRelation", e.target.value)
                    }
                  />
                  {errors.emergencyContactRelation && (
                    <span style={{ color: "red" }}>
                      {"Emergency Contact Relation is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            {" "}
            <Btn color="primary">{UpdateProfile}</Btn>
          </CardFooter>
        </Form>
      </Card>
    </TabPane>
  );
}

export default General;
